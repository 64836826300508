import React, {useState, useEffect, useCallback} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {generalService} from "../../services";
import {Note} from "./components/Note";
import {useParams} from "react-router";
import ImageViewer from "react-simple-image-viewer";
import axios from "axios";
import TableItem from "./components/TableItem";
import {dollarFormat, moreDigitsDollarFormat, percentFormat, thousandFormat} from "../../helpers";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

function TableItem2(props) {
  const {item} = props

  return <tr className={`traded-pair-row price`} style={{lineHeight: 1.5, fontSize: '14px'}}>
    <td>
      <span className="price-859-1505 price">{item.symbol} x{item.leverage}</span>
      <span className={`price-859-1505 price dimmed small ml-2`} style={{}}>{item.long}</span>
      <span>
        &nbsp;
        {
          item.note_id ?
            <Link to={`/futures/create/${item.note_id}`} className="monospace-font dimmed small">⭐️</Link>
            : <Link to={`/futures/create/null/${item.id}`} className="monospace-font dimmed small">-</Link>
        }
      </span>
    </td>
    <td className="d-sm-table-cell text-center dimmed small ">
      <span className="price-859-1505 price">{dollarFormat(item.vol)}</span>
    </td>
    <td className="d-sm-table-cell text-center dimmed small ">
      <span className="price-859-1505 price">{moreDigitsDollarFormat(item.fee)}</span>
    </td>
    <td className="d-sm-table-cell text-center dimmed small ">
      {
        item.protit_percent_parts ? <OverlayTrigger overlay={<Tooltip style={{paddingLeft: '5px'}}>{item.protit_percent_parts}</Tooltip>} placement={`right`}>
          <span>
            <span className={`price-859-1505 price ${item.color}`}>{dollarFormat(item.profit)} ({thousandFormat(item.profit_percent)}%)</span>
            &nbsp;
            <span style={{fontSize: '10px'}}>ⓘ</span>
          </span>
        </OverlayTrigger> : <span className={`price-859-1505 price ${item.color}`}>{dollarFormat(item.profit)} ({thousandFormat(item.profit_percent)}%)</span>
      }
    </td>
    <td className="d-sm-table-cell text-center dimmed small ">
      <span className="price-859-1505 price">{item.open_time}</span>
    </td>
    <td className="d-sm-table-cell text-center dimmed small ">
      <span className="price-859-1505 price">{item.closed_time}</span>
    </td>
  </tr>
}

function FuturesCreate() {
  const [isSummited, setIsSummited] = useState(false);
  const [inputs, setInputs] = useState('');
  const [rows, setRows] = useState([]);
  const [summary, setSummary] = useState({})
  const [summaryKeys, setSummaryKeys] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)

  useEffect(() => {
    document.title = 'Futures - BingX 🚀🚀';
    fetchList(currentPage).then(r => console.log('🚃'))
  }, [])

  const fetchList = async (page = currentPage) => {
    let query = `?page=${page}`
    await generalService.getFutures2(query)
      .then(res => {
        const newData = page !== currentPage ? [...rows, ...res.data] : res.data;
        setRows(newData)

        const dataSummary = res.data_summary
        if (dataSummary) {
          setSummaryKeys(Object.keys(dataSummary))
        }
        setSummary(dataSummary)
        setCurrentPage(res.current_page)
        setLastPage(res.last_page)
      });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    generalService.futures2Store(inputs).then(r => {
      setIsSummited(true)
    })
    setInputs('');
  }

  const handleChange = async (e) => {
    let {value} = e.target;
    setInputs(value);
  }

  const renderListItem = () => {
    return rows.map(item => {
      return <TableItem2 key={item.id} item={item}/>
    })
  }

  const loadMoreHandler = () => {
    const nextPage = currentPage + 1
    fetchList(nextPage).then(r =>
      console.log(`🚃 TradeList fetch - ${nextPage}`))
  }

  const formatKey = (key) => {
    return key.split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  function renderTableRow(key, summary) {
    return (
      <React.Fragment key={key}>
        <tr className={`traded-pair-row price`} style={{lineHeight: 1.5, fontSize: '14px'}}>
          <td className={`price-859-1505 price dimmed small ml-2`}>
            {formatKey(key)}:
          </td>
          <td>
            <span className="price-859-1505 price dimmed small">{summary[key] ? summary[key].str1 : ''}</span>
          </td>
          <td>
            <span className={`price-859-1505 price dimmed small ml-2`} style={{}}>{summary[key] ? summary[key].str2 : ''}</span>
          </td>
        </tr>
      </React.Fragment>
    );
  }


  return isSummited ? <Redirect to={`/`}/> : <div className="container">
    <div className="row mb-3">
      <div className="col-md-6 mx-auto mt-5">
        <div className="text-center">
          <h3>FUTURES - CREATE</h3>
          <Link to={`/`} className="monospace-font dimmed">Back</Link>
          <span> |  </span>
          <Link to={`/futures`} className="monospace-font dimmed">Notes</Link>
        </div>
      </div>
    </div>

    <div className="row">
      <div className="col-12">
        <a href={`#`} className="monospace-font dimmed text-right ml-2"
           onClick={handleSubmit}
           style={{
             fontSize: '1rem',
             paddingTop: '6px',
             paddingBottom: '6px'
           }}>
          SAVE
        </a>
        <form
          id="fake-order-create-form"
          className="hc-form mt-3"
          onSubmit={handleSubmit}>

          <textarea
            type="text"
            name="date"
            className="form-control mt-3 hc-input-not-disable"
            onChange={handleChange}
            style={{fontSize: '12px'}}
            value={inputs}
            placeholder="..." autoComplete="off"/>
        </form>
      </div>
      {isSummited === true ? <p>Saved!</p> : <></>}
    </div>
    <div className="row">
      <div className="col-lg-6 col-xs-12">
        <table className="table col-4">
          <thead>
          <th><strong>Summary</strong></th>
          </thead>
          <tbody>
          {summaryKeys.slice(0, Math.ceil(summaryKeys.length / 2)).map(key => renderTableRow(key, summary))}
          </tbody>
        </table>
      </div>
      <div className="col-lg-6 col-xs-12">
        <table className="table col-4">
          <thead>
          <th><strong>&nbsp;</strong></th>
          </thead>
          <tbody>
          {summaryKeys.slice(Math.ceil(summaryKeys.length / 2)).map(key => renderTableRow(key, summary))}
          </tbody>
        </table>
      </div>
    </div>

    <table className="table mt-2">
      <thead>
      <th><strong>Data </strong></th>
      <th className={`text-center`}><span>Vol </span></th>
      <th className={`text-center`}><span>Fee </span></th>
      <th className={`text-center`}><span>P/L </span></th>
      <th className={`text-center`}><span>Open</span></th>
      <th className={`text-center`}><span>Close</span></th>
      </thead>
      <tbody>
      {renderListItem()}
      </tbody>
    </table>
    {currentPage !== lastPage ?
      <div style={{textAlign: 'center', paddingBottom: '15px'}}>
        <a onClick={loadMoreHandler}
           style={{
             'font-size': '24px',
             'cursor': 'pointer'
           }}>
          ...</a>
      </div>
      : <></>
    }
  </div>
}

export default FuturesCreate;
