import React, {useState} from 'react';

function EmaSelections({ number_of_select, select_options, onSelectionsChanged}) {
  const [selectedValues, setSelectedValues] = useState([]);

  const handleSelectChange = (event, index) => {
    const newSelectedValues = [...selectedValues];
    newSelectedValues[index] = event.target.value;
    setSelectedValues(newSelectedValues);
    onSelectionsChanged(newSelectedValues);
  };

  const handleSelectClear = () => {
    setSelectedValues([])
    onSelectionsChanged([])
  }

  const renderSelects = () => {
    const selects = [];
    for (let i = 0; i < number_of_select; i++) {
      selects.push(<>
          <div className="col-md-2">
            <select
              key={i}
              value={selectedValues[i] || ''}
              className="form-control text-center pr-0"
              onChange={(event) => handleSelectChange(event, i)}
            >
              <option value="">...</option>
              {select_options.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        {i !== number_of_select - 1 ?
          <div className="col-md-1 text-center" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <span> > </span>
          </div> : <></>
        }
        </>
      );
    }
    return selects;
  }

  return (
    <div className={`row mt-3`} style={{ display: 'flex', alignItems: 'center' }}>
      {renderSelects()}
      <div className="col-md-1 text-right" >
        <span className={`text-right dimmed pointer`} onClick={handleSelectClear}>Clear</span>
      </div>
    </div>
  );
}

export {EmaSelections};
