import React, {useState} from 'react';
import {generalService} from "../../../services";
import {alertActions} from "../../../redux";
import {Table} from "react-bootstrap";

function RsiDivergenceTable({dataRsiAnalysis}) {
  // Split object into two parts
  const objectEntries = Object.entries(dataRsiAnalysis);
  const midIndex = Math.floor(objectEntries.length / 2);
  const firstPart = objectEntries.slice(0, midIndex);
  const secondPart = objectEntries.slice(midIndex);

  const copyHandle = (objectEntries)  => {
    let outputStr = ''
    let count = objectEntries.length
    objectEntries.forEach((data, key) => {
      outputStr += data[0] + (key + 1 < count ? ', ' : '')
    })
    navigator.clipboard.writeText(outputStr).then(() => {})
  }

// Render two rows for the two parts
  return <>
    <div className="col-sm-6 pr-0">
      <span
        className={`monospace-font dimmed text-center`}
        style={{position: "absolute"}}
      >
        {objectEntries.length} found.
        <span
          onClick={() => copyHandle(objectEntries)}
          className={`pointer`}
        >
            📋
          </span>
      </span>
      <Table striped bordered hover className={`mt-4`} style={{borderColor: "#282E3B"}}>
        <tbody>
        {
          firstPart.map(([key, value]) => (
            <tr>
              <td className={`monospace-font dimmed text-center`}><strong>{key}</strong></td>
              <td className={`p-0 text-center`}>
                {Object.entries(value).map(([key, value2]) => (
                  <tr className={`monospace-font dimmed small`}>
                    <td style={{width: "75%", fontWeight: value2[3] === true ? 'bolder' : 'normal'}}>{value2[0]}</td>
                    <td style={{width: "25%", fontWeight: value2[3] === true ? 'bolder' : 'normal'}}>{value2[1]}</td>
                    <td style={{width: "10%"}}>
                      {value2[3] === true ? <span style={{color: '#00ff00'}}>◉</span> : <></>}
                    </td>
                  </tr>
                ))}
              </td>
            </tr>
          ))
        }
        </tbody>
      </Table>
    </div>
    <div className="col-sm-6 pl-0">
      <Table striped bordered hover className={`mt-4`} style={{borderColor: "#282E3B"}}>
        <tbody>
        {
          secondPart.map(([key, value]) => (
            <tr>
              <td className={`monospace-font dimmed text-center`}><strong>{key}</strong></td>
              <td className={`p-0 text-center`}>
                {Object.entries(value).map(([key, value2]) => (
                  <tr className={`monospace-font dimmed small`}>
                    <td style={{width: "75%", fontWeight: value2[3] === true ? 'bolder' : 'normal'}}>{value2[0]}</td>
                    <td style={{width: "25%", fontWeight: value2[3] === true ? 'bolder' : 'normal'}}>{value2[1]}</td>
                    <td style={{width: "10%"}}data-vatlue={value2[3]}>
                      {value2[3] === true ? <span style={{color: '#00ff00'}}>◉</span> : <></>}
                    </td>
                  </tr>
                ))}
              </td>
            </tr>
          ))
        }
        </tbody>
      </Table>
    </div>
  </>
}

export {RsiDivergenceTable};
