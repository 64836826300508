import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {dollarFormat, percentFormat} from "../../helpers";
import {useSelector} from "react-redux";
import {klineActions} from "../../redux";

function Header(props) {
  const {info, order, symbol, fakeContain} = props
  const {fiat, buying, remaining, remaining_percent, selling_by_symbol} = info

  const [orderBy, setOrderBy] = useState('time');
  const [isFakeContain, setIsFakeContain] = useState(false);
  const [tada, setTada] = useState(0);
  const summaries = useSelector(state => state.general.summaries);

  const kline = useSelector(state => state.kline);

  useEffect(() => {
    calcMoon()
  }, [kline])

  const orderClickHandle = (orderBy) => {
    order(orderBy)
    setOrderBy(orderBy)
  }

  const sortClickHandle = (sortBy) => {
    fakeContain(sortBy)
    setIsFakeContain(sortBy)
  }

  const calcMoon = () => {
    let tada = 0;
    let thisKline = kline

    Object.entries(summaries).map(([key, value]) => {
      let realtimePrice = 0
      const selling = selling_by_symbol[key]
      let holding = value['calc_holding']

      if (thisKline[key]) {
        realtimePrice = thisKline[key]
      } else {
        klineActions.setPriceInComponent(key).then(r => {
          const {symbol, price} = r
          thisKline = {...thisKline, [symbol]: price}
          realtimePrice = price
        })
      }

      if (selling) {
        holding = holding - selling['amount']
        tada += (selling['value'] + holding * realtimePrice)
      } else {
        tada += (holding * realtimePrice)
      }
    });

    setTada(tada)
  }

  return props.info ? <div>
      <div className="row">
        <div className="col-8 mx-auto">
          <div className="row mb-3">
            <div className="col-6">
              <div className="row">
                <h1 className="d-none d-sm-block p-2">{props.title}</h1>
              </div>
            </div>
            <div className="col-6 text-right">
              <div className="d-none d-sm-block">
                <Link to={`/`} className="monospace-font dimmed small">HOME</Link>
                <span> |  </span>
                <Link to={`/orders/${symbol}`} className="monospace-font dimmed small">ORDER LIST</Link>
                <span> |  </span>
                <Link to={`/trades/${symbol}`} className="monospace-font dimmed small">TRADE LIST</Link>

                <br/>
                <div>
                  <span className="monospace-font dimmed small">FIAT: </span>
                  <span className={`price-859-1505 price`}>
                  {dollarFormat(fiat)}</span>
                  <br/>
                  <span className="monospace-font dimmed small">BUYING: </span>
                  <span className="price-859-1505 price">{dollarFormat(buying)}</span>
                  <br/>
                  <span className="monospace-font dimmed small">FIAT REMAIN: </span>
                  <span className="price-859-1505 price">{dollarFormat(remaining)}</span>
                  <span className="smaller"> ({percentFormat(remaining_percent)})</span>
                  <br/>
                  <span className="monospace-font dimmed small">LOOK THIS 🚀: </span>
                  <span className="price-859-1505 price">{dollarFormat(tada + fiat)}</span>
                </div>

                <br/>
                <span className="monospace-font dimmed small">SORT BY: </span>
                <div className={`mt-1`}>
                  <a className={`btn btn-sm ${isFakeContain === true ? 'btn-primary' : ''}`} onClick={() => sortClickHandle(true)}>ALL</a>&nbsp;
                  <a className={`btn btn-sm ${isFakeContain === false ? 'btn-primary' : ''}`} onClick={() => sortClickHandle(false)}>JUST REAL</a>&nbsp;
                </div>
                <span className="monospace-font dimmed small">ORDER BY: </span>
                <br/>
                <div className={`mt-1`}>
                  <a className={`btn btn-sm ${orderBy === 'time' ? 'btn-primary' : ''}`} onClick={() => orderClickHandle('time')}>TIME</a>&nbsp;
                  <a className={`btn btn-sm ${orderBy === 'to_go' ? 'btn-primary' : ''}`} onClick={() => orderClickHandle('to_go')}>TO GO</a>&nbsp;
                  <a className={`btn btn-sm ${orderBy === 'side' ? 'btn-primary' : ''}`} onClick={() => orderClickHandle('side')}>SIDE</a>&nbsp;
                  <a className={`btn btn-sm ${orderBy === 'symbol' ? 'btn-primary' : ''}`} onClick={() => orderClickHandle('symbol')}>SYMBOL</a>
                </div>
                <br/>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row d-block d-sm-none p-3 hc-shadow">
        <h3 className="mt-2">
          {props.title}
        </h3>

        <Link to={`/`} className="monospace-font dimmed">HOME</Link>
        <span> | </span>
        <Link to={`/orders/${props.symbol}`} className="monospace-font dimmed">ORDER LIST</Link>
        <span> | </span>
        <Link to={`/trades/${props.symbol}`} className="monospace-font dimmed">TRADE LIST</Link>


        <br/>
        <div>
          <span className="monospace-font dimmed small">FIAT: </span>
          <span className={`price-859-1505 price`}>
                  {dollarFormat(info.fiat)}</span>
          <br/>
          <span className="monospace-font dimmed small">ORDERING: </span>
          <span className="price-859-1505 price">{dollarFormat(buying)}</span>
          <br/>
          <span className="monospace-font dimmed small">REMAINING: </span>
          <span className="price-859-1505 price">{dollarFormat(remaining)}</span>
          <span className="smaller"> ({percentFormat(remaining_percent)})</span>
          <br/>
          <span className="monospace-font dimmed small">LOOK THIS 🚀: </span>
          <span className="price-859-1505 price">{dollarFormat(tada + fiat)}</span>
        </div>

        <br/>
        <span className="monospace-font dimmed small">SORT BY: </span>
        <div className={`mt-1`}>
          <a className={`btn btn-sm ${isFakeContain === true ? 'btn-primary' : ''}`} onClick={() => sortClickHandle(true)}>ALL</a>&nbsp;
          <a className={`btn btn-sm ${isFakeContain === false ? 'btn-primary' : ''}`} onClick={() => sortClickHandle(false)}>JUST REAL</a>&nbsp;
        </div>
        <span className="monospace-font dimmed small">ORDER BY: </span>
        <br/>
        <div className={`mt-1`}>
          <a className={`btn btn-sm ${orderBy === 'time' ? 'btn-primary' : ''}`} onClick={() => orderClickHandle('time')}>TIME</a>&nbsp;
          <a className={`btn btn-sm ${orderBy === 'to_go' ? 'btn-primary' : ''}`} onClick={() => orderClickHandle('to_go')}>TO GO</a>&nbsp;
          <a className={`btn btn-sm ${orderBy === 'side' ? 'btn-primary' : ''}`} onClick={() => orderClickHandle('side')}>SIDE</a>&nbsp;
          <a className={`btn btn-sm ${orderBy === 'symbol' ? 'btn-primary' : ''}`} onClick={() => orderClickHandle('symbol')}>SYMBOL</a>
        </div>
      </div>
    </div>
    : ''
}

export {Header};
