import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {dollarFormat, donghoFormat} from "../../helpers";

function Header(props) {


  return <div>
    <div className="row mb-3">
      <div className="col-12 text-center">
        <h1 className="d-sm-block p-2 center font-weight-bold">
          {dollarFormat(props.fiatNow.usdt_amount)} <span className={`small mt-3`}>({donghoFormat(props.fiatNow.vnd_amount)})</span>
          <br/>
          <Link to={`/fiat/create`} className={`btn btn-sm mt-1`}>Create</Link>
        </h1>
        <Link to={`/`} className="monospace-font dimmed small">HOME</Link>
        <span> |  </span>
        <Link to={`/orders/${props.symbol}`} className="monospace-font dimmed small">ORDER LIST</Link>
        <span> |  </span>
        <Link to={`/trades/${props.symbol}`} className="monospace-font dimmed small">TRADE LIST</Link>

      </div>
    </div>
  </div>
}

export {Header};
