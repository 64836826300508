import React, {useEffect, useState} from 'react';
import {Header} from "./Header";
import {generalService} from "../../services";
import Table from "./components/Table";
import {Link} from "react-router-dom";
import {LAYOUT_DESKTOP, LAYOUT_MOBILE} from "../../constants";

function FuturesHome() {
  const [favNotes, setFavNotes] = useState([])
  const [profitImages, setProfitImages] = useState([])

  useEffect(() => {
    fetchList().then(r => console.log('🚃'))
    document.title = 'Futures Notes 🚀🚀'
  }, []);

  const fetchList = async () => {
    await generalService.futuresNoteGetFavorite()
      .then(data => {
        setFavNotes(data)
      });
    await generalService.profitImages()
      .then(data => {
        setProfitImages(data)
      });
  }

  const renderProfitImagesItems = () => {
    if (profitImages.length > 0) {
      return <div className="row mb-3">
        {
          profitImages.map(item => {
            return <div className="col-6 p-1">
              <img width={`100%`} alt={``} src={item}/>
            </div>
          })
        }
      </div>
    }
  }

  return (
    <section>
      <div className="container p-3">
        <Header/>
        <div className="row mb-3">
          <div className="col-lg-4 p-0">
            <table className="table">
              <thead>
              <tr>
                <th className={`text-left`} colSpan={3}>Top Notes</th>
              </tr>
              </thead>
              <tbody id="exchange-data">

              {
                favNotes.map(item => {
                  return <tr className={`traded-pair-row`} style={{lineHeight: 1.5, fontSize: '14px'}}>
                    <td>
                      {item.content} (
                      {
                        item.futures.length ? item.futures.map(futureItem => {
                          return <Link to={`/futures/create/${futureItem.id}`} className="monospace-font dimmed small">{futureItem.id}</Link>
                        }) : <></>
                      }
                      )
                    </td>
                  </tr>
                })
              }
              </tbody>
              <td className={LAYOUT_DESKTOP}>
                  {renderProfitImagesItems()}
              </td>
            </table>

          </div>
          <div className="col-lg-8 pd-2">
            <Table/>
          </div>

          <td className={LAYOUT_MOBILE}>
              {renderProfitImagesItems()}
          </td>
        </div>
      </div>
    </section>
  );
}

export {FuturesHome};
