import React, {useEffect, useRef, useState} from 'react';
import {generalService} from "../../../services";
import {OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import NumberFormat from "react-number-format";
import {thousandFormat} from "../../../helpers";

export const defaultFilter = {sort: 1, paginate: 3}
export const defaultDcaNoteInputs = {action: 1, note: ''}

function Dca({type, data, filterAction, noteDone}) {
  const [inputs, setInputs] = useState({config: ''});
  const [filter, setFilter] = useState(defaultFilter);
  const [dcaNoteInputs, setDcaNoteInputs] = useState(defaultDcaNoteInputs);
  const [dcaNoteId, setDcaNoteId] = useState(null);
  const ref = useRef('');

  useEffect(() => {
    ref.current.value = data.config

    console.log(data)
  }, [])

  // useEffect(() => {
  // }, [dcaNoteInputs])

  const handleSubmit = (e) => {
    e.preventDefault();
    const postData = {
      config: ref.current.value.toUpperCase(),
      type: type
    }
    generalService.dcaConfigStore(postData).then(r => {
      ref.current.value = r
    })
  }

  const handleSubmitDcaNote = (e) => {
    e.preventDefault();
    const postData = {...dcaNoteInputs, id: dcaNoteId}
    generalService.dcaNoteStore(postData).then(r => {
      noteDone()
    })
  }

  const handleChange = async (e) => {
    const {name, value} = e.target;
    setInputs(inputs => ({...inputs, [name]: value.toUpperCase()}));
  }

  const handleChangeFilter = async (e) => {
    const {name, value} = e.target;

    const newFilter = {...filter, [name]: parseInt(value)}
    setFilter(newFilter);

    filterAction(newFilter)
  }

  const renderDataHistory = () => {
    return <>
      <Table className={`d-none d-sm-block  mt-3 striped bordered hover small`}>
        <tbody style={{display: "table", width: "100%"}}>
        {
          data.history !== undefined ? Object.entries(data.history).map(([key, value]) => (
            <tr key={key}>
              <td width={`15%`}>
                <strong>{key}</strong>
              </td>
              <td className={`pb-0 pt-0`}  style={{display: "inline-block", width: `100%`}}>
                {
                  Object.entries(value).map(([k, item]) => (
                    <tr style={{display: "block", minWidth: '300px'}}>
                      <td width={item.week_description.length !== 5 ? `20%` : `6%`} className={`td-dca-details`}>
                        <strong  style={{maxWidth: "100px"}}>{item.week_description}</strong>
                      </td>
                      <td width={`5%`} className={`td-dca-details text-center`}>{item.is_current_week ? <p style={{color: '#00ff00', minWidth: '15px', marginBottom: '0px'}}>◉</p> : <></>}</td>
                      <td width={`20%`} className={`td-dca-details text-center`} style={{cursor: "pointer"}}>
                        {
                          item.final
                            ? <span
                                className={`${item.action === false ? 'dca-non-action' : ''}`}
                                onClick={() => {
                                  setDcaNoteId(item.id)
                                  setDcaNoteInputs({action: item.action, note: item.action_note})
                                }}
                            >
                                {item.date}
                              </span>
                            : <>
                              <span style={{display: "inline-block", marginBottom: '0.3rem'}}>{item.symbol ? item.symbol : 'N/A'}{`@${item.run_count}`}</span>
                              <br/>
                              <span>{`(${item.date})`}</span>
                            </>
                        }
                      </td>
                      <td width={item.week_description.length !== 5 ? `25%` : `32%`} className={`td-dca-details text-center`}>
                        {item.final ? item.final : '-'}
                        {
                          item.action_note ?
                            <OverlayTrigger
                              overlay={<Tooltip style={{paddingLeft: '5px'}}><span style={{whiteSpace: 'pre-line'}}>{item.action_note}</span></Tooltip>}
                              placement={`right`}
                            >
                              <span>&nbsp;<span style={{fontSize: '10px'}}>ⓘ</span></span>
                            </OverlayTrigger>
                            : <></>
                        }

                      </td>
                      <td width={item.week_description.length !== 5 ? `25%` : `32%`} className={`td-dca-details text-center`}>{item.config_amount}</td>
                    </tr>
                  ))
                }
              </td>
            </tr>
          )) : <></>
        }
        </tbody>
      </Table>
      <Table className={`d-block d-sm-none mt-3 striped bordered hover small`}>
        <tbody>
        {
          data.history !== undefined ? Object.entries(data.history).map(([key, value]) => (
            <tr key={key}>
              <td width={`15%`}>
                <strong>{key}</strong>
              </td>
              <td className={`pb-0 pt-0`}  style={{display: "inline-block", width: `100%`}}>
                <tr>
                  {
                    Object.entries(value).map(([k, item]) => (
                      <tr style={{display: "block", minWidth: '300px', marginTop: "0.25rem", marginBottom: "0.5rem"}}>
                        <strong style={{maxWidth: "100px"}}>
                          {item.week_description}&nbsp;
                          {item.is_current_week ? <span style={{color: '#00ff00', minWidth: '15px', marginBottom: '0px'}}>◉</span> : <></>}
                        </strong>
                        <br/>
                        {item.final ? item.date : 'N/A'} - {item.final ? item.final : (item.symbol ? item.symbol : 'N/A') + "@" +item.run_count+ "(" +item.date+ ")"} - {item.config_amount}
                      </tr>
                    ))
                  }
                </tr>
              </td>
            </tr>
          )) : <></>
        }
        </tbody>
      </Table>
    </>
  }

  return <>
    <div className="col-md-4 mx-auto px-3">
      <form
        id="fake-order-create-form"
        className="hc-form"
        onSubmit={handleSubmit}
      >
        <textarea
          className="form-control mt-4"
          name="config"
          ref={ref}
          rows="5"
          onChange={handleChange}
          autoComplete="off"
          style={{border: "1px solid #444", textTransform: "uppercase"}}
        />
      </form>
      <button
        className="btn btn-block btn-primary mt-3 mx-auto"
        type="submit"
        onClick={handleSubmit}
        style={{
          fontSize: '1rem',
          paddingTop: '6px',
          paddingBottom: '6px'
        }}
      >
        Save
      </button>

      <form
        className="hc-form mt-5"
        onSubmit={handleSubmit}>

        <select className="coin-select bs-select form-control text-center mt-3 pr-0"
                name="sort"
                onChange={handleChangeFilter}
        >
          <option value={1}>Current config only</option>
          <option value={2}>All</option>
        </select>

        <select className="coin-select bs-select form-control text-center mt-3 pr-0"
                name="paginate"
                onChange={handleChangeFilter}
                value={filter.paginate}
        >
          {[3, 10, 20, 50].map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
          <option value={false}>Cash Out</option>
        </select>
      </form>

      {
        dcaNoteId ? <form
            className="hc-form mt-5"
            onSubmit={handleSubmit}>

            <select className="coin-select bs-select form-control text-center mt-3 pr-0"
                    name="action"
                    value={dcaNoteInputs.action}
                    onChange={event => setDcaNoteInputs({...dcaNoteInputs, action: event.target.value})}
            >
              <option value={false}>Action - Have not</option>
              <option value={true}>Action - Done</option>
            </select>

            <textarea
              className="form-control mt-4"
              name="note"
              rows="3"
              onChange={event => setDcaNoteInputs({...dcaNoteInputs, note: event.target.value})}
              autoComplete="off"
              value={dcaNoteInputs.note ? dcaNoteInputs.note : ''}
              style={{border: "1px solid #444", textTransform: "uppercase"}}
            />
            <button
              className="btn btn-block btn-primary mt-3 mx-auto"
              type="submit"
              onClick={handleSubmitDcaNote}
              style={{
                fontSize: '1rem',
                paddingTop: '6px',
                paddingBottom: '6px'
              }}
            >
              DCA Note
            </button>
          </form>
          : <></>
      }
    </div>
    <div className="col-md-8 mx-auto px-3">
      {renderDataHistory()}
    </div>
  </>
}

export {Dca};
