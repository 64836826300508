import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {generalService} from "../../../services";
import {RsiDivergenceTable} from "./RsiDivergenceTable";
import {Loading} from "react-loading-dot";
import {DateTimePicker} from "react-datetime-picker";
import MarketVolumeAnalysis from "../MarketVolumeAnalysis";

const initialInputs = {symbols: ''}

const VolModal = ({show, setShow}) => {
  const [inputs, setInputs] = useState(initialInputs);
  const [dataRsiAnalysis, setDataRsiAnalysis] = useState({});
  const [tab, setTab] = useState('divergence');
  const [loading, setLoading] = useState(false)

  const hideModalHandle = async () => {
    document.title = 'Analysis Dashboard 🚀🚀'
    setShow(false)
  }

  useEffect(() => {}, [dataRsiAnalysis])

  const handleSubmit = (e) => {
    e.preventDefault();
    let postInputs = {}
    if (start !== null) {
      postInputs.start = generalService.formatDateTimePicker(start)
    }
    if (end !== null) {
      postInputs.end = generalService.formatDateTimePicker(end)
    }
    if (inputs.symbols !== '') {
      postInputs.symbols = inputs.symbols
    }
    setLoading(true)
    setDataRsiAnalysis({})
    generalService.rsiAnalysis(postInputs).then(r => {
      setDataRsiAnalysis(r);
      setLoading(false)
    })
  }

  const handleChange = async (e) => {
    const {name, value} = e.target;
    setInputs(inputs => ({...inputs, [name]: value.toUpperCase()}));
  }

  const currentDate = new Date();
  const [start, onStartChange] = useState(new Date(currentDate.getTime() - (12 * 60 * 60 * 1000)));
  const [end, onEndChange] = useState(currentDate);

  return (
    <>
      {loading ? <Loading background={`rgb(225, 239, 250)`}/> : ''}
      <Modal
        className="alert-pricing-modal"
        size="lg"
        show={show}
        onHide={() => hideModalHandle()}
        aria-labelledby="alert-pricing-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="alert-pricing-modal"/>
        </Modal.Header>
        <Modal.Body>
          <MarketVolumeAnalysis isModal={true}/>
        </Modal.Body>
      </Modal>
    </>
  );
}

export {VolModal};
