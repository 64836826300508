import React, {useState, useEffect} from 'react';
import {generalService} from "../../../services";

function EmaHistoryItem({key, itemValue, setItemDelete}) {
  const [isFavorite, setIsFavorite] = useState(false);
  const [hideOnDeleted, setHideOnDeleted] = useState(false);

  useEffect(() => {
      setIsFavorite(itemValue.noted)
  }, [itemValue])

  const handleSaveExp = (e) => {
    e.preventDefault();
    generalService.emaNoteCheck({id: itemValue.id}).then(r => setIsFavorite(!isFavorite))
  }

  const handleDelete = (e) => {
    e.preventDefault();
    generalService.emaNoteCheck({id: itemValue.id, type: 'delete'}).then(r => setItemDelete())
  }

  return <>
    {
      hideOnDeleted === false ?
        <tr key={key}>
          <td style={{position: "relative"}}>
            <p className={`small mb-1`} style={{userSelect: "none"}}>
              <span
                className={`price-859-1505 price dimmed`}
                style={{cursor: "pointer"}}
                onClick={handleDelete}
              >
              x
              </span>&nbsp;
              {itemValue.created_at_formatted} ◉ {itemValue.query}
            </p>
            <span className={`dimmed price small`}>{itemValue.result}</span><br/>
            <span
              className="btn-default m-1"
              onClick={handleSaveExp}
              style={{position: "absolute", top: 0, right: 0, padding: "5px"}}>
            <i className="fa fa-heart"
               aria-hidden="true"
               style={{
                 color: isFavorite ? '#2E7AD0' : '#fff',
                 paddingLeft: "5%"
               }}
            />
          </span>
          </td>
        </tr> : <></>
    }
  </>;
}

export {EmaHistoryItem};
