import React, {useState, useEffect} from 'react';
import {Link, Redirect, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {userActions} from '../redux';
import {generalService} from "../services";
import axios from "axios";

function LoginPage() {
  const [inputs, setInputs] = useState({
    passcode: '',
    symbol: localStorage.getItem('HC_FUTURE_SYMBOL_CHECKING')
  });
  const [futureCheckingRes, setFutureCheckingRes] = useState('');
  const {passcode, symbol} = inputs;
  const loggedIn = useSelector(state => state.authentication.loggedIn) ?? false;
  const dispatch = useDispatch();
  const location = useLocation();


  useEffect(() => {
  }, [futureCheckingRes]);

  const handleChange = (e) => {
    const {name, value} = e.target;
    setInputs(inputs => ({...inputs, [name]: value}));
  }

  const futureChecking = async () => {
    if (symbol === '') {
      return
    }

    localStorage.setItem('HC_FUTURE_SYMBOL_CHECKING', symbol);
    let binancePrice = 0
    let bingXPrice = 0

    await generalService.getRealtimePrice(symbol.toUpperCase() + 'USDT').then(res => {
      binancePrice = Number(res.price)
    })
    await generalService.getBingXRealtimePrice(symbol.toUpperCase()).then(res => {
      bingXPrice = Number(res)
    }).catch(err => {})

    let diff = bingXPrice - binancePrice
    let str = 'Binance: ' + binancePrice
    str += ', BingX: ' + bingXPrice
    str += ' | '
    str += diff > 0 ? '+' + diff : diff

    setFutureCheckingRes(str)
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (passcode) {
      // get return url from location state or default to home page
      const {from} = location.state || {from: {pathname: "/"}};
      let abc = dispatch(userActions.login(passcode, from));
    }
  }


  return (
    loggedIn ? <Redirect to="/"/> :
      <section>
        <div className="container p-3">
          <form role="form"
                id="login-form"
                className="hc-login-form"
                onSubmit={handleSubmit}>
            <div className="input-group">
              <input type="number"
                     className="form-control"
                     name="passcode"
                     value={passcode}
                     placeholder="What?"
                     onChange={handleChange}
              />
              <span className="input-group-btn" style={{display: 'flex', width: '23%', marginLeft: '-10px'}}>
                <button className="btn btn-default"
                        type="button"
                        onClick={handleSubmit}
                        style={{borderBottomLeftRadius: '0', borderTopLeftRadius: '0'}}
                >&gt;</button>
              </span>
            </div>
          </form>
        </div>
        {
          futureCheckingRes ? <div className={`hc-future-result`}>
            <span>{futureCheckingRes}</span>
          </div> : ''
        }
        <div className="container p-3">

          <form role="form"
                id="login-form-2"
                className="hc-future-form">
            <div className="input-group">
              <input className="form-control"
                     name="symbol"
                     value={symbol}
                     placeholder=""
                     autoComplete="off"
                     onChange={handleChange}
                     style={{
                       height: '24px',
                       fontSize: '14px',
                       textTransform: 'uppercase',
                       border: 'none',
                       borderRadius: 0
                     }}
              />
            </div>
            <div style={{display: 'inline-block'}}>
              <a href={'#'}
                 onClick={futureChecking}
                 style={{
                   fontSize: '12px',
                   display: 'flex',
                   paddingTop: '1px',
                 }}>Check</a>
            </div>
          </form>
        </div>
      </section>
  );
}

export default LoginPage;
