import {allConstants} from '../../constants';
import {generalService} from "../../services";

export const klineActions = {
  setPrice,
  initSetPrices,
  setPriceInComponent,
};

function setPrice(symbolPair) {
  return dispatch => {
    generalService.getRealtimePrice(symbolPair)
      .then(
        data => dispatch(success(data))
      );
  };

  // function request() { return { type: allConstants.GETALL_REQUEST } }
  function success(data) { return {type: allConstants.SET_KLINES, data} }
  // function failure(error) { return { type: allConstants.GET_DASHBOARD_FAILURE, error } }
}

function setPriceInComponent(symbolPair) {
  return generalService.getRealtimePrice(symbolPair).then(data => data)
}

function initSetPrices(symbolPairs) {
  return dispatch => {
    fetchAllRealtimePrices(symbolPairs).then(promiseData => {
      let dispatchData = {}
      promiseData.forEach(promiseDataItem => {
        dispatchData[promiseDataItem.symbol] = promiseDataItem.price
      })
      dispatch(success(dispatchData))
    })
  };

  function success(data) { return {type: allConstants.INIT_SET_KLINES, data} }

  async function fetchAllRealtimePrices(symbolPairs) {
    return await Promise.all(symbolPairs.map(async (symbolPair) => {
      return await callAsynchronousOperation(symbolPair)
    }))
  }

  function callAsynchronousOperation(symbolPair) {
    return generalService.getRealtimePrice(symbolPair)
      .then(data => data)
  }
}