import React, {useState} from 'react';
import {generalService} from "../../../services";
import {alertActions} from "../../../redux";

function Note(props) {
  const {futuresId, note, key} = props
  const [content, setContent] = useState(note.content);
  const [id, setId] = useState(note.id);
  const [isSummited, setIsSummited] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isFavorite, setIsFavorite] = useState(note.is_favorite);

  const handleSaveExp = (e) => {
    e.preventDefault();
    generalService.futuresNoteSaveFavorite({id: id}).then(r => setIsFavorite(!isFavorite))
  }

  const handleDelete = (e) => {
    e.preventDefault();
    if (id) {
      generalService.futuresNoteDelete(id).then(r => setIsDeleted(true))
    }
  }

  const handleSave = (e) => {
    e.preventDefault();

    e.preventDefault();
    if (!content || !futuresId) {
      return
    }
    let request = {
      'content': content
    }
    if (id) {
      request = {...request, id: id}
    }
    if (futuresId) {
      request = {...request, futures_id: futuresId}
    }
    generalService.futuresNoteStore(request).then(r => {
      setId(r.id)
      setIsSummited(true)
    })
  }

  return isDeleted ? <></> : <div className="col-12 text-right p-0">
    <input type={"hidden"} value={note.key}/>

    {
      !note.is_new_note ?
        <button className="btn btn-default m-1" type="button" onClick={handleSaveExp}>
          <i className="fa fa-heart"
             aria-hidden="true"
             style={{
               color: isFavorite ? '#2E7AD0' : '#fff',
               paddingLeft: "5%"
             }}
          />
        </button>
        : <></>
    }

    {
      note.is_new_note || isEditing ?
        <button className="btn btn-default m-1" type="button" onClick={handleSave}>
          <i className="fa fa-check"
             aria-hidden="true"
             style={{
               color: "#fff",
               paddingLeft: "5%"
             }}
          />
        </button>
        : <></>

    }
    <button className="btn btn-default m-1" type="button" onClick={handleDelete}>
      <i className="fa fa-minus"
         aria-hidden="true"
         style={{
           color: "#fff",
           paddingLeft: "5%"
         }}
      />
    </button>

    <textarea
      name="content"
      className="form-control mt-1"
      placeholder="M15 rút fib 50 đẹp..."
      value={content}
      onChange={async (e) => {
        setContent(e.target.value)
        setIsEditing(true)
      }}
    />
  </div>
}

export {Note};
