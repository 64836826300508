import {dollarFormat, donghoFormat, percentFormat} from "../../../helpers";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {generalService} from "../../../services";

function TableItem(props) {
  const {item} = props
  const [todayItem, setTodayItem] = useState({});
  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    if (item.date.substring(0, 10) === props.jsToday) {
      setTodayItem({textDecoration: 'underline'})
    }
  }, []);

  const handleDelete = (e) => {
    e.preventDefault();
    if (item.id) {
      generalService.futuresDelete(item.id).then(r => setIsDeleted(true))
    }
  }

  return isDeleted ? <></> : <tr className={`traded-pair-row price`} style={{lineHeight: 1.5, fontSize: '14px'}}>
    <td>
      <span className="price-859-1505 price">
        {
          item.position === 1 ? <button className="btn btn-sm mr-2 price up">L</button>
            : <button className="btn btn-sm mr-2 price down">S</button>
        }
        <Link to={`/futures/create/${item.id}`}><strong>{item.position_title}</strong></Link>
      </span>
      <span className={`price-859-1505 price dimmed small ml-2`} style={todayItem}>{item.date}</span>
      &nbsp;
      <a href="javascript:void(0)" className={`price-859-1505 price dimmed`} onClick={handleDelete}>x</a>
    </td>
    <td className="d-sm-table-cell text-center">
      {
        item.profit
          ? <span className={`price-859-1505 price ${item.profit > 0 ? 'up' : 'down'}`}>{percentFormat(item.profit/100)}</span>
          : <span className={`price-859-1505 price dimmed small ml-2`}>Running...</span>
      }
    </td>
  </tr>
}

export default TableItem
