import {allConstants} from '../../constants';
///fake-order/create/:symbol/:available/:realtime/:tp:/:ts
const initialState = {
  available: null,
  realtime_price:null,
  total_purchase: null,
  total_sells: null,
}

export default function fakeOrderInput(state = initialState, action) {
  switch (action.type) {
    case allConstants.SET_FAKE_ORDER_INPUT:
      return action.data
    default:
      return state
  }
}