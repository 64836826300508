import { allConstants } from '../../constants';

let token = localStorage.getItem('HC_TOKEN');
const initialState = token ? { loggedIn: true, token: token } : {};

export default function authentication(state = initialState, action) {
  switch (action.type) {
    case allConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        token: action.token
      };
    case allConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        token: action.token
      };
    case allConstants.LOGIN_FAILURE:
      return {};
    case allConstants.LOGOUT:
      return {};
    default:
      return state
  }
}
