import React, {useState, useEffect} from 'react';
import {Link, Redirect} from 'react-router-dom';
import NumberFormat from "react-number-format";
import {generalService} from "../services";
import {NonBinancePair} from "../components/NonBinancePair.jsx";

function NonBinanceTradeInputPage() {
  const [isSummited, setIsSummited] = useState(false);
  const initInput = {symbol: '', price: '', total: '', type: 'buy'}
  const [inputs, setInputs] = useState(initInput);
  const {symbol} = inputs

  const handleSubmit = (e) => {
    e.preventDefault();
    generalService.nonBinancePairCreate(inputs).then(r => {
      setInputs(initInput)
    })
  }

  useEffect(() => {
  }, [inputs])

  const handleChange = async (e) => {
    const {name, value} = e.target;
    setInputs(inputs => ({...inputs, [name]: value}));
  }

  return isSummited ? <Redirect to={`/campaign/${symbol}`}/>
    : <section>
      <div className="col-md-6 mx-auto mt-5">
        <div className="text-center">
          <h3>NON BINANCE - CREATE</h3>
          <Link to={`/`} className="monospace-font dimmed">HOME</Link>
        </div>
      </div>

      <div className="d-flex-inline justify-content-center">
        <div className="container p-3">
          {/*<div className="col-12 fake-order-create-header">*/}
          <div className="col-md-6 mx-auto mt-5">
            <form
              id="fake-order-create-form"
              className="hc-form mt-3"
              onSubmit={handleSubmit}>

              <NonBinancePair setParentPageInputs={valueA => setInputs(inputs => ({...inputs, symbol: valueA}))}/>

              <select className="coin-select bs-select form-control text-center mt-3 pr-0"
                      name="type"
                      onChange={handleChange}
              >
                <option value="buy">BUY</option>
                <option value="sell">SELL</option>
              </select>

              <NumberFormat
                className="form-control cc hc-input-not-disable mt-3"
                name="price"
                placeholder="Price"
                onChange={handleChange}
                autoComplete="off"
                value={inputs.price}
                decimalSeparator="."
                displayType="input"
                type="text"
                allowNegative={false}
                decimalScale={4}/>

              <NumberFormat
                className="form-control cc hc-input-not-disable mt-3"
                name="total"
                placeholder="Total"
                onChange={handleChange}
                autoComplete="off"
                value={inputs.total}
                decimalSeparator="."
                displayType="input"
                type="text"
                allowNegative={false}
                decimalScale={4}/>

              <button className="btn btn-block btn-primary mt-3"
                      type="button"
                      onClick={handleSubmit}
                      style={{
                        fontSize: '1rem',
                        paddingTop: '6px',
                        paddingBottom: '6px'
                      }}
              >
                SAVE
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
}

export default NonBinanceTradeInputPage;