import React, {useEffect, useRef, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {generalService} from "../../../services";
import {Table} from "react-bootstrap";
import {AlertPricingItem} from "./AlertPricingItem";
import {moreDigitsDollarFormat} from "../../../helpers";
import {useDispatch} from "react-redux";
import {alertActions} from "../../../redux";
import {Link} from "react-router-dom";

const initialInputs = {id: null, symbol: '', price: '', note: '', frame: null}

const AlertPricingModal = ({show, setShow}) => {
  const dispatch = useDispatch();
  let [inputs, setInputs] = useState(initialInputs);
  const [dataAlertItems, setDataAlertItems] = useState([]);
  const [tab, setTab] = useState({id: 'pricing', trendQuery: 'bullish'});
  const [historyList, setHistoryList] = useState([]);
  const [oldSignalHistoryList, setOldSignalHistoryList] = useState([]);
  const [summaryCheckpoint, setSummaryCheckpoint] = useState(null);
  const [historySummary, setHistorySummary] = useState([]);
  const [alertCategories, setAlertCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState('NON-TYPE');
  const [sort, setSort] = useState('symbol');
  const ref = useRef('');

  const hideModalHandle = async () => {
    document.title = 'Analysis Dashboard 🚀🚀'
    setShow(false)
    setInputs(initialInputs);
  }

  useEffect(() => {
    document.title = 'Alert | Analysis Dashboard 🚀🚀'
  }, [])

  useEffect(() => {
    setTab({id: 'pricing', trendQuery: 'bullish'})

    generalDataFetch()

    const handleKeyDown = (event) => {
      if (event.shiftKey && event.keyCode === 82 && show === true) {
        // SHIFT + r pressed
        onRefresh();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [show, currentCategory, sort])

  useEffect(() => {
    generalService.alertHistories(tab.trendQuery).then(r => {
      setSummaryCheckpoint(r.checkpoint)
      setHistoryList(r.history)
      setHistorySummary(r.summary)
    })
  }, [summaryCheckpoint])

  useEffect(() => {
  }, [dataAlertItems])

  useEffect(() => {
    generalService.alertHistories(tab.trendQuery).then(r => {
      setSummaryCheckpoint(r.checkpoint)
      setHistoryList(r.history)
      setHistorySummary(r.summary)
    })
  }, [tab])

  const generalDataFetch = () => {
    generalService.alertPricingGet(currentCategory, sort).then(r => {
      setDataAlertItems(r.alerts)
      setAlertCategories(r.categories)
    })

    generalService.alertHistories(tab.trendQuery).then(r => {
      setSummaryCheckpoint(r.checkpoint)
      setHistoryList(r.history)
      setHistorySummary(r.summary)
    })

    generalService.alertOldSignalHistories().then(r => {
      setOldSignalHistoryList(r)
    })
  }

  const categoryChange = (category) => {
    generalService.alertPricingGet(category, sort).then(r => {
      setDataAlertItems(r.alerts)
      setCurrentCategory(category)
    })
  }

  const categoryHint = (category) => {
    let currentRefVal = ref.current.value

    if (currentRefVal.length === 0) {
      ref.current.value = '[' + category + '] '
    } else {
      const patternToRemove = /\[(.+?)\]/g;
      const updatedRefVal = currentRefVal.replace(patternToRemove, '');
      ref.current.value = '[' + category + '] ' + updatedRefVal.trimLeft()
    }
    ref.current.focus()
  }

  const generateHints = () => {
    if (alertCategories.length > 0) {
      const toGens = alertCategories.slice().reverse()
      return toGens.map(item => {
        if (!['ALL', 'NON-TYPE'].includes(item)) {
          return <span
            className={`dimmed price small mt-2 mr-2`}
            style={{cursor: "pointer", float: 'left'}}
            onClick={() => categoryHint(item.toUpperCase())}
          >
            {item}
          </span>
        } else {
          return ''
        }
      })
    }
  }

  const sortChange = () => {
    let sortToChange = ''
    if (sort === 'date') {
      sortToChange = 'symbol'
    } else {
      sortToChange = 'date'
    }

    generalService.alertPricingGet(currentCategory, sortToChange).then(r => {
      setDataAlertItems(r.alerts)
      setCurrentCategory(r.current_category)

      setSort(sortToChange)
    })
  }

  const onRefresh = () => {
    dispatch(alertActions.success('Refresh!'));
    generalDataFetch()
    if (tab.id !== 'old_signal') {
      setInputs(initialInputs)
      ref.current.value = ''
    }
  }

  const onPauseChanged = () => {
    generalService.alertPricingGet(currentCategory, sort).then(r => {
      setDataAlertItems(r.alerts)
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let inputsToPost = {...inputs, sort: sort, note: ref.current.value.toUpperCase()}
    generalService.alertPricingStore(inputsToPost).then(r => {
      setInputs(initialInputs);
      setDataAlertItems(r.alerts);
      setCurrentCategory(r.current_category);
      ref.current.value = ''
    })
  }

  const handleChange = async (e) => {
    const {name, value} = e.target;
    setInputs(inputs => ({...inputs, [name]: value.toUpperCase()}));
  }

  const toEdit = async (id) => {
    let item = dataAlertItems.find(x => x.id === id);
    let toInput = {
      id: item.id,
      symbol: item.symbol,
      price: moreDigitsDollarFormat(item.price, false),
      frame: item.close_at_frame === null ? null : item.close_at_frame.toLowerCase(),
      note: item.note
    }
    ref.current.value = item.note
    await setInputs(toInput)
  }

  const renderFrameOptions = () => {
    return ['hit', 'm15a', 'm15b', 'h1a', 'h1b', 'h4a', 'h4b'].map(item => {
      return <strong
        className={`dimmed mx-2 pointer`}
        style={{
          textDecoration: inputs.frame === null
            ? item === 'hit' ? 'underline' : 'none'
            : inputs.frame === item ? 'underline' : 'none',
          color: "#2E7AD0"
        }}
        onClick={() => {
          setInputs(inputs => ({...inputs, frame: item}))
        }}
      >
        {item.toUpperCase()}
      </strong>
    })
  }

  const handleSaveCheckPoint = async (id) => {
    generalService.saveCheckPoint({id: id}).then(r => {
      setSummaryCheckpoint(id)
    })
  }

  return (
    <>
      <Modal
        className="alert-pricing-modal"
        size="lg"
        show={show}
        onHide={() => hideModalHandle()}
        aria-labelledby="alert-pricing-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="alert-pricing-modal"/>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-1">
            <div className="col-md-6 mx-auto text-center">
              <span
                className={`monospace-font dimmed`}
                style={{color: "#2E7AD0", cursor: "pointer", textDecoration: tab.id === 'pricing' ? "underline" : "none"}}
                onClick={() => setTab({id: 'pricing', trendQuery: 'bullish'})}
              >
                Pricing
              </span>&nbsp;|&nbsp;
              <span
                className={`monospace-font dimmed`}
                style={{color: "#2E7AD0", cursor: "pointer", textDecoration: tab.id === 'history' && tab.trendQuery === 'bullish' ? "underline" : "none"}}
                onClick={() => setTab({id: 'history', trendQuery: 'bullish'})}
              >
                History Bull
              </span>&nbsp;|&nbsp;
              <span
                className={`monospace-font dimmed`}
                style={{color: "#2E7AD0", cursor: "pointer", textDecoration: tab.id === 'history' && tab.trendQuery === 'bearish' ? "underline" : "none"}}
                onClick={() => setTab({id: 'history', trendQuery: 'bearish'})}
              >
                History Bear
              </span>&nbsp;|&nbsp;
              <span
                className={`monospace-font dimmed`}
                style={{color: "#2E7AD0", cursor: "pointer", textDecoration: tab.id === 'old_signal' ? "underline" : "none"}}
                onClick={() => setTab({id: 'old_signal', trendQuery: 'bullish'})}
              >
                Old Signal
              </span>
              <span
                className={`monospace-font dimmed`}
                style={{cursor: "pointer"}}
                onClick={onRefresh}
              >
                <i className="fa fa-sync-alt"
                   aria-hidden="true"
                   style={{
                     color: "#fff",
                     paddingLeft: "1%"
                   }}
                />
              </span>
              <br/>
              <br/>
            </div>
          </div>

          {tab.id === 'pricing' ?
            <div className="row">
              <div className="col-md-6 mx-auto px-3">
                <form
                  id="fake-order-create-form"
                  className="hc-form"
                  onSubmit={handleSubmit}
                >
                  <input
                    className="form-control cc hc-input-not-disable mt-3"
                    name="symbol"
                    placeholder="Symbol"
                    onChange={handleChange}
                    autoComplete="off"
                    value={inputs.symbol}
                    type="text"/>

                  <input
                    className="form-control cc hc-input-not-disable mt-3"
                    name="price"
                    placeholder="Price"
                    onChange={handleChange}
                    autoComplete="off"
                    value={inputs.price}
                    type="text"/>

                  <div className="mt-2" style={{textAlign: "center"}}>
                    {renderFrameOptions()}
                  </div>

                  <textarea
                    className="form-control mt-2"
                    name="note"
                    ref={ref}
                    autoComplete="off"
                    style={{border: "1px solid #444", textTransform: "uppercase"}}
                  />

                  <button
                    className="btn btn-block btn-primary mt-3 mx-auto"
                    type="submit"
                    onClick={handleSubmit}
                    style={{
                      fontSize: '1rem',
                      paddingTop: '6px',
                      paddingBottom: '6px'
                    }}
                  >
                    Save
                  </button>
                  {generateHints()}

                </form>
              </div>
              <div className="col-md-6 mx-auto">
                <Table striped bordered hover className={`mt-3`} style={{borderColor: "#282E3B"}}>
                  <thead>
                  <tr>
                    <th>
                      Alerts
                      &nbsp;
                      <span
                        className={`pointer`}
                        onClick={sortChange}
                      >
                        [{sort}↓]
                      </span>
                        {
                          alertCategories.length > 0 ? alertCategories.map(item => {
                            return <span
                              className={`dimmed pl-2`}
                              style={{
                                color: "#2E7AD0",
                                cursor: "pointer",
                                float: 'right',
                                textDecoration: item.toUpperCase() === currentCategory ? 'underline' : 'none'
                              }}
                              onClick={() => categoryChange(item.toUpperCase())}
                            >
                              {item}
                            </span>
                          }) : <></>
                        }
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {dataAlertItems !== undefined ? Object.entries(dataAlertItems).map(([key, value]) => (
                    <AlertPricingItem
                      key={key}
                      itemValue={value}
                      afterDelete={onRefresh}
                      setItemToEdit={toEdit}
                      setPauseChanged={onPauseChanged}
                    />
                  )) : <></>
                  }
                  </tbody>
                </Table>
              </div>
            </div> : <></>
          }

          {tab.id === 'history' ?
            <div className="row mt-3">
              <div className="col-md-4 mx-auto">
                <div className="row">
                  <div className="col-sm-6">
                    <table className="table table-hover text-center">
                      <thead>
                      <tr>
                        <th className="d-sm-table-cell">Symbol</th>
                        <th className="d-sm-table-cell">Total</th>
                        <th className="d-sm-table-cell">Bull</th>
                        <th className="d-sm-table-cell">Bear</th>
                      </tr>
                      </thead>
                      <tbody id="exchange-data">
                      {historySummary !== [] &&
                      historySummary.slice(0, Math.ceil(historySummary.length / 2)).map(item => {
                        return (
                          <tr className={`traded-pair-row`}>
                            <td className="d-sm-table-cell">
                              <span className="price-859-1505 price small">{item.symbol}</span>
                            </td>
                            <td className="d-sm-table-cell">
                              <span className="price-859-1505 price small">{item.total}</span>
                            </td>
                            <td className="d-sm-table-cell">
                              <span className="price-859-1505 price small">{item.bull}</span>
                            </td>
                            <td className="d-sm-table-cell">
                              <span className="price-859-1505 price small">{item.bear}</span>
                            </td>
                          </tr>
                        )
                      })}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-sm-6">
                    <table className="table table-hover text-center">
                      <thead>
                      <tr>
                        <th className="d-sm-table-cell">&nbsp;</th>
                        <th className="d-sm-table-cell">&nbsp;</th>
                        <th className="d-sm-table-cell">&nbsp;</th>
                        <th className="d-sm-table-cell">&nbsp;</th>
                      </tr>
                      </thead>
                      <tbody id="exchange-data">
                      {historySummary !== [] &&
                      historySummary.slice(Math.ceil(historySummary.length / 2)).map(item => {
                        return (
                          <tr className={`traded-pair-row`}>
                            <td className="d-sm-table-cell">
                              <span className="price-859-1505 price small">{item.symbol}</span>
                            </td>
                            <td className="d-sm-table-cell">
                              <span className="price-859-1505 price small">{item.total}</span>
                            </td>
                            <td className="d-sm-table-cell">
                              <span className="price-859-1505 price small">{item.bull}</span>
                            </td>
                            <td className="d-sm-table-cell">
                              <span className="price-859-1505 price small">{item.bear}</span>
                            </td>
                          </tr>
                        )
                      })}
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>
              <div className="col-md-5 mx-auto px-3">
                <table className="table table-hover text-center" style={{display: "block", height: "80vh", overflowY: "scroll"}}>
                  <thead>
                  <tr>
                    <th className="d-sm-table-cell text-left" style={{width: "20vw"}}>Symbol</th>
                    <th className="d-sm-table-cell text-center" style={{width: "50vw"}}>Reason</th>
                    <th className="d-sm-table-cell" style={{width: "50vw"}}>Fired At</th>
                  </tr>
                  </thead>
                  <tbody id="exchange-data">
                  {
                    historyList !== [] ?
                      historyList.map(item => {
                        return <tr className={`traded-pair-row`}>
                          <td className="d-sm-table-cell text-left" style={{width: "20vw"}}>
                            <span className="price-859-1505 price small">
                              {item.symbol}
                              &nbsp;
                              <span className={`dimmed price small`}>
                                [{item.trend.toUpperCase()}]
                              </span>
                            </span>
                          </td>
                          <td className="d-sm-table-cell text-center" style={{width: "50vw"}}>
                            <span className="price-859-1505 price small">{item.reason}</span>
                          </td>
                          <td className="d-sm-table-cell" style={{width: "50vw"}}>
                            <span className="price-859-1505 price small">
                              {item.created_at_formatted}
                              <span
                                className="btn-default m-1"
                                onClick={() => handleSaveCheckPoint(item.id)}
                              >
                                <i className="fa fa-check-circle"
                                   aria-hidden="true"
                                   style={{
                                     color: item.id === summaryCheckpoint ? '#2E7AD0' : '#fff',
                                     paddingLeft: "5%"
                                   }}
                                />
                              </span>
                            </span>
                          </td>
                        </tr>
                      })
                      : <></>
                  }
                  </tbody>
                </table>
              </div>
            </div> : <></>
          }

          {tab.id === 'old_signal' ?
            <div className="row">
              <div className="col-md-6 mx-auto">
                <Table striped bordered hover text-center className={`mt-3`} style={{borderColor: "#282E3B", fontSize: "14px"}}>
                  <thead>
                  <tr>
                    <th>Content</th>
                    <th style={{textAlign: "center"}}>Datetime</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    oldSignalHistoryList !== [] ?
                      oldSignalHistoryList.map(item => {
                        return <tr className={`traded-pair-row`}>
                          <td className="d-sm-table-cell text-left" style={{width: "50vw"}}>
                            <span className="price-859-1505 price" style={{whiteSpace: 'pre-line', lineHeight: 1.5}}>
                                {item.content}
                            </span>
                          </td>
                          <td className="d-sm-table-cell" style={{width: "12vw", verticalAlign: "middle", textAlign: "center"}}>
                            <span className="price-859-1505 price">{item.created_at}</span>
                          </td>
                        </tr>
                      })
                      : <></>
                  }
                  </tbody>
                </Table>
              </div>
            </div> : <></>
          }

          <div style={{position: "absolute", bottom: 0, right: 0}} className="m-lg-1 mx-lg-3">
              <Link to={`/alert-pricing`} className="monospace-font dimmed price small mt-2 mr-2" style={{fontStyle:'italic'}}>*Page</Link>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export {AlertPricingModal};
