import React, {useEffect, useState} from 'react';
import {generalService} from "../services";

function NonBinancePair(props) {
  const [pairList, setPairList] = useState([]);
  const [isAddPairAreaShow, setIsAddPairAreaShow] = useState(false);

  const initInput = {pairToCreate: '', coingeckoId: ''}
  const [inputs, setInputs] = useState(initInput);
  const {pairToCreate, coingeckoId} = inputs;

  useEffect(() => {
    fetchPairList().then(r => console.log('🚃'))

    if (pairList.length && pairList[0]) {
      props.setParentPageInputs(pairList[0].symbol)
    }
  }, [])

  useEffect(() => {
    if (pairList.length && pairList[0]) {
      props.setParentPageInputs(pairList[0].symbol)
    }
  }, [pairList])

  const fetchPairList = async () => {
    generalService.getNonBinancePairList().then(res => {
      setPairList(res.data)
    })
  }

  const handleChange = async (e) => {
    const {name, value} = e.target;
    setInputs(inputs => ({...inputs, [name]: value}));
  }

  const handleSymbolChange = async (e) => {
    const {value} = e.target;
    props.setParentPageInputs(value);
  }

  const savePairHandle = async () => {
    let pairToCreateUpper = pairToCreate.toUpperCase()
    generalService.saveNonBinancePair(pairToCreateUpper, coingeckoId).then(r => {
      setIsAddPairAreaShow(false)
      setInputs(initInput)
      fetchPairList().then(r => console.log('🚃'))
    })
  }

  return <>
    <div className="col-12 text-right p-0">
      <button className="btn ext-right"
              type="button"
              onClick={() => setIsAddPairAreaShow(!isAddPairAreaShow)}
      >
        {!isAddPairAreaShow ? `Add new` : `Cancel`}
      </button>
      {
        isAddPairAreaShow ?
          <>
            <button className="btn ml-1 btn-primary" type="button" onClick={savePairHandle}>Save</button>
            <div className={`mt-2`}>
              <input type="text"
                     className="form-control"
                     name="pairToCreate"
                     value={pairToCreate}
                     placeholder={`Pair to create`}
                     onChange={handleChange}
              />
              <input type="text"
                     className="form-control mt-2"
                     name="coingeckoId"
                     value={coingeckoId}
                     placeholder={`Coingecko ID to create`}
                     onChange={handleChange}
              />
            </div>
          </>
            : <></>
      }
    </div>

    {
      pairList.length > 0 ?
        <select className="coin-select bs-select form-control text-center mt-3 pr-0"
                name="symbol"
                onChange={handleSymbolChange}
        >
          {
            pairList.map(item => {
              return <option value={item.symbol} data-value-id={item.id}>{item.symbol}</option>
            })
          }
        </select> : <></>
    }
  </>
}

export {NonBinancePair};
