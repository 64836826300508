export function authHeader() {
  // return authorization header with jwt token
  let token = localStorage.getItem('HC_TOKEN');

  if (token) {
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    };
  } else {
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
}