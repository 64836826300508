import { allConstants } from '../../constants';
import {generalService} from "../../services";
import {push} from "connected-react-router";

export const generalActions = {
  getDashboardData,
  updateDashboardData,
  saveCampaign,
  // getOrderList,
};

function getDashboardData() {
  return dispatch => {
    generalService.getDashboardData()
      .then(
        data => dispatch(success(data)),
        error => dispatch(failure(error.toString()))
      );
  };

  // function request() { return { type: allConstants.GETALL_REQUEST } }
  function success(data) { return { type: allConstants.GET_DASHBOARD_SUCCESS, data } }
  function failure(error) { return { type: allConstants.GET_DASHBOARD_FAILURE, error } }
}

function updateDashboardData() {
  return dispatch => {
    generalService.updateDashboardData()
      .then(
        data => {
          dispatch(success(data))
        },
      );
  // };

  // function request() { return { type: allConstants.GETALL_REQUEST } }
  function success(data) { return { type: allConstants.GET_DASHBOARD_SUCCESS, data } }

}}

// function getOrderList(query) {
//   return dispatch => {
//     generalService.getOrderList(query)
//       .then(data => {
//         return data
//       })
//   };
// }

function saveCampaign(symbol, endId) {
  return dispatch => {
    generalService.saveCampaign(symbol, endId)
      .then(
        data => {
          dispatch(success(data))


          // eslint-disable-next-line no-restricted-globals
          location.reload()
        },
      );
  };

  function success(data) { return { type: allConstants.GET_DASHBOARD_SUCCESS, data } }
}