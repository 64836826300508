import React, {useEffect, useState} from 'react';
import {Header} from "./Header";
import {Table} from "./Table";
import {useParams} from "react-router";
import {generalService} from "../../services";


function OrderListPage() {
  const {symbol} = useParams();
  const [data, setData] = useState([])
  const [orderBy, setOrderBy] = useState([])
  const [sortBy, setSortBy] = useState([])

  useEffect(() => {
    document.title = symbol === 'all'
      ? 'Order list 🚀🚀'
      : symbol.replace('USDT', '') + ' | Order list 🚀🚀';
    fetchList().then(r => console.log('🚃'))
  }, []);

  const fetchList = async () => {
    const query = symbol === 'all' ? '' : symbol
    await generalService.getOrderList(query)
      .then(data => {
        setData(data)
      });
  }

  return (data && Object.keys(data).length !== 0) ? <section>
      <div className="container p-3">
        <Header title={`NEW ORDER ${symbol !== 'all' ? '- ' + symbol : ''}`}
                symbol={symbol}
                info={data.info}
                order={orderBy => setOrderBy(orderBy)}
                fakeContain={sortBy => setSortBy(sortBy)}
        />
        <Table list={data.list} orderBy={orderBy} sortBy={sortBy}/>
      </div>
    </section> : <></>
}

export {OrderListPage};
