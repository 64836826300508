import { allConstants } from '../../constants';

const initState = {
  isViewerOpen: false,
  filename: []
}

export default function imageViewer(state = initState, action) {
  switch (action.type) {
    case allConstants.SET_IMAGE_VIEWS:
      return action.data
    default:
      return state
  }
}
