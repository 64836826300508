import React, {useEffect} from "react";
import {useSelector} from 'react-redux';
import {Header, GeneralTable} from "../components";

function HomePage() {
  const general = useSelector(state => state.general);

  useEffect(() => {
    document.title = 'TO THE MOON 🚀🚀';
  }, []);

  return general ?
    <section>
      <div className="container p-3">
        <Header data={general}/>
        <GeneralTable summaries={general.summaries}
                      symbol_pairs={general.symbol_pairs}
        />
      </div>
    </section>
    : <></>
}

export default HomePage;
