import React, {useState} from 'react';
import {Link} from "react-router-dom";

function Header(props) {
  const [sortBy, setSortBy] = useState('all');

  const orderClickHandle = (sortBy) => {
    props.sort(sortBy)
    setSortBy(sortBy)
  }

  return <div>
    <div className="row">
      <div className="col-8 mx-auto">
        <div className="row mb-3">
          <div className="col-6">
            <div className="row">
              <h1 className="d-none d-sm-block p-2">{props.title}</h1>
            </div>
          </div>
          <div className="col-6 text-right">
            <div className="d-none d-sm-block">
              <Link to={`/`} className="monospace-font dimmed small">HOME</Link>
              <span> |  </span>
              <Link to={`/orders/${props.symbol}`} className="monospace-font dimmed small">ORDER LIST</Link>
              <span> |  </span>
              <Link to={`/trades/${props.symbol}`} className="monospace-font dimmed small">TRADE LIST</Link>

              <br/>
              <span className="monospace-font dimmed small">FILTER BY: </span>
              <br/>
              <div className={`mt-1`}>
                <div className={`mt-1`}>
                  <a className={`btn btn-sm ${sortBy === 'all' ? 'btn-primary' : ''}`} onClick={() => orderClickHandle('all')}>ALL</a>&nbsp;
                  <a className={`btn btn-sm ${sortBy === 'buy' ? 'btn-primary' : ''}`} onClick={() => orderClickHandle('buy')}>BUY</a>&nbsp;
                  <a className={`btn btn-sm ${sortBy === 'sell' ? 'btn-primary' : ''}`} onClick={() => orderClickHandle('sell')}>SELL</a>&nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="row d-block d-sm-none p-3 hc-shadow">
      <h3 className="mt-2">
        {props.title}
      </h3>

      <Link to={`/`} className="monospace-font dimmed">HOME</Link>
      <span> | </span>
      <Link to={`/orders/${props.symbol}`} className="monospace-font dimmed">ORDER LIST</Link>
      <span> | </span>
      <Link to={`/trades/${props.symbol}`} className="monospace-font dimmed">TRADE LIST</Link>

      <br/>
      <span className="monospace-font dimmed small">ORDER BY: </span>
      <br/>
      <div className={`mt-1`}>
        <a className={`btn btn-sm ${sortBy === 'all' ? 'btn-primary' : ''}`} onClick={() => orderClickHandle('all')}>ALL</a>&nbsp;
        <a className={`btn btn-sm ${sortBy === 'buy' ? 'btn-primary' : ''}`} onClick={() => orderClickHandle('buy')}>BUY</a>&nbsp;
        <a className={`btn btn-sm ${sortBy === 'sell' ? 'btn-primary' : ''}`} onClick={() => orderClickHandle('sell')}>SELL</a>&nbsp;
      </div>
    </div>
  </div>
}

export {Header};