import React, {useEffect, useState} from 'react';
import {dollarFormat, dollarFormatLargeDecimal, moreDigitsDollarFormat, numberColor, percentFormat, thousandFormat} from "../helpers";
import {useDispatch, useSelector} from "react-redux";
import {allConstants, LAYOUT_DESKTOP, LAYOUT_MOBILE, LAYOUT_3, PRICE_1, PRICE_2, PRICE_3, PRICE_4, PRICE_5, PRICE_6, TITLE_1} from "../constants";
import {Link} from "react-router-dom";

// data.symbolPair = '';

function TableItem(props) {
  const {data, crypto, realtime} = props
  const {
    real_holding,
    calc_holding,
    avg,
    net_cost,
    total_purchases,
    total_sells
  } = data

  const dispatch = useDispatch();
  const [realtimePrice, setRealtimePrice] = useState({
    'price': 0,
    'price_pl_percent': 1,
    'market_value': 0,
    'market_value_pl': 0,
    'market_value_pl_percent': 0,
  })
  const {
    binance_market_value,
    price_pl_percent,
    price,
    market_value_pl,
    market_value,
    market_value_pl_percent
  } = realtimePrice

  useEffect(() => {
    calcRealtimePrice().then(() => {
    })
  }, [realtime])

  const calcRealtimePrice = async () => {
    let {real_holding, calc_holding, avg, net_cost, total_purchases} = data

    // console.log(crypto, realtime)
    let price = realtime
    let pricePl = price - avg
    let pricePlPercent = pricePl / avg

    let binanceMarketValue = real_holding * price
    let binanceMarketPl = binanceMarketValue - net_cost
    let binanceMarketPlPercent = binanceMarketPl / total_purchases

    let holding = real_holding > calc_holding ? real_holding : calc_holding
    let marketValue = holding * price
    let marketPl = marketValue - net_cost
    let marketPlPercent = marketPl / total_purchases

    dispatch({
      type: allConstants.SET_BINANCE_TOTAL_MARKET,
      data: {
        'symbol': crypto,
        'total_market': binanceMarketValue
      }
    })

    // // hardcode vì ném qua hết cake r, còn lẻ chưa tới 1 đồng
    if (crypto === 'BNBUSDT') {
      marketValue = binanceMarketValue
    }

    dispatch({
      type: allConstants.SET_TOTAL_MARKET,
      data: {
        'symbol': crypto,
        'total_market': marketValue
      }
    })

    await setRealtimePrice({
      'price': price,
      'price_pl_percent': pricePlPercent,
      'binance_market_value': binanceMarketValue,
      'binance_market_value_pl': binanceMarketPl,
      'binance_market_value_pl_percent': binanceMarketPlPercent,
      'market_value': marketValue,
      'market_value_pl': marketPl,
      'market_value_pl_percent': marketPlPercent,
    })
  }

  return realtime ? <>
    <tr className={LAYOUT_3} style={{lineHeight: 1.5, fontSize: '14px'}}>
      <td>
        <Link to={`/campaign/${crypto}`}><strong>{crypto}</strong> </Link>
        <br/>
        <span className={PRICE_1}
              style={{color: '#2E7AD0'}}>
          {thousandFormat(real_holding)} ({dollarFormat(binance_market_value)})
        </span>
        <div className={`d-sm-none`}>
          <span className={PRICE_2} style={{color: '#2E7AD0'}}>{moreDigitsDollarFormat(avg)}</span>
          <br/>
          <span className={`${PRICE_2} ${numberColor(price_pl_percent)}`}>
            {moreDigitsDollarFormat(price)}
          </span>
          <span className={`${PRICE_3} ${numberColor(price_pl_percent)}`}>
            &nbsp;({percentFormat(price_pl_percent)})
          </span>
        </div>
      </td>
      <td className={LAYOUT_MOBILE}>
        <span className={TITLE_1}>HOLDING</span>
        <br/>
        <span className={PRICE_2}>{thousandFormat(calc_holding)}</span>
        <br/>

        <span className={TITLE_1}>MARKET VALUE</span>
        <br/>
        <span className={`${PRICE_2} ${numberColor(market_value_pl)}`}>
          {dollarFormat(market_value)}&nbsp;
          <span className={`smaller`}>({dollarFormat(market_value_pl)})</span>
        </span>
        <br/>

        <span className={TITLE_1}>NET COST</span>
        <br/>
        <span className={PRICE_2}>{dollarFormat(net_cost)}</span>
        <br/>

        <span className={PRICE_6}>
          {`(${dollarFormat(total_purchases)} - ${dollarFormat(total_sells)})`}
        </span>
      </td>
      <td className={LAYOUT_DESKTOP}>
        <span className={PRICE_2}>{thousandFormat(calc_holding)}</span>
        <br/>
        <span className={PRICE_2}>{dollarFormat(net_cost)}</span>
        <br/>
        <span className={PRICE_6}>
          {`(${dollarFormat(total_purchases)} - ${dollarFormat(total_sells)})`}
        </span>
      </td>
      <td className={LAYOUT_DESKTOP}>
        <span className={`${PRICE_2} ${numberColor(market_value_pl)}`}>
          {dollarFormat(market_value)}
          <span className={`smaller`}>
            &nbsp;({dollarFormat(market_value_pl)})
          </span>
        </span>
      </td>
      <td className={LAYOUT_DESKTOP}>
        <span className={`${PRICE_5} 
          ${numberColor(price_pl_percent)}`}>{moreDigitsDollarFormat(price)}
        </span>
        <span className={`${PRICE_3} ${numberColor(price_pl_percent)}`}>
        </span>
        <br/>
        <span className={PRICE_4}>{moreDigitsDollarFormat(avg)}</span>
      </td>
      <td className={`text-right`}>
        <span className={`${PRICE_5} ${numberColor(price_pl_percent)}`}>
          {percentFormat(price_pl_percent)}
        </span>
      </td>
    </tr>
  </> : <></>
}

function GeneralTable(props) {
  const kline = useSelector(state => state.kline);

  useEffect(() => {
    renderListItem()
  }, [])

  useEffect(() => {
    renderListItem()
  }, [kline, props.summaries])

  const renderListItem = () => {
    if (!props.summaries) {
      return;
    }

    const summaries = Object.entries(props.summaries).map(([key, value]) => ({key, value}));

    if (summaries.length > 0) {
      return summaries.map(item => {
        const index = item.key
        const realtimePrice = kline ? kline[index] ?? 0 : 0

        return <TableItem key={index}
                          crypto={index}
                          data={item.value}
                          realtime={realtimePrice}/>
      })
    }

    return <></>
  }

  return <table className="table mt-3" style={{fontSize: '14px'}}>
    <thead>
    <tr>
      <th>Crypto</th>
      <th className={LAYOUT_MOBILE}>Value</th>
      <th className={LAYOUT_DESKTOP}>Net Cost</th>
      <th className={LAYOUT_DESKTOP}>Market Value</th>
      <th className={LAYOUT_DESKTOP}>Price</th>
      <th className="text-right">Profit/Loss</th>
    </tr>
    </thead>
    <tbody id="exchange-data">
    {renderListItem()}
    </tbody>
  </table>
}

export {GeneralTable};
