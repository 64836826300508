import React, {useState, useEffect} from 'react';
import {generalService} from "../../../services";
import {Table} from "react-bootstrap";
import {Loading} from "react-loading-dot";
import {alertActions} from "../../../redux";
import {DateTimePicker} from "react-datetime-picker";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

function EmaIntegrateVol() {
  const initInput = {
    symbol: 'btc',
    interval: 'm5',
    type: 'high_vol_sma'
  }
  const [inputs, setInputs] = useState(initInput);
  const [data, setData] = useState('undefined');
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();

    let postInput = inputs
    const arrSymbol = postInput.symbol.split(',')
    if (arrSymbol.length < 2) {
      postInput = ({...postInput, symbol: inputs.symbol + ', btc'})
    }

    postInput = ({...postInput
      , start: generalService.formatDateTimePicker(start)
      , end: generalService.formatDateTimePicker(end)
    })
    setLoading(true)
    generalService.marketVolumeAnalysis(postInput).then(r => {
      setData(r.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    // if (loading === true) {
    //   setLoading(false)
    // }
  }, [data])

  const handleChange = async (e) => {
    const {name, value} = e.target;
    setInputs(inputs => ({...inputs, [name]: value}));
  }

  const handleIntervalChange = async (e) => {
    setInputs({...inputs, interval: e.target.value});
  }

  const handleTypeChange = async (e) => {
    setInputs({...inputs, type: e.target.value});
  }

  const copyHandle = (sort) => {
    if (data !== 'undefined') {
      let outputStr = ''
      let count = data.length
      let sortedData = sort === 'asc' ? data.slice().reverse() : data
      sortedData.forEach((data, key) => {
        outputStr += data[3] + (key + 1 < count ? ', ' : '');
      })
      navigator.clipboard.writeText(outputStr).then(() => {})
    }
  }

  const dataShowing = () => {
    return (
      data !== 'undefined' ?
        <div className={`mx-auto mt-3 text-center`} style={{width: "10rem"}}>
          <span
            style={{position: "absolute", right: 0, cursor: "pointer"}}
            onClick={() => copyHandle('desc')}
          >
            📋 ↓
          </span>
          <span
            style={{position: "absolute", right: 0, cursor: "pointer", top: "2.5rem"}}
            onClick={() => copyHandle('asc')}
          >
            📋 ↑
          </span>
          <table>
          {
            data !== 'undefined' ? data.map((data) => (
              <tr className={``} style={{fontSize: "13px"}}>
                <td style={{minWidth: "50px"}}>{data[3]}</td>
                <td>{data[1]}</td>
              </tr>
            )) : <></>
          }
          </table>
        </div> : <></>
    )
  }

  const currentDate = new Date();
  const [start, onStartChange] = useState(new Date(currentDate.getTime() - (6 * 60 * 60 * 1000)));
  const [end, onEndChange] = useState(currentDate);


  return (
    // <div className={`row`}>
    <form
      id="fake-order-create-form"
      className="hc-form mt-3"
      onSubmit={handleSubmit}
    >
      <div className="row p-3 mt-5">
        <div className="col-md-6 mx-auto mt-5 p-0">
          <input
            className="form-control cc hc-input-not-disable mt-3"
            name="symbol"
            placeholder="Symbol"
            onChange={handleChange}
            autoComplete="off"
            value={inputs.symbol}
            type="text"/>

          <select className="form-control text-center mt-3 pl-0"
                  name="interval"
                  onChange={handleIntervalChange}
          >
            <option value="d1">D1</option>
            <option value="h4">H4</option>
            <option value="h1">H1</option>
            <option value="m15">M15</option>
            <option value="m5" selected={true}>M5</option>
          </select>

          <select className="form-control text-center mt-3 pl-0"
                  name="interval"
                  onChange={handleTypeChange}
          >
            <option value="high_vol">Normal</option>
            <option value="high_vol_sma" selected={true}>Vol vs SMA</option>
          </select>

          <DateTimePicker
            name="start"
            onChange={onStartChange}
            value={start}
            minDetail="minute"
            format={"dd-MM-y HH:mm"}
            className={`mt-3`}
          />

          <DateTimePicker
            name="start"
            onChange={onEndChange}
            value={end}
            format={"dd-MM-y HH:mm"}
            className={`mt-3`}
          />

          <div className="col-md-1 text-right p-0" style={{position: "absolute", right: 0, 'cursor': 'pointer'}}>
            <span className={`text-right dimmed`} onClick={() => {
              onEndChange(new Date())
            }}>Now</span>
          </div>

          <button
            className="btn btn-block btn-primary mt-4 mx-auto"
            type="submit"
            onClick={handleSubmit}
            style={{
              fontSize: '1rem',
              paddingTop: '6px',
              paddingBottom: '6px'
            }}
          >
            Run
            {loading ? <Loading background={`rgb(225, 239, 250)`}/> : ''}
          </button>
        </div>
        <div className="col-md-6 mx-auto mt-5 pr-0">
          {dataShowing()}
        </div>
      </div>
    </form>
    // </div>
  );
}

export {EmaIntegrateVol};
