import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';

import {generalService} from "../services";

function LogoutPage() {
  const [inputs, setInputs] = useState({
    passcode: '',
  });
  const {passcode} = inputs;
  const [redirect, setRedirect] = useState(false);


  const handleChange = (e) => {
    const {name, value} = e.target;
    setInputs(inputs => ({...inputs, [name]: value}));
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (passcode) {
      console.log(passcode)
      generalService.logoutAllSession(passcode)
        .then(res => {
          setRedirect(true)
        });
    }
  }


  return (
    redirect ? <Redirect to="/"/> :
      <section>
        <div className="container p-3">
          <form role="form"
                id="login-form"
                className="hc-login-form"
                onSubmit={handleSubmit}>
            <div className="input-group">
              <input type="number"
                     className="form-control"
                     name="passcode"
                     value={passcode}
                     placeholder="What?"
                     onChange={handleChange}
              />
              <span className="input-group-btn" style={{display: 'flex', width: '23%', marginLeft: '-10px'}}>
                <button className="btn btn-default"
                        type="button"
                        onClick={handleSubmit}
                        style={{borderBottomLeftRadius: '0', borderTopLeftRadius: '0'}}
                >&gt;</button>
              </span>
            </div>
          </form>
        </div>
      </section>
  );
}

export default LogoutPage;
