import React, {useEffect, useState} from 'react';
import {dollarFormat, numberColor, percentFormat, thousandFormat} from "../../helpers";
import {generalService} from "../../services";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import fa from "react-timeago/lib/language-strings/fa";
import {Loading} from "react-loading-dot";

function TableItem(props) {
  return <tr className="traded-pair-row"
             style={{lineHeight: 1.5, fontSize: '14px'}}>
    <td>
      <Link to={`/campaign/${props.symbol}`}><strong>{props.symbol}</strong></Link>
      <br/>
      <span className={`convertedprice-859-1505 small price dimmed`}>{props.last_summary}</span>
      <br/>
    </td>

    <td className="d-block d-sm-none text-center">
      <span className={`price-859-1505 price`}>{dollarFormat(props.totalPurchases)}</span>
      <br/>
      <span className="price-859-1505 price">{dollarFormat(props.totalSells)}</span>
    </td>

    <td className="d-none d-sm-table-cell text-center">
      <span className={`price-859-1505 price`}>{dollarFormat(props.totalPurchases)}</span>
    </td>

    <td className="d-none d-sm-table-cell text-center">
      <span className="price-859-1505 price">{dollarFormat(props.totalSells)}</span>
    </td>

    <td className="d-sm-table-cell text-center">
      <span className="price-859-1505 price">{thousandFormat(props.holding)}</span>
      <br/>
      <span className="d-sm-none price-859-1505 price">{dollarFormat(props.marketValue)}</span>
    </td>

    <td className="d-none d-sm-table-cell text-center">
      <span className="price-859-1505 price">{dollarFormat(props.marketValue)}</span>
    </td>

    <td className="d-none d-sm-table-cell text-center">
      <span className={`price-859-1505 price ${numberColor(props.profit)}`}>
        {dollarFormat(props.profit)} ({percentFormat(props.profitPercent)})
      </span>
    </td>

    <td className="d-block d-sm-none text-center">
      <span className={`price-859-1505 price ${numberColor(props.profit)}`}>
        {dollarFormat(props.profit)} <br/>
        ({percentFormat(props.profitPercent)})
      </span>
    </td>
  </tr>
}

function Table(props) {
  const kline = useSelector(state => state.kline);
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    renderListItem()
  }, [kline])

  useEffect(() => {
    fetchList().then(r => console.log('🚃'))
  }, [props])

  useEffect(() => {
    fetchList().then(r => console.log('🚃'))
  }, []);

  const fetchList = async () => {
    setLoading(true)
    generalService.getCampaignsData(props.sort)
      .then(res => {
        setData(res)
        setLoading(false)
      });
  }

  const renderListItem = () => {
    const campaigns = Object.entries(data).map(([key, value]) => ({key, value}));

    return campaigns.map(item => {
      let profit = 0
      let marketValue = 0
      const index = item.key

      if (item.value.holding) {
        const realtimePrice = kline ? kline[index] ?? 0 : 0
        const netCost = item.value.total_purchases - item.value.total_sells
        marketValue = realtimePrice * item.value.holding
        profit = marketValue - netCost
      } else {
        profit = item.value.total_sells - item.value.total_purchases
      }

      const profitPercent = profit / item.value.total_purchases

      let last_summary = ''
      if (item.value.has_last_summary === true) {
        last_summary = generalService.timestampFormat(item.value.last_summary)
      }

      return <TableItem key={item.key}
                        symbol={item.key}
                        last_summary={last_summary}
                        totalPurchases={item.value.total_purchases}
                        totalSells={item.value.total_sells}
                        holding={item.value.holding ?? 0}
                        marketValue={marketValue}
                        profit={profit}
                        profitPercent={profitPercent}/>
    })
  }

  return <table className="table">
    {loading ? <Loading background={`rgb(225, 239, 250)`}/> : ''}
    <thead>
    <tr>
      <th>Symbol</th>
      <th className="d-block d-sm-none text-center">Tada</th>
      <th className="d-none d-sm-table-cell text-center">Total Purchase</th>
      <th className="d-none d-sm-table-cell text-center">Total Sell</th>
      <th className="d-sm-table-cell text-center">Holding</th>
      <th className="d-none d-sm-table-cell text-center">Market Value</th>
      <th className="d-sm-table-cell text-center">Profit</th>
    </tr>
    </thead>
    <tbody id="exchange-data">
    {renderListItem()}
    </tbody>
  </table>
}

export {Table};
