import {authHeader, debug} from '../helpers';
import {base} from "../constants";

export const generalService = {
  login,
  logoutAllSession,
  getDashboardData,
  updateDashboardData,
  getRealtimePrice,
  getBingXRealtimePrice,
  getTradeList,
  getOrderList,
  getCampaignsData,
  saveCampaign,
  saveFakeOrder,
  getFillHoldingList,
  saveFillHolding,
  deleteFillHolding,
  deleteFakeOrder,
  getCampaignDetail,
  getCampaignDetailOrphan,
  getChart,
  getNonBinancePairList,
  saveNonBinancePair,
  nonBinancePairCreate,
  nonBinancePairCheck,
  nonBinancePairDelete,
  getFiat,
  getFiatNow,
  fiatCreate,
  fiatDelete,
  getDateTime,
  getDateTime2,
  formatDateTimePicker,
  timestampFormat,
  getDate,
  /* Futures */
  imageUpload,
  imageDelete,
  profitImages,
  futuresStore,
  futures2Store,
  marketStore,
  marketNoteStore,
  marketNoteDelete,
  fetchBinanceData,
  getMarketNotes,
  getMarketImages,
  marketImageNoteEdit,
  marketImageNoteDelete,
  marketVolumeAnalysis,
  marketVolumeAnalysisHistory,
  diffChecker,
  marketEmaAnalysis,
  marketEmaHistory,
  emaGeneralNote,
  emaNoteCheck,
  getMarketData,
  getFutures2,
  futuresDelete,
  futuresNoteStore,
  futuresNoteSaveFavorite,
  futuresNoteGetFavorite,
  futuresNoteDelete,
  listFutures,
  getFutures,
  alertSwitching,
  alertSwitchingStatus,
  trendSwitchingStatus,
  trendSwitching,
  alertPricingGet,
  alertHistories,
  alertOldSignalHistories,
  saveCheckPoint,
  alertPricingStore,
  alertPricingDelete,
  dcaConfigStore,
  dcaNoteStore,
  dcaConfigGet,
  dcaResultList,
  rsiAnalysis,
  rsiAnalysisObOs,
  rsiAnalysisEmaHit,
  rsiLTGet,
  rsiLTSeen,
  rsiLTSeenGet,
  GoshHelpPost,
  GoshHelpList,
  GoshHelpDelete,
};

function getDashboardData() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/data/home${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function getCampaignsData(sort) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/data/all?sort=${sort}&XDEBUG_SESSION_START=PHPSTORM`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function getCampaignDetail(symbol) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/campaign/${symbol}${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function getCampaignDetailOrphan(symbol, sortType) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/campaign/orphan/${symbol}/${sortType}${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function getChart(chartType, chartTime) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/chart/${chartType}/${chartTime}${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function updateDashboardData() {
  const requestOptions = {
    method: 'POST',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/updateData${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function getTradeList(query) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  // const query = symbol ? '/' + symbol : ''
  // console.log(`${process.env.REACT_APP_API_ENDPOINT}/trades${query}`);
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/trades${query}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getFillHoldingList(query) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/fill-holding/get/${query}${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getOrderList(symbol) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const query = symbol ? '/' + symbol : ''
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/orders${query}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function saveCampaign(symbol, endId) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({'symbol': symbol, 'end_id': endId})
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/campaign/save${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function saveFakeOrder(symbol, price, qty, origQty) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'symbol': symbol,
      'price': price,
      'qty': qty,
      'origQty': origQty
    })
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/fake-order/create${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function saveFillHolding(symbol, newHolding) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'symbol': symbol,
      'new_holding': newHolding
    })
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/fill-holding/create${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function nonBinancePairCreate(inputs) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(inputs)
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/non-binance/create-trade${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function nonBinancePairCheck($symbol) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  // return fetch(`${process.env.REACT_APP_API_ENDPOINT}/non-binance/pair-check/${$symbol}${debug}`, requestOptions)
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/non-binance/pair-check/${$symbol}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function deleteFillHolding(id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({'id': id})
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/fill-holding/delete${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function deleteFakeOrder(id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({'id': id})
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/fake-order/delete${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function getRealtimePrice(symbolPair) {
  const requestOptions = {
    method: 'GET',
  };

  if (typeof symbolPair === 'object') {
    if (typeof symbolPair['price'] != "undefined") {
      return new Promise((resolve) => {
        resolve({'symbol': symbolPair['pair'], 'price': symbolPair['price']})
      });
    }
  } else {
    return fetch(`https://api.binance.com/api/v3/ticker/price?symbol=${symbolPair}`, requestOptions)
      .then(handleResponse)
      .then(res => {
        return res;
      })
      .catch(res => {
        return res;
      })
  }
}

async function getBingXRealtimePrice(symbolPair) {
  const requestOptions = {
    method: 'GET',
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/bingX-price/${symbolPair}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.price;
    });
}

function getNonBinancePairList() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  // const query = symbol ? '/' + symbol : ''
  // console.log(`${process.env.REACT_APP_API_ENDPOINT}/trades${query}`);
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/non-binance/pair-list`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function saveNonBinancePair(symbol, coingeckoId) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      'symbol': symbol,
      'coingecko_id': coingeckoId
    })
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/non-binance/pair-create${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function nonBinancePairDelete(id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({'id': id})
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/non-binance/delete-trade${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function getFiatNow() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/fiat/now${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function getFiat(query) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/fiat${query}&XDEBUG_SESSION_START=PHPSTORM`, requestOptions)
    .then(handleResponse)
    .then(res => res );
}


function fiatCreate(inputs) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(inputs)
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/fiat${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function fiatDelete(id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({'id': id})
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/fiat/delete${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function login(passcode) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({passcode})
  };

  return fetch(`${base.API_ENDPOINT}/login?XDEBUG_SESSION_START=PHPSTORM`, requestOptions)
    .then(handleResponse)
    .then(res => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('HC_TOKEN', res.token);
      return res.token;
    });
}

function logoutAllSession(passcode) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({passcode})
  };

  return fetch(`${base.API_ENDPOINT}/logout/all?XDEBUG_SESSION_START=PHPSTORM`, requestOptions)
    .then(handleResponse)
    .then(res => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      // localStorage.setItem('HC_TOKEN', res.token);
      console.log(res)
      return res.token;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('HC_TOKEN');



  // eslint-disable-next-line no-restricted-globals
  location.reload()
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();

        // eslint-disable-next-line no-restricted-globals
        // location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function getDateTime(custom = null) {
  let date = new Date();
  if (custom) {
    date = new Date(new Date().setDate(custom > 0 ? date.getDate() + custom :  date.getDate() - Math.abs(custom)));
  }

  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hour = date.getHours();
  let minute = date.getMinutes();
  let second = date.getSeconds();
  if (month.toString().length === 1) {
    month = '0' + month;
  }
  if (day.toString().length === 1) {
    day = '0' + day;
  }
  if (hour.toString().length === 1) {
    hour = '0' + hour;
  }
  if (minute.toString().length === 1) {
    minute = '0' + minute;
  }
  if (second.toString().length === 1) {
    second = '0' + second;
  }
  return year + '/' + month + '/' + day + ' ' + hour + ':' + minute;
}

function getDateTime2(hoursToSubtract = null) {
  const date = new Date();
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const yyyy = date.getFullYear();

  let hours = date.getHours();
  hours -= hoursToSubtract;
  let minutes = date.getMinutes();
  minutes = 5 * Math.round(minutes / 5);

  const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;

  return `${dd}/${mm}/${yyyy} ${formattedTime}`;
}

function formatDateTimePicker(dateStr) {
  const dateObj = new Date(dateStr);
  const day = dateObj.getDate().toString().padStart(2, '0');
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
  const year = dateObj.getFullYear().toString();
  const hours = dateObj.getHours().toString().padStart(2, '0');
  const minutes = dateObj.getMinutes().toString().padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

function timestampFormat(timestamp) {
  const dateObj = new Date(timestamp * 1000);
  const year = dateObj.getFullYear();
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
  const day = dateObj.getDate().toString().padStart(2, '0');
  const hours = dateObj.getHours().toString().padStart(2, '0');
  const minutes = dateObj.getMinutes().toString().padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}


function getDate(custom = null) {
  let date = new Date();
  if (custom) {
    date = new Date(new Date().setDate(custom > 0 ? date.getDate() + custom :  date.getDate() - Math.abs(custom)));
  }

  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  if (month.toString().length === 1) {
    month = '0' + month;
  }

  return year + '-' + month + '-' + day;
}

function imageUpload(symbol, endId) {
  // const requestOptions = {
  //   method: 'POST',
  //   headers: authHeader(),
  //   body: JSON.stringify({'symbol': symbol, 'end_id': endId})
  // };
  //
  // return fetch(`${process.env.REACT_APP_API_ENDPOINT}/campaign/save${debug}`, requestOptions)
  //   .then(handleResponse)
  //   .then(res => {
  //     return res.data;
  //   });
}

function imageDelete(id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({'id': id})
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/futures/image/delete${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function futuresStore(inputs) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(inputs)
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/futures/store${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function futures2Store(inputs) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({'data': inputs})
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/futures-history/store${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function marketStore(inputs) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({'data': inputs})
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/market/store${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.result;
    });
}

function marketNoteStore(inputs) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(inputs)
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/market/notes${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.result;
    });
}

function marketImageNoteEdit(inputs) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(inputs)
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/market/notes-image/text${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.result;
    });
}

function marketImageNoteDelete(id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/market/notes-image/delete/${id}${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.result;
    });
}

function marketNoteDelete(id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({'id': id})
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/market/notes/delete${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function futuresDelete(id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({'id': id})
  };

  console.log(requestOptions)

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/futures/delete${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function futuresNoteStore(inputs) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(inputs)
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/futures/note/store${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function futuresNoteSaveFavorite(inputs) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(inputs)
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/futures/note/save-favorite${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function saveCheckPoint(inputs) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(inputs)
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/alert/histories${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function futuresNoteGetFavorite() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    // body: JSON.stringify(inputs)
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/futures/note/favorites${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function futuresNoteDelete(id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({'id': id})
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/futures/note/delete${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function listFutures(query) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/futures/list${query}&XDEBUG_SESSION_START=PHPSTORM`, requestOptions)
    .then(handleResponse)
    .then(res => res );
}

function getFutures(query) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/futures/get/${query}?XDEBUG_SESSION_START=PHPSTORM`, requestOptions)
    .then(handleResponse)
    .then(res => res );
}

function alertSwitching(action, type) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/alert/switching/${action}/${type}?XDEBUG_SESSION_START=PHPSTORM`, requestOptions)
    .then(handleResponse)
    .then(res => res );
}

function alertSwitchingStatus(type) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/alert/switching/status/${type}?XDEBUG_SESSION_START=PHPSTORM`, requestOptions)
    .then(handleResponse)
    .then(res => res );
}

function alertPricingGet(type = '', sort = 'date') {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/alert/price/${type}/${sort}?XDEBUG_SESSION_START=PHPSTORM`, requestOptions)
    .then(handleResponse)
    .then(res => res.data );
}

function trendSwitching(action) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/trend/switching/${action}?XDEBUG_SESSION_START=PHPSTORM`, requestOptions)
    .then(handleResponse)
    .then(res => res );
}

function trendSwitchingStatus() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/trend/switching?XDEBUG_SESSION_START=PHPSTORM`, requestOptions)
    .then(handleResponse)
    .then(res => res );
}

function alertHistories(query) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/alert/histories/${query}?XDEBUG_SESSION_START=PHPSTORM`, requestOptions)
    .then(handleResponse)
    .then(res => res.data );
}

function alertOldSignalHistories() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/alert/old-signal?XDEBUG_SESSION_START=PHPSTORM`, requestOptions)
    .then(handleResponse)
    .then(res => res.data );
}

function alertPricingStore(inputs) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(inputs)
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/alert/price${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function dcaConfigStore(inputs) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(inputs)
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/dca/config${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function dcaNoteStore(inputs) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(inputs)
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/dca/note${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function dcaConfigGet(type, sort, paginate) {
  const requestOptions = {
    method: 'get',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/dca/config/${type}/${sort}/${paginate}?XDEBUG_SESSION_START=PHPSTORM`, requestOptions)
    .then(handleResponse)
    .then(res => res.data );
}

function dcaResultList(query) {
  const requestOptions = {
    method: 'get',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/dca/list${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => res );
}

function GoshHelpPost(inputs) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(inputs)
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/gosh-help${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function GoshHelpList(query) {
  const requestOptions = {
    method: 'get',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/gosh-help${query}`, requestOptions)
    .then(handleResponse)
    .then(res => res );
}

function GoshHelpDelete(inputs) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(inputs)
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/gosh-help/delete${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.result;
    });
}

function rsiAnalysis(inputs) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(inputs)
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/rsi-analysis${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function rsiAnalysisObOs(inputs) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(inputs)
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/rsi-analysis/ob-os${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function rsiAnalysisEmaHit(inputs) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(inputs)
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/ema-hit${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function rsiLTGet(query) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/rsi-lt${query}&XDEBUG_SESSION_START=PHPSTORM`, requestOptions)
    .then(handleResponse)
    .then(res => res );
}

function rsiLTSeen() {
  const requestOptions = {
    method: 'POST',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/rsi-lt/seen`, requestOptions)
    .then(handleResponse)
    .then(res => res );
}

function rsiLTSeenGet() {
  const requestOptions = {
    method: 'get',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/rsi-lt/seen`, requestOptions)
    .then(handleResponse)
    .then(res => res );
}

function alertPricingDelete(inputs) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(inputs)
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/alert/price/delete${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.result;
    });
}

function getFutures2(query) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/futures-history${query}&XDEBUG_SESSION_START=PHPSTORM`, requestOptions)
    .then(handleResponse)
    .then(res => res );
}

function getMarketData(query) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/market/view/${query}?XDEBUG_SESSION_START=PHPSTORM`, requestOptions)
    .then(handleResponse)
    .then(res => res );
}

function fetchBinanceData() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/market/binance/store?XDEBUG_SESSION_START=PHPSTORM`, requestOptions)
    .then(handleResponse)
    .then(res => res);
}

function getMarketNotes(symbol) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/market/notes/${symbol}?XDEBUG_SESSION_START=PHPSTORM`, requestOptions)
    .then(handleResponse)
    .then(res => res );
}

function getMarketImages(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/market/notes-image/${id}?XDEBUG_SESSION_START=PHPSTORM`, requestOptions)
    .then(handleResponse)
    .then(res => res );
}

function marketVolumeAnalysis(inputs) {
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(inputs)
    };

    return fetch(`${process.env.REACT_APP_API_ENDPOINT}/market/volume-range-analysis${debug}`, requestOptions)
      .then(handleResponse)
      .then(res => {
        return res;
      });
}

function marketVolumeAnalysisHistory(deletedId = '') {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  const typeUrl = deletedId !== '' ? '/' + deletedId : '';

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/market/volume-range-analysis/history${typeUrl}${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function diffChecker(inputs) {
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(inputs)
    };

    return fetch(`${process.env.REACT_APP_API_ENDPOINT}/diff-checker${debug}`, requestOptions)
      .then(handleResponse)
      .then(res => {
        return res.data;
      });
}

function marketEmaAnalysis(inputs) {
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(inputs)
    };

    return fetch(`${process.env.REACT_APP_API_ENDPOINT}/ema/analysis${debug}`, requestOptions)
      .then(handleResponse)
      .then(res => {
        return res;
      });
}

function marketEmaHistory() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/ema/history?XDEBUG_SESSION_START=PHPSTORM`, requestOptions)
    .then(handleResponse)
    .then(res => res.data);
}

function emaGeneralNote(inputs) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(inputs)
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/ema/general-note${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function emaNoteCheck(inputs) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(inputs)
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/ema/note-check${debug}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res.data;
    });
}

function profitImages() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/futures/profit-images?XDEBUG_SESSION_START=PHPSTORM`, requestOptions)
    .then(handleResponse)
    .then(res => res.data );
}
