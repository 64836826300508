import React, {useState, useEffect} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {generalService} from "../../services";
import {Loading} from "react-loading-dot";
import {Table} from "react-bootstrap";
import {DateTimePicker} from "react-datetime-picker";

function MarketVolumeAnalysis({isModal = false}) {
  const initInput = {
    symbol: 'btc',
    interval: 'm5'
  }
  const [inputs, setInputs] = useState(initInput);
  const [data, setData] = useState({});
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true)
    let postInput = inputs
    postInput = ({...postInput
      , start: generalService.formatDateTimePicker(start)
      , end: generalService.formatDateTimePicker(end)
    })
    generalService.marketVolumeAnalysis(postInput).then(r => {
      setData(r.data)
      console.log(r)
      setHistory(r.history)
      setLoading(false)
    })
  }

  useEffect(() => {
    document.title = 'Single Volume Analysis 🚀🚀'
    console.log(isModal)
  }, [])

  useEffect(() => {
    generalService.marketVolumeAnalysisHistory().then(r => {
      setHistory(r)
    })
  }, [data])


  const handleChange = async (e) => {
    const {name, value} = e.target;
    setInputs(inputs => ({...inputs, [name]: value}));
  }

  const handleIntervalChange = async (e) => {
    setInputs({...inputs, interval: e.target.value});
  }

  const deleteItem = (id) => {
    generalService.marketVolumeAnalysisHistory(id).then(r => {
      setHistory(r)
    })
  }

  const dataTable = () => {
    return (
      <Table striped bordered hover className={`mt-3`} style={{borderColor: "#282E3B"}}>
        <thead>
        <tr>
          <th>Key</th>
          <th>Value</th>
        </tr>
        </thead>
        <tbody>
        {data !== undefined ? Object.entries(data).map(([key, value]) => (
          <tr key={key}>
            <td>{key}</td>
            <td>
              {typeof value === "object" ? (
                <pre>{JSON.stringify(value, null, 2)}</pre>
              ) : (
                value
              )}
            </td>
          </tr>
        )) : <></>
        }
        </tbody>
      </Table>
    );
  }

  const historyTable = () => {
    return (
      <Table striped bordered hover className={`mt-3`} style={{borderColor: "#282E3B"}}>
        <thead>
        <tr>
          <th colSpan={2}>History</th>
        </tr>
        </thead>
        <tbody style={{border: "0px"}}>
        {history !== undefined ? Object.entries(history).map(([key, value]) => (
          <>
            <tr key={key}>
              <td
                colSpan={2}
                style={{
                  padding: "0.25rem",
                }}
              >
                <p className="small mb-1">
                  ◉&nbsp;
                  {value.symbol.toUpperCase() + " [" + value.interval.toUpperCase() + "] "}
                  <i className={`dimmed mr-2`} style={{float: "right", fontSize: "12px"}}>
                    {value.created_at_formatted}
                    &nbsp;
                    <span className="price-859-1505 price dimmed" style={{cursor: "pointer"}} onClick={() => deleteItem(value.id)}>x</span>
                  </i>
                  &nbsp;
                  <br/>
                  <p className={`price small`} style={{marginTop: "0.5rem", marginBottom: "0.25rem"}}>
                    &nbsp;&nbsp;&nbsp;&nbsp;{"[" + value.start + " -> " + value.end + "]"}
                  </p>
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan={2} width={`100%`} style={{padding: 0}}>
                <table className="price small text-center" style={{width: "100%"}}>
                  <tr>
                    <td width={`20%`}>All</td>
                    <td width={`40%`}>HighVol</td>
                    <td width={`50%`}>HighVol vs SMA</td>
                  </tr>
                  <tr>
                    <td>{value.result_all}</td>
                    <td>{value.result_high_vol}</td>
                    <td>{value.result_high_vol_sma}</td>
                  </tr>
                </table>
              </td>
            </tr>
            <br/>
          </>
        )) : <></>
        }
        </tbody>
      </Table>
    );
  }

  const currentDate = new Date();
  const [start, onStartChange] = useState(new Date(currentDate.getTime() - (6 * 60 * 60 * 1000)));
  const [end, onEndChange] = useState(currentDate);


  return <section>
    {loading ? <Loading background={`rgb(225, 239, 250)`}/> : ''}

    {
      isModal === false ? <div className="col-md-6 mx-auto mt-5">
        <div className="text-center">
          <h3>VOLUME ANALYSIS</h3>
          <Link to={`/`} className="monospace-font dimmed">HOME</Link>
        </div>
      </div> : <></>
    }

    <div className="d-flex-inline justify-content-center">
      <div className={`${isModal === false ? 'container' : 'container-fluid'} p-3`}>
        <div className={`row`}>
        <div className={`col-md-6 mx-auto ${isModal === false ? 'mt-5' : ''}`}>
          <form
            id="fake-order-create-form"
            className="hc-form mt-3"
            onSubmit={handleSubmit}>

            <input
              className="form-control cc hc-input-not-disable mt-3"
              name="symbol"
              placeholder="Symbol"
              onChange={handleChange}
              autoComplete="off"
              value={inputs.symbol}
              type="text"/>

            <select className="form-control text-center mt-3 pr-0"
                    name="interval"
                    onChange={handleIntervalChange}
            >
              <option value="d1">d1</option>
              <option value="h4">h4</option>
              <option value="h1">h1</option>
              <option value="m15">m15</option>
              <option value="m5" selected={true}>m5</option>
            </select>

            <DateTimePicker
              name="start"
              onChange={onStartChange}
              value={start}
              minDetail="minute"
              format={"dd-MM-y HH:mm"}
              className={`mt-3`}
            />

            <DateTimePicker
              name="start"
              onChange={onEndChange}
              value={end}
              format={"dd-MM-y HH:mm"}
              className={`mt-3`}
            />

            <div className="col-md-1 text-right pr-5" style={{position: "absolute", right: 0, 'cursor': 'pointer'}}>
              <span
                className={`text-right dimmed`}
                onClick={() => {
                  onEndChange(new Date())
                }}
              >
                Now
              </span>
            </div>

            <button className="btn btn-block btn-primary mt-4"
                    type="submit"
                    onClick={handleSubmit}
                    style={{
                      fontSize: '1rem',
                      paddingTop: '6px',
                      paddingBottom: '6px'
                    }}
            >
              Run
            </button>
          </form>
          {historyTable()}
        </div>
          <div className={`col-md-6 mx-auto ${isModal === false ? 'mt-5' : ''}`}>
          {dataTable()}
        </div>
        </div>
      </div>
    </div>
  </section>
}

export default MarketVolumeAnalysis;
