import React, {useEffect, useState} from 'react';
import {Header, Table} from "../TradeListPage";
import {useParams} from "react-router";

function TradeListPage() {
  const {symbol} = useParams();
  const [sortBy, setSortBy] = useState([])

  useEffect(() => {
    document.title = symbol === 'all'
      ? 'Trade list 🚀🚀'
      : symbol.replace('USDT', '') + ' | Trade list 🚀🚀';
  }, [])

  return (
    <section>
      <div className="container p-3">
        <Header title={`HISTORY TRADE ${symbol !== 'all' ? '- ' + symbol : ''}`}
                symbol={symbol}
                sort={sortBy => setSortBy(sortBy)}
        />
        <Table symbol={symbol} sortBy={sortBy}/>
      </div>
    </section>
  );
}

export {TradeListPage};
