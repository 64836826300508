import React, {useEffect, useState} from 'react';
import {Table, Header} from "../FiatPage";
import {useParams} from "react-router";
import {generalService} from "../../services";

function FiatPage() {
  const {symbol} = useParams();
  const [fiatNow, setFiatNow] = useState(0)

  useEffect(() => {
    document.title = 'Cash Flow List 💰💰'
    generalService.getFiatNow()
      .then(res => {
        setFiatNow(res)
      });
  }, [])

  return (
    <section>
      <div className="container p-3">
        <Header fiatNow={fiatNow}/>
        <Table symbol={symbol}/>
      </div>
    </section>
  );
}

export {FiatPage};
