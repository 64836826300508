import { push } from 'connected-react-router'
import {allConstants} from '../../constants';
import {generalService} from '../../services';
import {alertActions} from '../';

export const userActions = {
  login,
  logout,
};

function login(passcode) {
  return dispatch => {
    dispatch(request({passcode}));

    generalService.login(passcode)
      .then(
        token => {
          dispatch(success(token));
          dispatch(push('/'));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(passcode) {
    return {type: allConstants.LOGIN_REQUEST, passcode}
  }

  function success(token) {
    return {type: allConstants.LOGIN_SUCCESS, token}
  }

  function failure(error) {
    return {type: allConstants.LOGIN_FAILURE, error}
  }
}

function logout() {
  generalService.logout();
  return {type: allConstants.LOGOUT};
}
