import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {dollarFormat, thousandFormat} from "../../helpers";
import {allConstants} from "../../constants";
function Header(props) {
  const dispatch = useDispatch();
  const resetState = useSelector(state => state.reset);

  return <div>
    <div className="row ml-3 mr-3 mb-4">
      <div className="col-12 mx-auto">
        <div className="row">
          <div className="col-5">
            <div className="row">
              <h1 className="d-none d-sm-block p-2">{props.title}</h1>
            </div>
            <div className="row">
              <a href="javascript:void(0)"
                 className={`d-none d-sm-inline-block button btn ${resetState ? 'btn-primary' : ''}`}
                 onClick={() => dispatch({type: allConstants.TOGGLE_RESET_STAND_BY})}
              >Save Campaign</a>
              <Link to={`/fill-holding/create/${props.symbol}`}
                 className={`d-none d-sm-inline-block button btn ml-1`}
              >Fill Holding</Link>
            </div>
          </div>
          <div className="col-7 text-right">
            <div className="d-none d-sm-block">
              <Link to={`/`} className="monospace-font dimmed small">HOME</Link>
              <span> | </span>
              <Link to={`/campaigns`} className="monospace-font dimmed small">CAMPAIGN LIST</Link>
              <span> | </span>
              <Link to={`/orders/${props.symbol}`} className="monospace-font dimmed small">ORDER LIST</Link>
              <br/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="row d-block d-sm-none p-3 hc-shadow">
      <h3 className="mt-2">
        {props.title}
      </h3>

      <div className="row">
        <a href="javascript:void(0)"
           className={`d-sm-inline-block button btn ${resetState ? 'btn-primary' : ''}`}
           onClick={() => dispatch({type: allConstants.TOGGLE_RESET_STAND_BY})}
        >Save Campaign</a>
        <Link to={`/fill-holding/create/${props.symbol}`}
              className={`d-sm-inline-block button btn ml-1`}
        >Fill Holding</Link>
      </div>
      <br/>

      <Link to={`/`} className="monospace-font dimmed">HOME</Link>
      <span> | </span>
      <Link to={`/orders/${props.symbol}`} className="monospace-font dimmed">ORDER LIST</Link>
      <span> | </span>
      <Link to={`/trades/${props.symbol}`} className="monospace-font dimmed">TRADE LIST</Link>
    </div>
  </div>
}

export {Header};