import {allConstants} from '../../constants';

const initialState = {
  "all_net_cost": 0,
  "borrowing": 0,
  "usdt": 0,
  "busd": 0,
  "all_net_cost_include_stable_coins": 0,
  'symbol_pairs': [],
  "summaries": {},
}

export default function general(state = initialState, action) {
  switch (action.type) {
    case allConstants.GET_DASHBOARD_SUCCESS:
      return action.data
    case allConstants.GET_DASHBOARD_FAILURE:
      return {
        error: action.error
      };
    // case allConstants.DELETE_FAILURE:
    //     // remove 'deleting:true' property and add 'deleteError:[error]' property to user
    //     return {
    //         ...state,
    //         items: state.items.map(user => {
    //             if (user.id === action.id) {
    //                 // make copy of user without 'deleting:true' property
    //                 const { deleting, ...userCopy } = user;
    //                 // return copy of user with 'deleteError:[error]' property
    //                 return { ...userCopy, deleteError: action.error };
    //             }
    //
    //             return user;
    //         })
    //     };
    default:
      return state
  }
}
