import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {generalService} from "../../../services";
import {alertActions} from "../../../redux";
import {useDispatch} from "react-redux";
import {MarketNoteDetail} from "./MarketNoteDetail";


const MarketNoteModal = ({show, setShow, modalData})  => {
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({});
  const [idOnDetail, setIdOnDetail] = useState(null);
  const {id, priority, note} = inputs;

  useEffect(() => {
    document.title = modalData.symbol + ' | Market Data 🚀🚀'
    inputByModalData().then()
  }, [modalData])

  const inputByModalData = async () => {
    await setInputs({...inputs, priority: modalData.priority})
  }

  const toEdit = async (id) => {
    let note = modalData.notes.find(x => x.id === id);
    let toInput = {...inputs, id: note.id}
    toInput = {...toInput, note: note.note}
    await setInputs(toInput)
  }

  const toDelete = async (id) => {
    generalService.marketNoteDelete(id).then(r => {
      setShow(false)
      dispatch(alertActions.success('Deleted!'));
      setInputs({id: null, note: '', priority: null});
    })
  }

  const toOpenDetail = async (id) => {
    if (id !== idOnDetail) {
      setIdOnDetail(id)
    } else {
      setIdOnDetail(null)
    }
  }

  const noteList = () => {
    let res = '...'
    if (Array.isArray(modalData.notes) && modalData.notes.length > 0) {
      res = modalData.notes.map(item => {
        return <table id="paths" className="table table-hover" style={{fontFamily: "proxima-nova, sans-serif"}}>
          <thead>
          <tr>
            <th onClick={() => toEdit(item.id, item.created_at)} style={{position: "relative"}}>
              {item.created_at}
              <a
                href="javascript:void(0)"
                className="hc-orphan-ordering-remove tab-note-head"
                aria-disabled="true"
                onClick={() => toDelete(item.id)}
              >
                x
              </a>
            </th>

          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <div className="comp-top"
                   style={{padding: 0}}>
                <p style={{whiteSpace: 'pre-wrap', fontSize: '14px'}}>{item.note}</p>
                <p>
                  <a
                    href="javascript:void(0)"
                    aria-disabled="true"
                    onClick={() => toOpenDetail(item.id)}
                  >
                    {idOnDetail !== item.id ? '...' : '-'}
                  </a>
                </p>
                {
                  idOnDetail === item.id && <MarketNoteDetail marketNodeId={item.id}/>
                }
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      })
    }

    return res
  }

  const handleChange = async (e) => {
    const {name, value} = e.target;
    await setInputs({...inputs, [name]: value});
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      id: id,
      symbol_id: modalData.symbol_id,
      symbol: modalData.symbol,
      note: note,
      priority: priority
    }
    generalService.marketNoteStore(data).then(r => {
      setShow(false)
      dispatch(alertActions.success('Saved!'));
      setInputs({id: null, note: '', priority: null});
    })
  }

  const hideModalHandle = async () => {
    document.title = 'Market Data 🚀🚀'
    setShow(false)
    setInputs({id: null, note: '', priority: null});
    modalData.notes = []
  }

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={() => hideModalHandle()}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {modalData.symbol}
            &nbsp;&nbsp;
            {
              modalData.priority > 0 ?
                Array.from(Array(modalData.priority).keys()).map(item => {
                  return <span style={{fontSize: '10px'}}>⭐️</span>
                })
                : <></>
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form role="form"
                id=""
                className=""
                onSubmit={handleSubmit}>
            <div>
              <select className="coin-select bs-select form-control text-center mt-3 pr-0"
                      name="priority"
                      onChange={handleChange}
                      value={priority}
              >
                <option value={null}>None</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
              </select>
              <textarea
                name="note"
                rows="10"
                className="form-control mt-3"
                onChange={handleChange}
                value={note}
                placeholder="Lượm tiền hay sao"
              />
              <div className="row d-flex justify-content-center">
                <div className="col-lg-6 col-md-4">
                  <button
                    className="btn btn-block btn-primary mt-3"
                    type="button"
                    style={{fontSize: '1rem', paddingTop: '6px', paddingBottom: '6px'}}
                    onClick={handleSubmit}
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          </form>

          <br/>
          <br/>
          <br/>
          {noteList()}
        </Modal.Body>
      </Modal>
    </>
  );
}

export {MarketNoteModal};
