import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {generalService} from "../../services";
import {Loading} from "react-loading-dot";
import {EmaSelections} from "./components/EmaSelections";
import {Table} from "react-bootstrap";
import {EmaIntegrateVol} from "./components/EmaIntegrateVol";
import {EmaHistoryItem} from "./components/EmaHistoryItem";
import {AlertPricingModal} from "./components/AlertPricingModal";
import {currentDatetime} from "../../helpers";
import {alertActions} from "../../redux";
import {useDispatch} from "react-redux";
import {AlertSwitching} from "./AlertSwitching";
import {RsiModal} from "./components/RsiModal";
import {VolModal} from "./components/VolModal";
import {LTModal} from "./components/LTModal";
import {GoshHelpModal} from "./components/GoshHelpModal";
import {TrendSwitching} from "./TrendSwitching";

function MarketEmaAnalysis() {
  const initInput = {
    symbols: '',
    symbols_action: 'in',
    no_cache: false,
    order: '',
    interval: 'h1',
    sorts: [],
  }
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState(initInput);
  const [data, setData] = useState('');
  const [dataHistory, setDataHistory] = useState([]);
  const [generalNote, setGeneralNote] = useState('');
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false);
  const [showRsiModal, setShowRsiModal] = useState(false);
  const [showVolModal, setShowVolModal] = useState(false);
  const [LTIndex, setLTIndex] = useState(0);
  const [showLTModal, setShowLTModal] = useState(false);
  const [showGoshHelpModal, setShowGoshHelpModal] = useState(false);

  const handleSelectionsChanged = (newSelections) => {
    setInputs(inputs => ({...inputs, sorts: newSelections}));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setData('')
    setLoading(true)
    generalService.marketEmaAnalysis(inputs).then(r => {
      setTimeout(() => {
        setData(r.data)
        setDataHistory(r.data_history)
        setLoading(false)
        setInputs(inputs => ({...inputs, no_cache: false}));
      }, 500);
    })
  }

  useEffect(() => {
    document.title = 'Analysis Dashboard 🚀🚀'
    generalService.marketEmaHistory().then(r => {
      setDataHistory(r.history)
      setGeneralNote(r.note)
      setLTIndex(r.rsi_long_term ?? 0)
    })

    const handleKeyDown = (event) => {
      if (event.shiftKey && event.keyCode === 49) {
        // SHIFT + 1 pressed
        shortcutFunction1();
      } else if (event.shiftKey && event.keyCode === 50) {
        // SHIFT + 2 pressed
        shortcutFunction2();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [])

  useEffect(() => {}, [data])
  useEffect(() => {}, [dataHistory])

  const shortcutFunction1 = () => {
    // SHIFT + 1 pressed
    closeAllModal()
    alertPricingModalClick();
  }

  const shortcutFunction2 = () => {
    // SHIFT + 1 pressed
    closeAllModal()
    GoshHelpModalClick();
  }

  const closeAllModal = () => {
    setShow(false)
    setShowRsiModal(false)
    setShowVolModal(false)
    setShowLTModal(false)
    setShowGoshHelpModal(false)
  }

  const onItemDelete = () => {
    generalService.marketEmaHistory().then(r => {
      setDataHistory(r.history)
    })
  }

  const handleChange = async (e) => {
    const {name, value} = e.target;
    setInputs(inputs => ({...inputs, [name]: value.toUpperCase()}));
  }

  const handleIntervalChange = async (e) => {
    setInputs({...inputs, interval: e.target.value});
  }

  const handleOnNoteChange = async (e) => {
    setGeneralNote(e.target.value)
  }

  const handleSaveGeneralNote = () => {
    setLoading(true)
    generalService.emaGeneralNote({note: generalNote}).then(r => {
      setTimeout(() => {
        setLoading(false)
      }, 500);
    })
  }

  const alertPricingModalClick = () => {
    document.title = 'Alert - Analysis Dashboard 🚀🚀'
    setShow(true)
  }

  const rsiModalClick = () => {
    document.title = 'RSI - Analysis Dashboard 🚀🚀'
    setShowRsiModal(true)
  }

  const volModalClick = () => {
    document.title = 'Vol - Analysis Dashboard 🚀🚀'
    setShowVolModal(true)
  }

  const GoshHelpModalClick = () => {
    setShowGoshHelpModal(true)
  }

  const ltModalClick = () => {
    document.title = 'LT - Analysis Dashboard 🚀🚀'
    setShowLTModal(true)
  }

  const refreshSeenIndex = () => {
    generalService.rsiLTSeenGet().then(r => {
      setLTIndex(r.data)
    })
  }

  const emaResultToSymbols = () => {
    if (data && data.includes("item(s): ")) {
      const parts = data.split("item(s): ");
      setInputs(inputs => ({...inputs, symbols: parts[1]}));
    }
  }

  const symbolsClear = () => {
    setInputs(inputs => ({...inputs, symbols: ''}));
  }

  const notInButtonHandle = () => {
    const toChange = inputs.symbols_action === 'in' ? 'not_in' : 'in'
    setInputs(inputs => ({...inputs, symbols_action: toChange}));
  }

  const cachingClosePriceButtonHandle = () => {
    const toChange = !inputs.no_cache
    setInputs(inputs => ({...inputs, no_cache: toChange}));
  }

  const tdvCopy = (isBullish) => {
    if (inputs.symbols) {
      let count = 0;
      const MAX_COUNT = 40;
      const symbols = inputs.symbols.split(", ");
      const alertFor = isBullish ? 'bull' : 'bear'

      let outputStr = "//[" + alertFor.toUpperCase() + "] " + currentDatetime() + "\n"
      symbols.forEach(symbol => {
        if (symbol.endsWith('*')) {
          if (count < MAX_COUNT) {
            symbol = symbol.replace('*', '')
            outputStr += "fireAlert('" + symbol + "', '15', '" + alertFor + "')\n"
            count++;
          }
        }
      });

      navigator.clipboard.writeText(outputStr)
        .then(() => {
          dispatch(alertActions.success('Alerts copied to clipboard!'));
        })
    }
  }

  return <section className={`ema-analysis`}>
    <div className="fluid-container pl-5 pr-5">
      <div className={`row`}>
        <div className="col-md-7 mt-2 p-4">
          <div className="text-center pt-4">
            <h3>
              ANALYSIS SYSTEM</h3>
            <Link to={`/`} className="monospace-font dimmed">Home</Link>&nbsp;|&nbsp;
            <Link to={`/market/1`} className="monospace-font dimmed">Market</Link>&nbsp;|&nbsp;
            <Link to={`/diff-checker`} className="monospace-font dimmed">Diff Checker</Link>&nbsp;|&nbsp;
            <span
              className={`monospace-font dimmed`}
              style={{color: "#2E7AD0",cursor: "pointer"}}
              onClick={alertPricingModalClick}
            >
              Alert
            </span>
            <br/>
            <span
              className={`monospace-font dimmed`}
              style={{color: "#2E7AD0",cursor: "pointer"}}
              onClick={rsiModalClick}
            >
              RSI
            </span>&nbsp;|&nbsp;
            <span
              className={`monospace-font dimmed`}
              style={{color: "#2E7AD0",cursor: "pointer"}}
              onClick={volModalClick}
            >
              Vol
            </span>&nbsp;|&nbsp;
            <span
              className={`monospace-font dimmed`}
              style={{color: "#2E7AD0",cursor: "pointer"}}
              onClick={ltModalClick}
            >
              LT({LTIndex})
            </span>&nbsp;|&nbsp;
            <span
              className={`monospace-font dimmed`}
              style={{color: "#2E7AD0",cursor: "pointer"}}
              onClick={GoshHelpModalClick}
            >
              Gosh Help
            </span>
          </div>
          <div className="text-center p-1" style={{'cursor': 'pointer', height: "1rem"}}>
            <AlertSwitching type={`bull`}/>
            &nbsp;&nbsp;
            <AlertSwitching type={`bear`}/>
            &nbsp;&nbsp;
            <AlertSwitching type={`btc`}/>
            &nbsp;&nbsp;
            <TrendSwitching/>
          </div>
        </div>
        <div className="col-md-5 mt-2 p-4">
          <textarea
          name="position_description"
          className="form-control mt-3 small"
          rows={5}
          style={{
            backgroundColor: "#2220",
            border: "1px solid #626b7f38",
            color: "#626B7F"
          }}
          onChange={handleOnNoteChange}
          value={generalNote}
          />
          <span
            className={`text-right pr-4`}
            style={{
              color: "#2E7AD0",
              cursor: "pointer",
              position: "absolute",
              right: "0"
            }}
            onClick={handleSaveGeneralNote}
          >
            Save
          </span>
        </div>
      </div>

    </div>
    <div className="d-flex-inline justify-content-center" style={{zIndex: 1}}>
      <div className="fluid-container pl-5 pr-5">
        <div className={`row`}>
          <div className="col-md-7 mt-2 p-4">
            <form
              style={{minHeight: '32vh'}}
              className="hc-form"
              onSubmit={handleSubmit}
            >
              <div className="col-md-2 p-0">
                <select className="form-control text-center pr-0 mb-3"
                        name="interval"
                        onChange={handleIntervalChange}
                >
                  <option value="d1">d1</option>
                  <option value="h4">h4</option>
                  <option value="h1" selected={true}>h1</option>
                  <option value="m15">m15</option>
                </select>
              </div>

              <EmaSelections
                number_of_select={4}
                select_options={[34, 89, 200, 610, 'current_price']}
                onSelectionsChanged={handleSelectionsChanged}
              />

              <textarea
                className="form-control mt-3 text-left"
                name="symbols"
                rows="3"
                value={inputs.symbols}
                onChange={handleChange}
              />
              <span className={`text-left dimmed mt-5`}>
                Percent of change between Sort1 and Sort2 DESC.
                <span onClick={symbolsClear} style={{cursor: "pointer", fontWeight: '800'}}>&nbsp;[Clear</span>
                &nbsp;|&nbsp;
                <span
                  onClick={notInButtonHandle}
                  style={{
                    cursor: "pointer",
                    fontWeight: '800',
                    textDecoration: inputs.symbols_action === 'not_in' ? "underline" : "none",
                    color: inputs.symbols_action === 'not_in' ? "#2E7AD0" : "#999999"
                  }}
                >
                  Not In
                </span>
                &nbsp;|&nbsp;
                <span
                  onClick={cachingClosePriceButtonHandle}
                  style={{
                    cursor: "pointer",
                    fontWeight: '800',
                    textDecoration: inputs.no_cache === true ? "underline" : "none",
                    color: inputs.no_cache === true ? "#2E7AD0" : "#999999"
                  }}
                >
                  No Caching Close Price
                </span>
                <span>]</span>
              </span>
              <span
                className={`text-right dimmed px-4`}
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  right: 0
                }}
              >
                <span>📋:</span>&nbsp;&nbsp;
                <span onClick={() => tdvCopy(true)}>🐂</span>&nbsp;&nbsp;&nbsp;
                <span onClick={() => tdvCopy(false)}>🐻</span>
              </span>

              <button className="btn btn-block btn-primary mt-3"
                      type="submit"
                      onClick={handleSubmit}
                      style={{
                        fontSize: '1rem',
                        paddingTop: '6px',
                        paddingBottom: '6px'
                      }}
              >
                Run
                {loading ? <Loading background={`rgb(225, 239, 250)`}/> : ''}
              </button>
            </form>
            <p className="mt-4" style={{textAlign: "justify"}}>
              {data}
              {
                data !== '' ? <span onClick={emaResultToSymbols} style={{cursor: "pointer"}}>&nbsp;📋</span> : <></>
              }
            </p>

            <EmaIntegrateVol/>
          </div>
          <div className="col-md-5 mx-auto mt-2 p-4">
            <Table striped bordered hover className={`mt-3`} style={{borderColor: "#282E3B"}}>
              <thead>
              <tr>
                <th>History</th>
              </tr>
              </thead>
              <tbody>
              {dataHistory !== undefined ? Object.entries(dataHistory).map(([key, value]) => (
                <EmaHistoryItem key={key} itemValue={value} setItemDelete={onItemDelete}/>
              )) : <></>
              }
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
    <AlertPricingModal show={show} setShow={setShow}/>
    <RsiModal show={showRsiModal} setShow={setShowRsiModal}/>
    <VolModal show={showVolModal} setShow={setShowVolModal}/>
    <LTModal show={showLTModal} setShow={setShowLTModal} refreshSeenIndex={refreshSeenIndex}/>
    <GoshHelpModal show={showGoshHelpModal} setShow={setShowGoshHelpModal}/>
  </section>
}

export default MarketEmaAnalysis;
