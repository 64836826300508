import { allConstants } from '../../constants';

const initState = {} //{'BTCUSDT': 0}

export default function kline(state = initState, action) {
  switch (action.type) {
    case allConstants.INIT_SET_KLINES:
      return action.data
    case allConstants.SET_KLINES:
      const symbolPair = action.data.symbol
      const price = action.data.price
      return {...state, [symbolPair]: price}
    default:
      return state
  }
}