import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";

function Header(props) {


  return <div>
    <div className="row mb-3">
      <div className="col-12 text-center">
        <h1 className="d-sm-block p-2 center font-weight-bold">
          <Link to={`/futures/create`} className={`btn btn-sm mt-1`}>Create</Link>
        </h1>
        <Link to={`/`} className="monospace-font dimmed small">HOME</Link>
        <span> |  </span>
        <Link to={`/futures-2`} className="monospace-font dimmed small">FUTURES</Link>
      </div>
    </div>
  </div>
}

export {Header};
