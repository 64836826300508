export const allConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    // GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GET_DASHBOARD_SUCCESS: 'GET_DASHBOARD_SUCCESS',
    GET_DASHBOARD_FAILURE: 'GET_DASHBOARD_FAILURE',

    INIT_SET_KLINES: 'INIT_SET_KLINES',
    SET_KLINES: 'SET_KLINES',
    SET_BINANCE_TOTAL_MARKET: 'SET_BINANCE_TOTAL_MARKET',
    SET_TOTAL_MARKET: 'SET_TOTAL_MARKET',

    TOGGLE_RESET_STAND_BY: 'TOGGLE_RESET_STAND_BY',
    SET_RESET_STAND_BY_ON: 'SET_RESET_STAND_BY_ON',
    SET_RESET_STAND_BY_OFF: 'SET_RESET_STAND_BY_OFF',

    SET_FAKE_ORDER_INPUT: 'SET_FAKE_ORDER_INPUT',

    SET_IMAGE_VIEWS: 'SET_IMAGE_VIEWS',
};
