import React, {useState, useEffect} from 'react';
import {generalService} from "../../../services";
import {moreDigitsDollarFormat} from "../../../helpers";

function GoshHelpItem({key, itemValue, afterDelete, setItemToEdit}) {

  useEffect(() => {
  }, [itemValue])

  const handleDelete = (e) => {
    e.preventDefault();
    generalService.GoshHelpDelete({id: itemValue.id}).then(afterDelete)
  }

  const toEdit = async (id) => {
    setItemToEdit(id)
  }

  return <tr key={key}>
    <td style={{position: "relative", cursor: "default"}}>
      <p className={`small mb-1`} onClick={() => toEdit(itemValue.id)}>
        <strong style={{cursor: "pointer"}}>
          {itemValue.Gosh_Saying ? itemValue.Gosh_Saying.toUpperCase() : ''}
        </strong>
        &nbsp;
        <span className={`dimmed price small`}>({itemValue.created_at_formatted})</span>
      </p>
      <span className={`price small`}>{itemValue.title}</span>
      <div
        style={{
          position: "absolute",
          top: 0, right: 0,
          paddingTop: "0.5rem",
          width: "2rem"
        }}
      >
        <span
          className="btn-default m-1"
          onClick={handleDelete}
        >
          <i className={`fa fa-trash`}
             aria-hidden="true"
             style={{
               cursor: "pointer",
               fontSize: "80%",
             }}
          />
        </span>
      </div>
    </td>
  </tr>
}

export {GoshHelpItem};
