import React, {useEffect, useState} from 'react';
import {dollarFormat, moreDigitsDollarFormat, percentFormat, thousandFormat} from "../../helpers";
import {useDispatch, useSelector} from "react-redux";
import {confirmAlert} from "react-confirm-alert";
import {generalActions} from "../../redux";
import {Link} from "react-router-dom";
import {generalService} from "../../services";
import {useParams} from "react-router";
import {allConstants} from "../../constants";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

function TradeItem(props) {
  const {item, setParentDeleteMark, isNonBinancePair, balance} = props
  const {symbol, side, time, quoteQty, qty, price, tradeId, orderId, id, qtyAfterCommission, quoteQtyAfterCommission} = item
  const dispatch = useDispatch();
  const resetState = useSelector(state => state.reset);

  const onResetHandle = () => {
    if (!resetState || !tradeId) {
      return false
    }

    confirmAlert({
      title: 'Save campaign',
      message: `Save campaign to this trade: ${side} ${moreDigitsDollarFormat(quoteQty)} (${time}).`,
      buttons: [{
        label: 'Confirm',
        onClick: () => {
          dispatch(generalActions.saveCampaign(symbol, tradeId));
        }
      },
        {
          label: 'Cancel',
        }
      ]
    })
  }

  const onDeleteOrdering = (e) => {
    e.preventDefault();
    generalService.deleteFakeOrder(orderId).then(r => setParentDeleteMark())
  }

  const onDeleteNonBinanceTrade = (e) => {
    e.preventDefault();
    generalService.nonBinancePairDelete(id).then(r => setParentDeleteMark())
  }

  return (
    <tr className="traded-pair-row"
        style={{lineHeight: 1.5, fontSize: '14px'}}
        onClick={onResetHandle}>
      <td>
        <span className="price-859-1505 price small dimmed">
          {item.time}
        </span>
        {
          isNonBinancePair ? <> <a href="javascript:void(0)"
                 className="hc-orphan-ordering-remove"
                 aria-disabled="true"
                 onClick={onDeleteNonBinanceTrade}>
                x</a>
            </>
            : <></>
        }
      </td>

      <td className="d-sm-table-cell text-center">
        <span className="price-859-1505 price dimmed">{thousandFormat(balance)}</span>
      </td>

      <td className="d-sm-table-cell text-center">
        <span className={`price-859-1505 price ${item.side === 'BUY' ? 'up' : 'down'}`}>{side}</span>
        {
          !tradeId ? <br/> : <></>
        }
        {
          !tradeId ? <><a href="javascript:void(0)"
                      className="btn btn-sm hc-orphan-ordering"
                      aria-disabled={"true"}>
              Ordering</a>
              <a href="javascript:void(0)"
                 className="hc-orphan-ordering-remove"
                 aria-disabled="true"
                 onClick={onDeleteOrdering}>
              x</a>
            </>
            : <></>
        }
      </td>

      <td className="d-sm-table-cell text-center">
        <span className="price-859-1505 price dimmed">{moreDigitsDollarFormat(price)}</span>
      </td>

      <td className="d-sm-table-cell text-center">
        <span className="price-859-1505 price dimmed">
          {qtyAfterCommission ? thousandFormat(qtyAfterCommission) : thousandFormat(qty)}
          {
            (qtyAfterCommission !== qty) ?
              <OverlayTrigger
                overlay={
                  <Tooltip
                    style={{paddingLeft: '5px'}}
                  >
                    Before commission:<br/>
                    {thousandFormat(qty)}
                  </Tooltip>
                }
                placement={`right`}
              >
            <span>
              &nbsp;
              <span style={{fontSize: '10px'}}>ⓘ</span>
            </span>
              </OverlayTrigger>
              : <></>
          }
        </span>
      </td>

      <td className="text-center">
        <span className="price-859-1505 price dimmed">
          {quoteQtyAfterCommission ? thousandFormat(quoteQtyAfterCommission) : thousandFormat(quoteQty)}
          {
            (quoteQtyAfterCommission !== quoteQty) ?
              <OverlayTrigger
                overlay={
                  <Tooltip
                    style={{paddingLeft: '5px'}}
                  >
                    Before commission:<br/>
                    {thousandFormat(quoteQty)}
                  </Tooltip>
                }
                placement={`right`}
              >
                <span>
                  &nbsp;
                  <span style={{fontSize: '10px'}}>ⓘ</span>
                </span>
              </OverlayTrigger>
              : <></>
          }
        </span>
      </td>
    </tr>
  )
}

function OrphanTable(props) {
  const {symbol} = useParams();
  const {isNonBinancePair, realtimePrice} = props;
  const dispatch = useDispatch();

  const [data, setData] = useState({
    summary: {},
    list: [],
    available: 0,
    marketValue: 0,
    sumQty: 0,
  })
  const {summary, list, marketValue, noOrphanButHolding} = data

  const {total_sells, total_purchases, net_cost, holding, orig_holding} = summary
  /*
  * 1. order.sells + trade
  * 2. order.all + trade
  * 3. trade only
  * */
  const [sortType, setSortType] = useState(3)
  const [deleteDone, setDeleteDone] = useState(false)

  useEffect(() => {
    dataHandle()
  }, [realtimePrice])

  useEffect(() => {
    dataHandle()
  }, [sortType])

  useEffect(() => {
    dataHandle()
  }, [deleteDone])

  const dataHandle = () => {
    generalService.getCampaignDetailOrphan(symbol, sortType)
      .then(data => {
        const {summary, list, no_orphan_but_holding} = data
        const marketValue = realtimePrice * (summary ? summary.holding : 0)

        setData({
          summary: summary ?? {},
          list: list,
          marketValue: marketValue,
          noOrphanButHolding: no_orphan_but_holding,
          sumQty: sumQty(list),
        })
      })
  }

  const sortHandle = () => {
    setSortType((sortType !== 3) ? sortType + 1 : 1)
  }

  const sortText = () => {
    return sortType === 3
      ? 'Trades only' : sortType === 2
        ? 'All orders + trades'
        : 'Sell orders + trades'
  }

  const fakeOrderInputPrepare = () => {
    dispatch({
      type: allConstants.SET_FAKE_ORDER_INPUT,
      data: {
        available: holding,
        realtime_price: realtimePrice,
        total_purchase: total_purchases,
        total_sells: total_sells,
      }
    })
  }

  const renderTradeItems = () => {
    const transactions = list
    let currentBalance = data.summary.holding

    const result = transactions.map((transaction, index) => {
      let balance = currentBalance;
      for (let i = 0; i < index; i++) {
        const prevTransaction = transactions[i];
        const prevTransactionValue = prevTransaction.qtyAfterCommission ?
          prevTransaction.qtyAfterCommission : prevTransaction.qty
        const qty = Number(prevTransactionValue)
        if (prevTransaction.side === "BUY") {
          balance -= qty;
        } else {
          balance += qty;
        }
      }
      return {
        ...transaction,
        balance: balance
      };
    });

    return result.map((item) => {
      const { id, qty, quoteQty, balance} = item;
      const key = id ? id : qty + quoteQty;

      return (
        <TradeItem
          isNonBinancePair={isNonBinancePair}
          key={key}
          item={item}
          balance={balance}
          setParentDeleteMark={() => setDeleteDone(new Date().getTime())}
        />
      );
    });
  }

  const sumQty = (array) => {
    return array.reduce((acc, cur) => {
      return cur.side === 'BUY'
        ? acc + parseFloat(cur.qty)
        : acc - parseFloat(cur.qty);
    }, 0);
  }

  return (summary && Object.keys(summary).length !== 0) ? <>
    <table className="table mt-3" data-dismiss={deleteDone}>
      <thead>
      <tr>
        <th className="d-sm-table-cell text-left"
            style={{fontSize: '14px', lineHeight: '22px'}}
            colSpan={2}>
          Total purchase: {dollarFormat(total_purchases)} <br/>
          {
            total_sells > 0
              ? `Total sell:  ${dollarFormat(total_sells)}`
              : ``
          }
          {total_sells > 0 ? <br/> : ``}
          {
            !orig_holding ? `Holding: ${thousandFormat(holding)}`
              : `Holding: ${thousandFormat(holding)} (${thousandFormat(orig_holding)})`
          }
          <br/>
          AVG: {moreDigitsDollarFormat(net_cost / holding)} <br/>
          NET COST: {dollarFormat(net_cost)} <br/>
          {`Profit: ${dollarFormat(marketValue - net_cost)}
        (${percentFormat((marketValue - net_cost) / total_purchases)})`}
        </th>
        <th style={{textAlign: 'right'}}>
          <a href="javascript:void(0)"
             className={`btn mt-1`}
             onClick={sortHandle}>
            {sortText()}</a>
          <Link to={`/fake-order/create/${symbol}`}
                className={`btn ml-3 mt-1`}
          onClick={fakeOrderInputPrepare}>
            Create F-Order</Link>
        </th>
      </tr>
      </thead>
    </table>

    <table className="table">
      <thead>
      <tr>
        <th className="d-sm-table-cell text-left"
            style={{width: "25%"}}>Time</th>
        <th className="d-sm-table-cell text-center">Balance</th>
        <th className="d-sm-table-cell text-center">Side</th>
        <th className="d-sm-table-cell text-center">Price</th>
        <th className="d-sm-table-cell text-center">Qty</th>
        <th className="d-sm-table-cell text-center">Total</th>
      </tr>
      </thead>
      <tbody id="exchange-data">
      {renderTradeItems()}
      </tbody>
    </table>
  </> : noOrphanButHolding ? <table className="table mt-3">
    <thead>
    <tr>
      <th className="d-sm-table-cell text-left"
          style={{fontSize: '14px', lineHeight: '22px'}}
          colSpan={2}>
        HOLDING: {thousandFormat(noOrphanButHolding)}
      </th>
    </tr>
    </thead>
  </table> : <></>
}

export {OrphanTable};
