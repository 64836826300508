import React from "react";

export const TITLE_1 = 'monospace-font dimmed small'

export const PRICE_2 = 'price-859-1505 price'

export const PRICE_1 = 'convertedprice-859-1505 price'
export const PRICE_4 = 'convertedprice-859-1505 small price'
export const PRICE_6 = 'convertedprice-859-1505 small price dimmed'

export const PRICE_3 = 'pricedelta-859-1505 smaller price'
export const PRICE_5 = 'pricedelta-859-1505 price'

export const LAYOUT_DESKTOP = 'd-none d-sm-table-cell'
export const LAYOUT_MOBILE = 'd-block d-sm-none'
export const LAYOUT_3 = 'traded-pair-row'

//   <th className="d-block d-sm-none text-center">Tada</th>
// <th className="d-none d-sm-table-cell text-center">Total Purchase</th>
// <th className="d-none d-sm-table-cell text-center">Total Sell</th>
// <th className="d-sm-table-cell text-center">Holding</th>
// <th className="d-none d-sm-table-cell text-center">Market Value</th>