import React, {useEffect, useState} from 'react';
import {Table} from "./Table";
import {Header} from "./Header";
import {useParams} from "react-router";
import {allConstants} from "../../constants";
import {useDispatch} from "react-redux";

function CampaignListPage() {
  const {symbol} = useParams();
  const dispatch = useDispatch();
  const [sort, setSort] = useState('date') //'name', 'date', 'profit', 'profit_percent'
  const sorts = ['Date', 'Name', 'Profit', 'ProfitPercent'];

  useEffect(() => {
    document.title = 'Campaign - List 🚀🚀';
    dispatch({type: allConstants.SET_RESET_STAND_BY_OFF})
  }, []);

  const SortButtons = () => {
    return sorts.map((name) => {
      return <span
        className={`btn mt-1 ml-2 ${sort === name.toLowerCase() ? 'btn-primary' : ''}`}
        onClick={() => setSort(name.toLowerCase())}
      >
      {name}
    </span>
    })
  }


  return (
    <section>
      <div className="container p-3">
        <Header title={`CAMPAIGN LIST`}
                symbol={symbol}
        />
        {SortButtons()}
        <Table symbol={symbol} sort={sort}/>
      </div>
    </section>
  );
}



export {CampaignListPage};
