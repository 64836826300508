import React, {useEffect, useState} from "react";
import {generalService} from "../../../services";
import TableItem from "./TableItem";
import {numberColor, percentFormat} from "../../../helpers";

function Table() {
  const [data, setData] = useState([])
  const [summary, setSummary] = useState({})
  const {day, month, week, all_time, yesterday} = summary;

  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)


  useEffect(() => {
    fetchList(currentPage).then(r => console.log('🚃'))
  }, [])
  //
  const fetchList = async (page = currentPage) => {
    let query = `?page=${page}`
    await generalService.listFutures(query)
      .then(res => {
        console.log(res.data_summary)
        const newData = page !== currentPage ? [...data, ...res.data] : res.data;
        setData(newData)
        setSummary(res.data_summary)
        setCurrentPage(res.current_page)
        setLastPage(res.last_page)
      });
  }

  const loadMoreHandler = () => {
    const nextPage = currentPage + 1
    fetchList(nextPage).then(r =>
      console.log(`🚃 TradeList fetch - ${nextPage}`))
  }

  const renderListItem = (deleteFiat) => {
    let jsToday = generalService.getDate()
    return data.map(item => {
      return <TableItem key={item.id} item={item} jsToday={jsToday}/>
    })
  }

  const deleteFiat = (id) => {
    generalService.fiatDelete(id).then(r => {
      fetchList(1).then(r => console.log('🚃'))
    })
  }

  return <>
    <table className="table">
      <thead>
      <th>
        {
          yesterday !== undefined ?
            <>
              <span>Yesterday: </span>
              {yesterday.str1 ? yesterday.str1 : ''}
              <span className={`price-859-1505 price`}>
                 {
                   yesterday.str2 ?
                     <span>
                       &nbsp; - &nbsp;
                       {percentFormat(yesterday.str2 / 100)}
                     </span>
                     : ''
                 }
                </span>
            </>
            : <></>
        }
        {
          week !== undefined ?
            <>
              <span>&nbsp;|&nbsp;Week: </span>
              {week.str1 ? week.str1 : ''}
              <span className={`price-859-1505 price`}>
                 {
                   week.str2 ?
                     <span>
                       &nbsp; - &nbsp;
                       {percentFormat(week.str2 / 100)}
                     </span>
                     : ''
                 }
                </span>
            </>
            : <></>
        }
        <span>&nbsp;|&nbsp;Month: </span>
        {
          month !== undefined ?
            <>
              {month.str1 ? month.str1 : ''}
              <span className={`price-859-1505 price`}>
                 {
                   month.str2 ?
                     <span>
                       &nbsp; - &nbsp;
                       {percentFormat(month.str2 / 100)}
                     </span>
                     : ''
                 }
                </span>
            </>
            : <></>
        }
        <span>&nbsp;|&nbsp;All Time: </span>
        {
          all_time !== undefined ?
            <>
              {all_time.str1 ? all_time.str1 : ''}
              <span className={`price-859-1505 price`}>
                 {
                   all_time.str2 ?
                     <span>
                       &nbsp; - &nbsp;
                       {percentFormat(all_time.str2 / 100)}
                     </span>
                     : ''
                 }
                </span>
            </>
            : <></>
        }
      </th>
      <tr>
        <th className={`text-left`} colSpan={3}>
          <span>Positions</span>
          {
            day !== undefined ?
              <>
                {day.str1 ? ' - ' + day.str1 : ''}
                <span className={`price-859-1505 price`}>
                 &nbsp; - &nbsp; {day.str2 ? percentFormat(day.str2 / 100) : ''}
                </span>
              </>
              : <></>
          }
        </th>
      </tr>

      </thead>
      <tbody id="exchange-data">
      {renderListItem(deleteFiat)}
      </tbody>
    </table>
    {currentPage !== lastPage ?
      <div style={{textAlign: 'center', paddingBottom: '15px'}}>
        <a onClick={loadMoreHandler}
           style={{
             'font-size': '24px',
             'cursor': 'pointer'
           }}>
          ...</a>
      </div>
      : <></>
    }
  </>
}

export default Table;
