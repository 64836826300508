import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {Loading} from "react-loading-dot";
import {generalService} from "../../../services";
import {Table} from "react-bootstrap";
import {thousandFormat} from "../../../helpers";

const LTModal = ({show, setShow, refreshSeenIndex}) => {
  const [data, setData] = useState([]);
  const [unSeen, setUnSeen] = useState(0);
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)

  const hideModalHandle = async () => {
    document.title = 'Analysis Dashboard 🚀🚀'
    setShow(false)
    refreshSeenIndex()
  }

  useEffect(() => {
    if (show && data.length === 0) {
      fetchList().then()
    }
  }, [show])

  const fetchList = async (page = currentPage) => {
    setLoading(true)
    let query = `?page=${page}`
    query += '&XDEBUG_SESSION_START=PHPSTORM'
    generalService.rsiLTGet(query)
      .then(res => {
        const newData = {
          ...data,
          ...Object.entries(res.data).reduce((acc, [key, value]) => {
            acc[key] = [...(data[key] || []), ...value];
            return acc;
          }, {})
        };

        setData(newData)
        setUnSeen(res.unseen)
        setCurrentPage(res.current_page)
        setLastPage(res.last_page)
        setLoading(false)
      });
  }

  const loadMoreHandler = () => {
    const nextPage = currentPage + 1
    fetchList(nextPage).then(r =>
      console.log(`🚃 LT Fetch - ${nextPage}`))
  }

  const seenOnClick = () => {
    generalService.rsiLTSeen().then(r => {
      setUnSeen(r.data)
    })
  }

  const ltCopyHandle = (key) => {
    let thisData = data[key]
    let outputStr = ''
    let count = thisData.length
    thisData.forEach((data, key) => {
      outputStr += data['symbol'] + (key + 1 < count ? ', ' : '');
    })
    navigator.clipboard.writeText(outputStr).then(() => {})
  }

  const dataTable = () => {
    return (
      <Table className={`mt-3 striped bordered hover small`} style={{borderColor: "#282E3B"}}>
        <thead>
        <tr>
          <th width={`15%`}>Date</th>
          <th>
            Data (D1.RSI30, H4.Derivative, H4.RSI30) →↓
            <span onClick={seenOnClick} style={{float: "right", cursor: "pointer"}}>
              <i className="fa fa-eye" aria-hidden="true"/>
              <span className={`ml-1`}>{unSeen}</span>
            </span>
          </th>
        </tr>
        </thead>
        <tbody>
        {
          data !== undefined ? Object.entries(data).map(([key, value]) => (
          <tr key={key}>
            <td>
              {key}
              <span onClick={() => ltCopyHandle(key)} style={{cursor: "pointer"}}>&nbsp;📋</span>
            </td>
            <td className={`pb-0 pt-0`}  style={{display: "inline-block", width: `100%`}}>
              <tr>
                {
                  value.map(item => (
                    <tr style={{display: "inline-block"}}>
                      <td width={`80`} className={`noborder`}>{item.symbol}</td>
                      <td width={`20%`} className={`noborder`}><span style={{color: item.rsi_30_d1 === 1 ? '#00ff00' : 'inherit'}}>◉</span></td>
                      <td width={`20%`} className={`noborder`}><span style={{color: item.h4_derivative === 1 ? '#00ff00' : 'inherit'}}>◉</span></td>
                      <td width={`20%`} className={`noborder`}><span style={{color: item.rsi_30_h4 === 1 ? '#00ff00' : 'inherit'}}>◉</span></td>
                    </tr>
                  ))
                }
              </tr>
            </td>
          </tr>
          )) : <></>
        }
        </tbody>
      </Table>
    );
  }

  return (
    <>
      {loading ? <Loading background={`rgb(225, 239, 250)`}/> : ''}
      <Modal
        className="alert-pricing-modal"
        size="lg"
        show={show}
        onHide={() => hideModalHandle()}
        aria-labelledby="alert-pricing-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="alert-pricing-modal"/>
        </Modal.Header>
        <Modal.Body>
          {dataTable()}
          {currentPage !== lastPage ?
            <div style={{textAlign: 'center', paddingBottom: '15px'}}>
              <a onClick={loadMoreHandler}
                 style={{
                   'font-size': '24px',
                   'cursor': 'pointer'
                 }}>
                ...</a>
            </div>
            : <></>
          }
        </Modal.Body>
      </Modal>
    </>
  );
}

export {LTModal};
