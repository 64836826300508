import React, {useCallback, useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {generalService} from "../../../services";
import {alertActions} from "../../../redux";
import {useDispatch} from "react-redux";
import axios from "axios";
import ImageViewer from "react-simple-image-viewer";
import {allConstants} from "../../../constants";


const MarketNoteDetail = ({marketNodeId})  => {
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const [showModalEditNote, setShowModalEditNote] = useState(false);
  const [dataModalEditNote, setDataModalEditNote] = useState({id: null, note: ''});

  useEffect(() => {
    if (marketNodeId) {
      generalService.getMarketImages(marketNodeId)
        .then(res => {
          setImages(res.data)
        });
    }
  }, [])

  const openImageViewer = useCallback((filename) => {
    dispatch({
      type: allConstants.SET_IMAGE_VIEWS,
      data: {
        'isViewerOpen': true,
        'filename': [filename]
      }
    })
  }, []);

  const handlePaste = (e, imageId) => {
    if (e.clipboardData.files.length) {
      const fileObject = e.clipboardData.files[0];

      let form = new FormData();
      form.append('file', fileObject);
      form.append('market_note_id', marketNodeId);
      if (imageId !== undefined) {
        form.append('image_id', imageId);
      }

      axios.post(`${process.env.REACT_APP_API_ENDPOINT}/market/notes-image?XDEBUG_SESSION_START=PHPSTORM`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + localStorage.getItem('HC_TOKEN')
        }
      }).then(r => {
        return generalService.getMarketImages(marketNodeId)
          .then(res => {
            setImages(res.data)
          });
      })
    } else {
      alert('No image data was found in your clipboard. Copy an image first or take a screenshot.'
      );
    }
  };

  const hideEditModalHandle = async () => {
    setShowModalEditNote(false)
  }

  const onNoteIdClick = (id, note, filename) => {
    setShowModalEditNote(true)
    setDataModalEditNote({id: id, note: note, filename: filename})
  }

  const onNoteParagraphClick = (e) => {
    if (e.target.innerText.startsWith('#')) {
      const text = e.target.innerText;
      const id = text.substring(text.indexOf('#') + 1);
      let targetedImage = images[parseInt(id) - 1];
      if (targetedImage !== undefined) {
        openImageViewer(targetedImage.filename)
      }
    }
    return true
  };

  const handleNoteChange = async (e) => {
    const {value} = e.target;
    await setDataModalEditNote({...dataModalEditNote, note: value});
  }

  const handleNoteEditSubmit = (e) => {
    e.preventDefault();
    generalService.marketImageNoteEdit(dataModalEditNote).then(r => {
      setShowModalEditNote(false)
      dispatch(alertActions.success('Image description note is updated!'));
      setDataModalEditNote({id: null, note: ''});

      return generalService.getMarketImages(marketNodeId)
        .then(res => {
          setImages(res.data)
        });
    })
  }

  const handleDeleteImage = (id) => {
    generalService.marketImageNoteDelete(id).then(r => {
      setShowModalEditNote(false)
      return generalService.getMarketImages(marketNodeId)
        .then(res => {
          setImages(res.data)
        });
    })
  }

  const handleDisplayNoteText = (text) => {
    const words = text.split(" ");
    return (
      <>
        {words.map((word) =>
          word.startsWith("#") ? (
            <span onClick={onNoteParagraphClick} style={{color: "#2E7AD0;"}}>{word} </span>
          ) : (
            <>{word} </>
          )
        )}
      </>
    );
  }

  return <>
    {
      images.map((image, index) => (<>
          <hr/>
          <div className="row" onPaste={(e) => handlePaste(e, image.id)}>
            <div className="col-lg-6 col-xs-12">
              <div style={{position: "relative"}} key={index}>
                <img
                  src={image.filename}
                  onClick={() => openImageViewer(image.filename)}
                  width="100%"
                  key={index}
                  style={{margin: "2px"}}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 col-xs-12 mt-3">
              <p
                className={`right, mb-0`}
                style={{color: '#626B7F', padding: '1rem'}}
                onClick={() => onNoteIdClick(image.id, image.note, image.filename)}
              >
                #{index + 1}
              </p>
              <p
                style={{whiteSpace: 'pre-wrap', fontSize: '14px', padding: '0 1rem'}}
              >
                {handleDisplayNoteText(image.note)}
              </p>
            </div>
          </div>
        </>
      ))
    }
    <h3 className={`mt-3 text-center paste-zone`} onPaste={handlePaste}>Paste to create</h3>

    <Modal
      size="lg"
      show={showModalEditNote}
      onHide={() => hideEditModalHandle()}
      aria-labelledby="1"
      className={`modal-edit-note`}
    >
      <Modal.Header closeButton>
        <Modal.Title id="1">
          Edit
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img
          src={dataModalEditNote.filename}
          width="60%"
          style={{margin: '0 auto', display: 'block'}}
        />
        <form role="form"
              id=""
              className="mt-3"
              onSubmit={handleNoteEditSubmit}>
          <div>
            <textarea
              name="note"
              rows="10"
              className="form-control mt-3"
              onChange={handleNoteChange}
              value={dataModalEditNote.note}
            />
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 col-md-4">
                <button
                  className="btn btn-block btn-primary mt-3"
                  type="button"
                  style={{fontSize: '1rem', paddingTop: '6px', paddingBottom: '6px'}}
                  onClick={handleNoteEditSubmit}
                >
                  SAVE
                </button>
              </div>

              <div className="col-lg-1 col-md-4 pd-2">
                <p
                  className={`mb-0`}
                  style={{position: "absolute", bottom: 0}}
                  onClick={() => handleDeleteImage(dataModalEditNote.id)}
                >
                  Delete
                </p>
              </div>
            </div>

          </div>
        </form>
      </Modal.Body>
    </Modal>
  </>
}

export {MarketNoteDetail};
