import React, {useState, useEffect, useCallback} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {generalService} from "../../services";
import {Note} from "./components/Note";
import {useParams} from "react-router";
import ImageViewer from "react-simple-image-viewer";
import axios from "axios";

function FuturesCreate() {
  const {id, futureId} = useParams()

  const [imageReviews, setImageReviews] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const [isSummited, setIsSummited] = useState(false);
  const initInput = {futures_id: null, position: 1, profit: 0, profit_image: null, position_title: '', position_description: "", date: generalService.getDateTime()}
  const [inputs, setInputs] = useState(initInput);
  const {futures_id, position, profit, profit_image, position_title, position_description, date} = inputs;

  const [notes, setNotes] = useState([])
  const [imagesObject, setImageObject] = useState([])
  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    setIsDeleted(false)
    document.title = 'Futures Note | Create 🚀🚀'
    return initFunction().then(r => console.log(111))
  }, [])

  const initFunction = async () => {
    if (id) {
      generalService.getFutures(id).then(async r => {
        let data = r.data
        setInputs({
          futures_id: data.id,
          position: data.position,
          profit: data.profit,
          profit_image: data.profit_image,
          position_title: data.position_title,
          position_description: data.position_description,
          date: data.date,
        })

        await setNotes(data.notes)

        const dataImages = data.images
        await setImageObject(dataImages)

        await setImageReviews(dataImages.map(item => item.filename))
      })
    }
  }

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let requestInputs = inputs
    if (!inputs.profit) {
      delete requestInputs.profit
    }
    if (!inputs.profit_image) {
      delete requestInputs.profit_image
    }
    if (id) {
      requestInputs = {...requestInputs, id: id}
    }
    if (futureId) {
      requestInputs = {...requestInputs, position_title: '#FutureHistory - ' + futureId}
      requestInputs = {...requestInputs, profit: 100}
      requestInputs = {...requestInputs, history_id: futureId}
    }
    generalService.futuresStore(requestInputs).then(r => {
      setIsSummited(true)
    })
  }

  const handleAddNote = (e) => {
    e.preventDefault();

    setNotes([...notes, {key: Date.now(), is_new_note: true}])
  }

  const handleDeleteImage = (id) => {
    generalService.imageDelete(id).then(r => setImageObject(imagesObject.filter(item => item.id !== id)))
  }

  const handleChange = async (e) => {
    let {name, value} = e.target;

    if (name === 'position_title') {
      value = value ? value.toUpperCase() : null
    }

    setInputs(inputs => ({...inputs, [name]: value}));
  }

  const handlePaste = (e) => {
    if (!futures_id) {
      alert(
        "Save futures basic info first"
      );
    }

    if (e.clipboardData.files.length) {
      const fileObject = e.clipboardData.files[0];

      let form = new FormData();
      form.append('file', fileObject);
      form.append('futures_id', futures_id);
      form.append('type', 1);

      axios.post(`${process.env.REACT_APP_API_ENDPOINT}/futures/upload`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + localStorage.getItem('HC_TOKEN')
        }
      }).then(r => {
        return initFunction().then(r => console.log(111))
      })

    } else {
      alert(
       'No image data was found in your clipboard. Copy an image first or take a screenshot.'
      );
    }
  };

  const handleProfitImageChange = (e) => {
    if (!futures_id) {
      alert(
        "Save futures basic info first"
      );
    }

    if (e.target.files.length) {
      const fileObject = e.target.files[0];

      let form = new FormData();
      form.append('file', fileObject);
      form.append('futures_id', futures_id);
      form.append('type', 2);

      axios.post(`${process.env.REACT_APP_API_ENDPOINT}/futures/upload`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + localStorage.getItem('HC_TOKEN')
        }
      }).then(r => {
        return initFunction().then(r => console.log(111))
      })

    } else {
      alert(
        'No image data was found in your clipboard. Copy an image first or take a screenshot.'
      );
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    if (id) {
      generalService.futuresDelete(id).then(r => setIsSummited(true))
    }
  }

  return isSummited ? (
      futureId ? <Redirect to={`/futures`}/> : <Redirect to={`/futures-2`}/>
    )
    : <div className="container">
      <div className="row mb-3">
        <div className="col-md-6 mx-auto mt-5">
          <div className="text-center">
            <h3>
              FUTURES - CREATE
              &nbsp;
              <a href="javascript:void(0)" className={`price-859-1505 price dimmed`} onClick={handleDelete}>x</a>
            </h3>
            <Link to={`/futures`} className="monospace-font dimmed">Back</Link>
            <span> |  </span>
            <Link to={`/futures-2`} className="monospace-font dimmed">Futures</Link>
          </div>
        </div>
      </div>

    <div className="row">
      <div className="col-lg-6 col-xs-12">
        <h3 onPaste={handlePaste}>Paste image here</h3>
        {imagesObject.map((image, index) => (
          <div style={{position: "relative"}} key={index}>
            <img
              src={image.filename}
              onClick={() => openImageViewer(index)}
              width="100%"
              key={index}
              style={{margin: "2px"}}
              alt=""
            />
            <a
              href="javascript:void(0)"
              style={{
                position: 'absolute',
                top: '1rem',
                right: '1rem',
                fontSize: '18px',
                color: 'white',
              }}
              onClick={() => handleDeleteImage(image.id)}
            >x
            </a>
          </div>
        ))}

        {isViewerOpen && (
          <ImageViewer
            src={imageReviews}
            currentIndex={currentImage}
            onClose={closeImageViewer}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)"
            }}
            closeOnClickOutside={true}
          />
        )}
      </div>
      <div className="col-lg-6 col-xs-12">
        {profit_image ? <img width={`50%`} src={profit_image}/> : <></>}
        <form
          id="fake-order-create-form"
          className="hc-form mt-3"
          onSubmit={handleSubmit}>

          <input
            type="text"
            name="date"
            className="form-control mt-3 hc-input-not-disable"
            onChange={handleChange}
            value={date}
            placeholder="Note..." autoComplete="off"/>

          <select className="coin-select bs-select form-control text-center mt-3 pr-0"
                  name="position"
                  onChange={handleChange}
                  value={position}
          >
            <option value={1}>Long</option>
            <option value={2}>Short</option>
          </select>

          <input
            name="position_title"
            className="form-control mt-3 hc-input-not-disable text-uppercase"
            onChange={handleChange}
            value={position_title}
            placeholder="BTC x50" autoComplete="off"/>

          <textarea
            name="position_description"
            className="form-control mt-3"
            onChange={handleChange}
            value={position_description}
            placeholder="M15 rút fib 50 đẹp..."
          />

          <div className="col-12 text-left mt-5 mb-2 p-0">
            <span className={``}>Profit</span>
            <div className="row">
              <div className="col-6 p-0">
                <input
                  name="profit"
                  className="form-control mt-3 m-lg-2 hc-input-not-disable text-uppercase"
                  onChange={handleChange}
                  value={profit}
                  placeholder="BTC x50" autoComplete="off"/>
              </div>
              <div className="col-6 p-0">
                <input
                  type="file"
                  name="position_title"
                  className="form-control mt-3 m-lg-2 hc-input-not-disable text-uppercase"
                  onChange={handleProfitImageChange}
                  style={{
                    fontSize: '14px',
                  }}
                  placeholder="BTC x50" autoComplete="off"/>
              </div>
            </div>
          </div>

          <div className="col-12 text-left mt-5 mb-2 p-0">
            <span className={``}>Notes</span>
            <button className="btn btn-default m-1" type="button" onClick={handleAddNote}>
              <i className="fa fa-plus"
                 aria-hidden="true"
                 style={{
                   color: "#fff",
                   paddingLeft: "5%"
                 }}
              />
            </button>
          </div>

          <div>
            {
              notes.map((note, i) =>
                <Note key={note.key} note={note} futuresId={id}/>
              )
            }
          </div>


          <button className="btn btn-block btn-primary mt-3"
                  type="button"
                  onClick={handleSubmit}
                  style={{
                    fontSize: '1rem',
                    paddingTop: '6px',
                    paddingBottom: '6px'
                  }}
          >
            SAVE
          </button>
          {isSummited === true ? <p>Saved!</p> : <></>}
        </form>
      </div>
    </div>
  </div>
}

export default FuturesCreate;
