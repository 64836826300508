import React, {useEffect, useState} from 'react';
import {generalService} from "../../services";

function TrendSwitching() {
  const [trend, setTrend] = useState(null)

  useEffect(() => {
    alertSwitchingStatusFetching()
  }, [])

  useEffect(() => {
    // alertSwitchingStatusFetching()
  }, [trend])

  const alertSwitchingStatusFetching = () => {
    generalService.trendSwitchingStatus().then(res => {
      setTrend(res.data)
    })
  }

  const trendSwitchingHandle = () => {
    let switchTo = (trend === 'bull') ? 'bear' : 'bull'
    generalService.trendSwitching(switchTo).then(res => {
      setTrend(res.data)
    })
  }

  return <>
    {
      trend != null ?
        <span
          onClick={trendSwitchingHandle}
          style={{
            color: trend === 'bull' ? "rgba(46,122,208,0.73)" : "red",
            width: "2rem",
            fontSize: "1.12rem"
          }}
        >
          &nbsp;&nbsp;
          <i
            className={`fa switching ${trend === 'bull' ? 'fa-level-up' : 'fa-level-down'}`}
            aria-hidden="true"
          />
        </span>
        : <></>
    }
  </>
}

export {TrendSwitching};
