import React, {useEffect, useState} from 'react';
import {dollarFormat, numberColor, percentFormat} from "../helpers";
import {generalActions} from "../redux";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {generalService} from "../services";
import { Loading } from 'react-loading-dot'

function Header(props) {
  const dispatch = useDispatch();
  const binanceTotalMarket = useSelector(state => state.binanceTotalMarket);
  const totalMarket = useSelector(state => state.totalMarket);
  const [headerData, setHeaderData] = useState({
    'binance_market_value': 0,
    'market_value': 0,
    'market_value_pl': 0,
    'market_value_pl_percent': 0,
  })
  const [fiatNow, setFiatNow] = useState(0)
  const [marketValue, setMarketValue] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    generalService.getFiatNow()
      .then(res => {
        setFiatNow(res.usdt_amount)
      });
  }, [])

  useEffect(() => {
    calcHeaderData().then(() => {})
    if (loading === true) {
      setLoading(false)
    }
  }, [props.data, totalMarket])

  const updateDashboardData = () => {
    dispatch(generalActions.updateDashboardData());
    setLoading(true)
  }

  const calcHeaderData = async () => {
    if (Object.entries(totalMarket).length > 0) {
      const binanceMarketValue = Object.values(binanceTotalMarket).reduce((a, b) => a + b) ?? 0
      let marketValue = Object.values(totalMarket).reduce((a, b) => a + b) ?? 0
      marketValue = marketValue - props.data.borrowing
      const marketPl = marketValue - props.data.all_net_cost
      const marketPlPercent = marketPl / props.data.all_net_cost

      await setHeaderData({
        'binance_market_value': binanceMarketValue,
        'market_value': marketValue,
        'market_value_pl': marketPl,
        'market_value_pl_percent': marketPlPercent,
      })

      setMarketValue(marketValue + props.data.usdt + props.data.busd)
    }
  }

  return (
    <div>
      {loading ? <Loading background={`rgb(225, 239, 250)`}/> : ''}
      <div className="row ml-3 mr-3 mb-4">
        <div className="col-12 mx-auto">
          <div className="row mb-3">
            <div className="col-4">
              <h1 className="d-none d-sm-block p-2">
                DASHBOARD
                {/* eslint-disable-next-line no-script-url */}
                <a href="javascript:void(0)" onClick={updateDashboardData}>
                  <i className="fa fa-sync"
                     aria-hidden="true"
                     style={{
                       color: "#2E7AD0",
                       padding: "10%"
                     }}
                  />
                </a>
              </h1>

            </div>
            <div className="col-8 text-right">
              <div className="d-none d-sm-block">
                <Link to={`/chart`}>
                  <span className="monospace-font dimmed small">BINANCE MARKET VALUE + FIAT: </span>
                  <span className={`price-859-1505 price ${numberColor(headerData.market_value_pl)}`}>
                  {dollarFormat(headerData.binance_market_value + props.data.usdt + props.data.busd)}
                </span>
                </Link>
                <br/>
                <span className="monospace-font dimmed small">TOTAL MARKET VALUE + FIAT: </span>
                <span className={`price-859-1505 price ${numberColor(headerData.market_value_pl)}`}>
                {dollarFormat(marketValue)}
              </span>
                <br/>
                <span className="monospace-font dimmed small">MARKET VALUE: </span>
                <span className={`price-859-1505 price ${numberColor(headerData.market_value_pl)}`}>
                {dollarFormat(headerData.market_value)}
                  <span className="smaller"> ({dollarFormat(headerData.market_value_pl)})</span>
              </span>
                <br/>
                <span className="monospace-font dimmed small">PROFIT/LOSS: </span>
                <span className={`price-859-1505 price ${numberColor(headerData.market_value_pl_percent)}`}>
                {percentFormat(headerData.market_value_pl_percent)}
              </span>
                <br/>
                <br/>
                <span className="monospace-font dimmed small">TOTAL INVEST: </span>
                <span className="price-859-1505 price">{dollarFormat(props.data.all_net_cost_include_stable_coins)}</span>
                <br/>
                <span className="rangelow-859-1505 price dimmed small">NET COST:&nbsp; {dollarFormat(props.data.all_net_cost)}</span>
                <br/>
                <span className="rangelow-859-1505 price dimmed small">FIAT:&nbsp; {dollarFormat(props.data.usdt + props.data.busd)}</span>
                <br/>
                <Link to={`/fiat`}>
                  <span className="monospace-font dimmed small">INITIAL INVEST: </span>
                  <span className={`price-859-1505 price  ${numberColor(11650)}`}>
                  {dollarFormat(fiatNow)}
                    <br/>
                  <span className={`smaller ${marketValue > fiatNow ? 'up' : 'down'}`}>
                    ({dollarFormat(marketValue - fiatNow)} =
                  </span>
                  <span>{percentFormat((marketValue - fiatNow) / fiatNow)})</span>
                </span>
                </Link>
                <br/>
                <Link to={`/ema-analysis`} className="monospace-font dimmed small">ANALYSIS</Link>
                <span> |  </span>
                <Link to={`/market/1`} className="monospace-font dimmed small">MARKET</Link>
                <span> |  </span>
                <Link to={`/futures-2`} className="monospace-font dimmed small">FUTURES</Link>
                <span> |  </span>
                <Link to={`/trades/all`} className="monospace-font dimmed small">TRADE LIST</Link>
                <span> |  </span>
                <Link to={`/orders/all`} className="monospace-font dimmed small">ORDER LIST</Link>
                <span> |  </span>
                <Link to={`/campaigns`} className="monospace-font dimmed small">CAMPAIGN LIST</Link>
                <span> |  </span>
                <Link to={`/non-binance/create`} className="monospace-font dimmed small">NON BINANCE</Link>
                <span> |  </span>
                <Link to={`/logout`} className="monospace-font dimmed small">LOG OUT</Link>
                <br/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row d-block d-sm-none p-3 hc-shadow">
        <h2 className="mt-2">
          DASHBOARD
          <a href="javascript:void(0)" onClick={updateDashboardData}>
            <i className="fa fa-sync"
               aria-hidden="true"
               style={{
                 color: "#2E7AD0",
                 paddingLeft: "5%"
               }}
            />
          </a>
        </h2>
        <Link to={`/chart`}>
          <span className="monospace-font dimmed small">BINANCE MARKET VALUE + FIAT: </span>
          <span className={`price-859-1505 price ${numberColor(headerData.market_value_pl)}`}>
                  {dollarFormat(headerData.binance_market_value + props.data.usdt + props.data.busd)}
                </span>
        </Link>
        <br/>
        <span className="monospace-font dimmed small">TOTAL MARKET VALUE: </span>
        <span className={`price-859-1505 price ${numberColor(headerData.market_value_pl)}`}>
                {dollarFormat(marketValue)}
              </span>
        <br/>
        <span className="monospace-font dimmed small">MARKET VALUE: </span>
        <span className={`price-859-1505 price ${numberColor(headerData.market_value_pl)}`}>
                {dollarFormat(headerData.market_value)}
          <span className="smaller">({dollarFormat(headerData.market_value_pl)})</span>
              </span>
        <br/>
        <span className="monospace-font dimmed small">PROFIT/LOSS: </span>
        <span className={`price-859-1505 price ${numberColor(headerData.market_value_pl_percent)}`}>
                {percentFormat(headerData.market_value_pl_percent)}
              </span>
        <br/>

        <br/>
        <span className="monospace-font dimmed small">TOTAL INVEST: </span>
        <span className="price-859-1505 price">{dollarFormat(props.data.all_net_cost_include_stable_coins)}</span>
        <br/>
        <span className="rangelow-859-1505 price dimmed small">NET:&nbsp; {dollarFormat(props.data.all_net_cost)}</span>
        <br/>
        <span className="rangelow-859-1505 price dimmed small">FIAT:&nbsp; {dollarFormat(props.data.usdt + props.data.busd)}</span>
        <br/>
        <Link to={`/fiat`}>
          <span className="monospace-font dimmed small">INITIAL INVEST: </span>
          <span className={`price-859-1505 price  ${numberColor(11650)}`}>
          {dollarFormat(fiatNow)}
            <span className={`smaller ${marketValue > fiatNow ? 'up' : 'down'}`}> ({dollarFormat(marketValue - fiatNow)} =</span>
          <span>{percentFormat((marketValue - fiatNow) / fiatNow)})</span>
        </span>
        </Link>
        <br/>
        <Link to={`/ema-analysis`} className="monospace-font dimmed small">ANALYSIS</Link>
        <span> |  </span>
        <Link to={`/market/1`} className="monospace-font dimmed small">MARKET</Link>
        <span> |  </span>
        <Link to={`/futures-2`} className="monospace-font dimmed small">FUTURES</Link>
        <span> |  </span>
        <Link to={`/orders/all`} className="monospace-font dimmed small">ORDER LIST</Link>
        <span> |  </span>
        <Link to={`/trades/all`} className="monospace-font dimmed small">TRADE LIST</Link>
        <span> |  </span>
        <Link to={`/campaigns`} className="monospace-font dimmed small">CAMPAIGN LIST</Link>
        <span> |  </span>
        <Link to={`/non-binance/create`} className="monospace-font dimmed small">NON BINANCE</Link>
        <span> |  </span>
        <Link to={`/logout`} className="monospace-font dimmed small">LOG OUT</Link>
      </div>
    </div>
  )
}

export {Header};
