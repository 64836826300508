import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import alert from './alert/alert.Reducer'
import authentication from './authentication/authentication.Reducer'
import general from './general/general.Reducer'
import kline from './kline/kline.Reducer'
import binanceTotalMarket from './binanceTotalMarket/binanceTotalMarket.Reducer'
import totalMarket from './totalMarket/totalMarket.Reducer'
import reset from './reset/reset.Reducer'
import fakeOrderInput from './fakeOrderInput/fakeOrderInput.Reducer'
import imageViewer from "./imageViewer/imageViewer.Reducer";

const createRootReducer = (history) => combineReducers({
  alert,
  authentication,
  general,
  kline,
  binanceTotalMarket,
  totalMarket,
  imageViewer,
  reset,
  fakeOrderInput,
  router: connectRouter(history)
})

export default createRootReducer
