import React, {useCallback, useEffect, useRef, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {generalService} from "../../../services";
import {Table} from "react-bootstrap";
import {AlertPricingItem} from "./AlertPricingItem";
import {moreDigitsDollarFormat} from "../../../helpers";
import {GoshHelpItem} from "./GoshHelpItem";
import {alertActions} from "../../../redux";
import {useDispatch} from "react-redux";

const initialInputs = {id: null, title: '', a: '', b: '', starIndex: ''}

const GoshHelpModal = ({show, setShow}) => {
  const dispatch = useDispatch();
  let [inputs, setInputs] = useState(initialInputs);
  const [dataItems, setDataItems] = useState([]);
  const [starIndex, setStarIndex] = useState('');
  const refA = useRef('');
  const refB = useRef('');

  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)

  const [timerRunning, setTimerRunning] = useState(false);


  const hideModalHandle = async () => {
    document.title = 'Analysis Dashboard 🚀🚀'
    setShow(false)
    setInputs(initialInputs);
  }

  useEffect(() => {
    document.title = 'Alert | Analysis Dashboard 🚀🚀'
  }, [])

  useEffect(() => {
    generalDataFetch()

    const handleKeyDown = (event) => {
      if (event.shiftKey && event.keyCode === 82 && show === true) {
        // SHIFT + r pressed
        onRefresh();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [show])

  useEffect(() => {
  }, [starIndex])

  const generalDataFetch = () => {
    if (show && dataItems.length === 0) {
      fetchList().then()
    }
  }

  const fetchList = async (page = currentPage) => {
    let query = `?page=${page}`
    generalService.GoshHelpList(query).then(r => {
      const newData = page !== currentPage ? [...dataItems, ...r.data] : r.data;
      setDataItems(newData)
      setCurrentPage(r.current_page)
      setLastPage(r.last_page)
    })
  }

  const toggleStars = useCallback(async () => {
    const updatedStarIndex = starIndex === 'a' ? 'b' : 'a';
    setStarIndex(updatedStarIndex);
  }, [starIndex]);

  useEffect(() => {
    let intervalId;
    const randomTimeout = Math.floor(Math.random() * 401) + 200;

    if (timerRunning) {
      intervalId = setInterval(toggleStars, randomTimeout);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timerRunning, toggleStars]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let inputsToPost = {...inputs, a: refA.current.value.toUpperCase(), b: refB.current.value.toUpperCase()}
    let result = ''
    let list = []
    generalService.GoshHelpPost(inputsToPost).then(r => {
      result = r !== undefined ? r.Gosh_Saying : ''
      if (!result) {
        setTimerRunning(false);
      } else {
        list = r.list
      }
    })

    setTimerRunning(true);
    const randomTimeout = Math.floor(Math.random() * 14501) + 1000;
    setTimeout(() => {
      setTimerRunning(false);
      setStarIndex(result)
      setInputs(inputs => ({...inputs, starIndex: result}));
      setDataItems(list)
    }, randomTimeout);
  }

  const handleChange = async (e) => {
    const {name, value} = e.target;
    setInputs(inputs => ({...inputs, [name]: value.toUpperCase()}));
  }

  const toEdit = async (id) => {
    let item = dataItems.find(x => x.id === id);
    let toInput = {
      id: item.id,
      title: item.title,
      a: item.a,
      b: item.b,
      starIndex: item.Gosh_Saying
    }
    refA.current.value = item.a
    refB.current.value = item.b
    if (item.Gosh_Saying !== 'draft') {
      setStarIndex(item.Gosh_Saying)
    }
    await setInputs(toInput)
  }

  const loadMoreHandler = () => {
    const nextPage = currentPage + 1
    fetchList(nextPage).then()
  }

  const onRefresh = () => {
    dispatch(alertActions.success('Refresh!'));
    fetchList(1).then()
    setInputs(initialInputs)
    setStarIndex('')
    refA.current.value = ''
    refB.current.value = ''
  }

  const saveTitleHandle = () => {
    let inputsToPost = {...inputs, a: refA.current.value.toUpperCase(), b: refB.current.value.toUpperCase(), type: 'update_title'}
    generalService.GoshHelpPost(inputsToPost).then(r => {
      if (r !== undefined) {
        setDataItems(r.list)
      }
    })
  }

  const saveAsDraftHandle = () => {
    let inputsToPost = {...inputs, a: refA.current.value.toUpperCase(), b: refB.current.value.toUpperCase(), type: 'draft'}
    generalService.GoshHelpPost(inputsToPost).then(r => {
      if (r !== undefined) {
        setDataItems(r.list)
      }
    })
  }

  return (
    <>
      <Modal
        className="alert-pricing-modal"
        size="lg"
        show={show}
        onHide={() => hideModalHandle()}
        aria-labelledby="alert-pricing-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="alert-pricing-modal"/>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-8 mx-auto px-3">
              <form
                id="fake-order-create-form"
                className="hc-form"
                onSubmit={handleSubmit}
              >
                <input
                  className="form-control cc hc-input-not-disable mt-3 mb-2"
                  name="title"
                  placeholder="Title"
                  onChange={handleChange}
                  autoComplete="off"
                  value={inputs.title}
                  style={{textAlign: "left"}}
                  type="text"/>
                {
                  inputs.starIndex !== 'draft' && inputs.id ? <span
                    className={`convertedprice-859-1505 dimmed`}
                    style={{
                      position: "absolute",
                      top: "1.5rem",
                      right: "1.75rem",
                      cursor: "pointer",
                      textDecoration: "underline"
                    }}
                    onClick={saveTitleHandle}
                  >
                    Save
                  </span> : <></>
                }


                <strong className={`dimmed`}>
                  A&nbsp;
                  {
                    starIndex !== '' && starIndex === 'a'
                      ? <span className={`start-a`}>⭐️</span>
                      : <></>
                  }
                </strong>
                <textarea
                  className="form-control analysis mb-2"
                  name="note"
                  ref={refA}
                  rows={6}
                  autoComplete="off"
                />

                <strong className={`dimmed`}>
                  B&nbsp;
                  {
                    starIndex !== '' && starIndex === 'b'
                      ? <span className={`start-b`}>⭐️</span>
                      : <></>
                  }
                </strong>
                <textarea
                  className="form-control analysis mb-2"
                  name="note"
                  ref={refB}
                  rows={6}
                  autoComplete="off"
                />

                <div className="row mx-0" style={{position: "relative"}}>
                  <button
                    className={`btn btn-block ${inputs.starIndex === '' || inputs.starIndex === 'draft' ? 'btn-primary' : ''} mt-3 mx-auto`}
                    type="submit"
                    onClick={handleSubmit}
                    style={{
                      fontSize: '1rem',
                      paddingTop: '6px',
                      paddingBottom: '6px'
                    }}
                    disabled={(inputs.starIndex && inputs.starIndex !== 'draft')}
                  >
                    Run
                  </button>
                  {
                    inputs.id === null || inputs.starIndex === 'draft' ? <span
                      className={`convertedprice-859-1505 dimmed`}
                      style={{
                        position: "absolute",
                        top: "3.5rem",
                        right: "0",
                        cursor: "pointer",
                        textDecoration: "underline"
                      }}
                      onClick={saveAsDraftHandle}
                    >
                      Save as draft
                    </span> : <></>
                  }
                </div>

              </form>
            </div>
            <div className="col-md-4 mx-auto">
              <Table striped bordered hover className={`mt-3`} style={{borderColor: "#282E3B"}}>
                <thead>
                <tr>
                  <th>Items<span
                    className={`monospace-font dimmed`}
                    style={{cursor: "pointer"}}
                    onClick={onRefresh}
                  >
                <i className="fa fa-sync-alt"
                   aria-hidden="true"
                   style={{
                     color: "#fff",
                     paddingLeft: "1%"
                   }}
                />
              </span></th>
                </tr>
                </thead>
                <tbody>
                {dataItems !== undefined ? Object.entries(dataItems).map(([key, value]) => (
                  <GoshHelpItem
                    key={key}
                    itemValue={value}
                    afterDelete={onRefresh}
                    setItemToEdit={toEdit}
                  />
                )) : <></>
                }
                {
                  currentPage !== lastPage ?
                  <div style={{textAlign: 'center', paddingBottom: '15px'}}>
                    <a onClick={loadMoreHandler}
                       style={{
                         'font-size': '24px',
                         'cursor': 'pointer'
                       }}>
                      ...</a>
                  </div>
                  : <></>
                }
                </tbody>
              </Table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export {GoshHelpModal};
