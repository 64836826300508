import React, {useState} from 'react';
import {Link,} from 'react-router-dom';
import {generalService} from "../../services";


function BingXUpdate() {
  const [isSummited, setIsSummited] = useState(false);
  const [inputs, setInputs] = useState('');
  const [summary, setSummary] = useState('')


  const handleSubmit = (e) => {
    e.preventDefault();
    generalService.marketStore(inputs).then(r => {
      setIsSummited(true)
      setSummary(r)
    })
    setInputs('');
  }

  const binanceHandleSubmit = (e) => {
    e.preventDefault();
    generalService.fetchBinanceData().then(r => {
      setIsSummited(true)
    })
  }

  const handleChange = async (e) => {
    let {value} = e.target;
    setInputs(value);
  }

  return <div className="container">
    <div className="row mb-3">
      <div className="col-md-6 mx-auto mt-5">
        <div className="text-center">
          <h3>MARKET</h3>
          <Link to={`/market/3`} className="monospace-font dimmed">Back</Link>
        </div>
      </div>
    </div>

    <div className="row">
      <div className="col-12">
        <form
          id="fake-order-create-form"
          className="hc-form mt-3"
          onSubmit={handleSubmit}>
          <textarea
            type="text"
            name="date"
            className="form-control mt-3 hc-input-not-disable"
            onChange={handleChange}
            style={{fontSize: '12px'}}
            value={inputs}
            placeholder="..." autoComplete="off"/>
        </form>
        <a href={`#`} className="monospace-font dimmed text-right ml-2"
           onClick={handleSubmit}
           style={{
             fontSize: '1rem',
             paddingTop: '6px',
             paddingBottom: '6px'
           }}>
          BingX Save
        </a>
        <br/>
        <a href={`#`} className="monospace-font dimmed text-right ml-2"
           onClick={binanceHandleSubmit}
           style={{
             fontSize: '1rem',
             paddingTop: '6px',
             paddingBottom: '6px'
           }}>
          Binance Fetch
        </a>
        <span style={{display: "inline-block"}} className={`ml-3`}>{isSummited === true ? <p>Saved!</p> : <></>}</span>
      </div>
    </div>

    <p>{summary}</p>
    <p>https://bingx.com/en-us/market/futures</p>
    <p>Futures - USDT Standard</p>
  </div>
}

export default BingXUpdate;
