import { allConstants } from '../../constants';

const initState = {}

export default function totalMarket(state = initState, action) {
  switch (action.type) {
    case allConstants.SET_TOTAL_MARKET:
      const symbolPair = action.data.symbol
      const price = action.data.total_market
      return {...state, [symbolPair]: price}
    default:
      return state
  }
}