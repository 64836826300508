import React, {useEffect, useState} from 'react';
import {generalService} from "../../services";

function AlertSwitching({type}) {
  const [switchingOn, setSwitchingOn] = useState(null)

  useEffect(() => {
    alertSwitchingStatusFetching()
  }, [])

  useEffect(() => {
    // alertSwitchingStatusFetching()
  }, [switchingOn])

  const alertSwitchingStatusFetching = () => {
    generalService.alertSwitchingStatus(type).then(res => {
      setSwitchingOn(res.data === 'on')
    })
  }

  const alertSwitchingHandle = () => {
    let switchTo = (switchingOn === true) ? 'off' : 'on'
    generalService.alertSwitching(switchTo, type).then(res => {
      setSwitchingOn(res.data === 'on')
    })
  }

  return <>
    {
      switchingOn != null ?
        <span
          onClick={alertSwitchingHandle}
          style={{
            color: switchingOn ? "rgba(46,122,208,0.73)" : "#aaaaaa",
            fontSize: switchingOn ? "1.25rem" : "1.1rem"
          }}
        >
          {
            type !== 'btc' ? type === 'bull' ? '⬆' : '⬇' : "\t฿"
          }
          <i
            className={`fa switching ${switchingOn ? 'fa-bell' : 'fa-bell-slash'}`}
            aria-hidden="true"
          />
        </span>
        : <></>
    }
  </>
}

export {AlertSwitching};
