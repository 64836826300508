import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {PrivateRoute} from './components';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import FakeOrderCreatePage from './pages/FakeOrderCreatePage';
import {TradeListPage} from "./pages/TradeListPage";
import {OrderListPage} from "./pages/OrderListPage";
import {generalActions, klineActions} from './redux';
import {CampaignListPage} from "./pages/CampaignListPage";
import {CampaignDetailPage} from "./pages/CampaignDetailPage";
import ChartPage from "./pages/ChartPage";
import FillHoldingPage from "./pages/FillHoldingPage";
import NonBinanceTradeInputPage from "./pages/NonBinanceTradeInputPage";
import {FiatPage} from "./pages/FiatPage";
import FiatCreatePage from "./pages/FiatCreatePage";
import {FuturesHome} from "./pages/FuturesPages";
import FuturesCreate from "./pages/FuturesPages/FuturesCreate";
import Futures2 from "./pages/FuturesPages/Futures2";
import BingXUpdate from "./pages/FuturesPages/BingXUpdate";
import Market from "./pages/FuturesPages/Market";
import LogoutPage from "./pages/LogoutPage";
import MarketVolumeAnalysis from "./pages/FuturesPages/MarketVolumeAnalysis";
import MarketEmaAnalysis from "./pages/FuturesPages/MarketEmaAnalysis";
import DiffChecker from "./pages/FuturesPages/DiffChecker";
import {MarketAlertPricing} from "./pages/FuturesPages/MarketAlertPricing";

function App() {
  const dispatch = useDispatch();
  const alert = useSelector(state => state.alert);
  const [alertVisible, setAlertVisible] = useState(false);
  const loggedIn = useSelector(state => state.authentication.loggedIn);
  const symbolPairs = useSelector(state => state.general.symbol_pairs);

  useEffect(() => {
    // Dark mode
    document.body.classList.add('dark')

    // Call trong đây để toàn app có đồ mà xài
    // dispatch(generalActions.getDashboardData());

  }, []);

  useEffect(() => {
    // Call trong đây để toàn app có đồ mà xài
    if (loggedIn) {
      dispatch(generalActions.getDashboardData());
    }
  }, [loggedIn]);

  useEffect(() => {
    webSocketFire()
  }, [symbolPairs])

  useEffect(() => {
    setAlertVisible(true)
    setTimeout(() => {
      setAlertVisible(false)
    }, 3000);
  }, [alert])

  const webSocketFire = () => {
    if (symbolPairs && symbolPairs.length > 0) {
      // eslint-disable-next-line array-callback-return
      dispatch(klineActions.initSetPrices(symbolPairs))
    }
  }

  return (
    <>
      {
        (alert.message && alertVisible) ?
          <div className={`alert hc-alert`}>{alert.message}</div>
          : ''
      }
      <Router>
        <Switch>
          <Route path="/login" component={LoginPage}/>
          <PrivateRoute exact path="/logout" component={LogoutPage}/>
          <PrivateRoute exact path="/" component={HomePage}/>
          <PrivateRoute exact path="/campaigns" component={CampaignListPage}/>
          <PrivateRoute path="/campaign/:symbol" component={CampaignDetailPage}/>
          <PrivateRoute path="/trades/:symbol" component={TradeListPage}/>
          <PrivateRoute path="/orders/:symbol" component={OrderListPage}/>
          <PrivateRoute exact path="/chart" component={ChartPage}/>
          <PrivateRoute exact path="/fake-order/create/:symbol" component={FakeOrderCreatePage}/>
          <PrivateRoute path="/fill-holding/create/:symbol" component={FillHoldingPage}/>
          <PrivateRoute exact path="/fiat" component={FiatPage}/>
          <PrivateRoute exact path="/fiat/create" component={FiatCreatePage}/>
          <PrivateRoute exact path="/non-binance/create" component={NonBinanceTradeInputPage}/>

          {/* Futures pages */}
          <PrivateRoute exact path="/futures" component={FuturesHome}/>
          <PrivateRoute exact path="/futures/create/:id?/:futureId?" component={FuturesCreate}/>

          {/* Market pages */}
          <PrivateRoute exact path="/bingX-update" component={BingXUpdate}/>
          <PrivateRoute exact path="/market/:type" component={Market}/>
          <PrivateRoute exact path="/volume-range-analysis" component={MarketVolumeAnalysis}/>
          <PrivateRoute exact path="/diff-checker" component={DiffChecker}/>
          <PrivateRoute exact path="/ema-analysis" component={MarketEmaAnalysis}/>
          <PrivateRoute exact path="/alert-pricing" component={MarketAlertPricing}/>

          <PrivateRoute exact path="/futures-2" component={Futures2}/>


          <Redirect from="*" to="/"/>
        </Switch>
      </Router>
    </>
  );
}

export default App;
