import React, {useEffect, useState} from 'react';
import ReactApexChart from "react-apexcharts";
import {generalService} from "../services";
import {Link} from "react-router-dom";

function ChartPage(props) {
  const areaOptions = {
      theme: {
        mode: 'dark',
        palette: 'palette1',
        monochrome: {
          enabled: false,
          color: '#00124a',
          shadeTo: 'light',
          shadeIntensity: 0.65
        },
      },
      background: '#f10952',
      chart: {
        id: 'area-datetime',
        type: 'full',
        height: 850,
        zoom: {
          autoScaleYaxis: true
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false
        },
        tickAmount: 300,
      },
      yaxis: {
        tooltip: {
          enabled: true
        }
      },
      tooltip: {
          x: {
            format: 'dd MMM yyyy HH:mm',
            style: {
              color: "#fff",
              background: '#775DD0'
            }
          }
      },
  }

  const candleStickOptions = {
    theme: {
      mode: 'dark',
      palette: 'palette1',
      monochrome: {
        enabled: false,
        color: '#00124a',
        shadeTo: 'light',
        shadeIntensity: 0.65
      },
    },
    background: '#f10952',
    chart: {
      // id: 'area-datetime',
      type: 'candlestick',
      height: 850,
      zoom: {
        autoScaleYaxis: true
      }
    },
    dataLabels: {
      enabled: false
    },
    plotOptions: {
      wick: {
        useFillColor: true,
      },
      candlestick: {
        colors: {
          upward: '#3C90EB',
          downward: '#DF7D46'
        }
      }
    },
    xaxis: {
      type: 'datetime'
    },
    yaxis: {
      tooltip: {
        enabled: true
      }
    }
  }

  const [chart, setChart] = useState({
      series: [],
      options: areaOptions,
      selection: 'td',
      ranges: {}
    })
  const [chartTime, setChartTime] = useState('5m')
  const [chartType, setChartType] = useState('mv_fiat') // mv_fiat
  // const [chartType, setChartType] = useState('binance_mv_fiat') // mv_fiat

  useEffect(() => {
    document.title = 'Summary Chart 🚀🚀';
    fetchList().then(r => console.log('🚃'))
  }, [chartTime, chartType]);

  const fetchList = async () => {
    await generalService.getChart(chartType, chartTime)
      .then(data => {
        const series = {
          name: (chartType === 'binance_mv_fiat') ? 'Binance MV' : 'TOTAL MV',
          data: data.series
        }
        let updateChart = {...chart, series: [series]}
        let updateChart1 = {...updateChart, ranges: data.ranges}
        let updateChart2 = {...updateChart1, options: (chartTime === '5m') ? areaOptions : candleStickOptions}
        setChart(updateChart2)
      });
  }

  return <div id="chart" className="container">
    <div className="d-flex justify-content-center">
        <Link to={'/'} className="mt-3 justify-content-center">
          <h3>DASHBOARD</h3>
        </Link>

    </div>
    <div id="hc-chart" className="mt-5 p-3">
      <div className={`mt-1 d-flex justify-content-center mb-3`}>
        <a className={`btn btn-sm ${chartType === 'binance_mv_fiat' ? 'btn-primary' : ''}`}
           onClick={() => setChartType('binance_mv_fiat')}
        >BINANCE MV</a>&nbsp;
        <a className={`btn btn-sm ${chartType === 'mv_fiat' ? 'btn-primary' : ''}`}
           onClick={() => setChartType('mv_fiat')}
        >TOTAL MV</a>
      </div>
      <div className="d-flex justify-content-center mb-2">
        <a href="javascript:void(0)" onClick={() => setChartTime('5m')}>5m</a>&nbsp;&nbsp;
        <a href="javascript:void(0)" onClick={() => setChartTime('1h')}>Hour</a>&nbsp;&nbsp;
        <a href="javascript:void(0)" onClick={() => setChartTime('d')}>Day</a>&nbsp;&nbsp;
        <a href="javascript:void(0)" onClick={() => setChartTime('all')}>All</a>&nbsp;&nbsp;
      </div>
      <ReactApexChart options={chart.options}
                  series={chart.series}
                  type={(chartTime === '5m') ? 'area' : 'candlestick'}
                  height={'80%'}
                  style={{
                    background: '#fff'
                  }}/>
    </div>
  </div>
}

export default ChartPage;
