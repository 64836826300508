import React, {useState, useEffect} from 'react';
import {Link, Redirect} from 'react-router-dom';
import NumberFormat from "react-number-format";


import {useParams} from "react-router";
import {generalService} from "../services";
import {dollarFormat, percentFormat, thousandFormat} from "../helpers";
import {useSelector} from "react-redux";

function FakeOrderCreatePage(props) {
  const {symbol} = useParams();
  const fakeOrderInput = useSelector(state => state.fakeOrderInput);
  const {available, total_purchase, realtime_price, total_sells} = fakeOrderInput
  const [inputs, setInputs] = useState({
    price: realtime_price,
    qty: '',
    origQty: '',
    percent: '',
  });
  const {price, qty, origQty, percent} = inputs;

  const [isPercentInputHide, setIsPercentInputHide] = useState(true);
  const [isSummited, setIsSummited] = useState(false);

  const [summary, setSummary] = useState({
    holding: available,
    totalSells: 0,
    marketValue: 0,
    netCost: 0,
  });
  const {holding, netCost, marketValue, totalSells} = summary

  useEffect(() => {
    document.title = 'Create F-Order 🚀🚀'
  }, [])

  useEffect(() => {
    let newInputs = {...inputs, origQty: price * qty}
    if (isPercentInputHide === true) {
      newInputs = {...newInputs, percent: qty / available * 100}
    }
    setInputs(newInputs);
  }, [price, qty])

  useEffect(() => {
    const thisHolding = available - qty
    const thisTotalSells = parseFloat(total_sells) + parseFloat(origQty)
    const thisMarketValue = realtime_price * thisHolding
    const thisNetCost = total_purchase - thisTotalSells

    setSummary({
      holding: thisHolding,
      totalSells: thisTotalSells,
      marketValue: thisMarketValue,
      netCost: thisNetCost,
    })
  }, [origQty])

  useEffect(() => {
    let newQty = parseFloat(available * percent) / 100
    let newInputs = {...inputs, qty: newQty}
    setInputs(newInputs);
  }, [percent])

  const handleChange = async (e) => {
    const {name, value} = e.target;
    setInputs(inputs => ({...inputs, [name]: value}));
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    generalService.saveFakeOrder(symbol, price, qty, origQty).then(r => setIsSummited(true))
  }

  const handlePercentBtns = (percentValue) => {
    setIsPercentInputHide(true)
    setInputs(inputs => ({...inputs, percent: percentValue}));
  }

  const handleCustomBtn = () => {
    setIsPercentInputHide(!isPercentInputHide)
    setInputs(inputs => ({...inputs, percent: ''}));
  }

  return isSummited || !fakeOrderInput.available ? <Redirect to={`/campaign/${symbol}`}/>
    : <section>
      <div className="d-flex-inline justify-content-center">
        <div className="container p-3">
          {/*<div className="col-12 fake-order-create-header">*/}
          <div className="col-md-6 mx-auto mt-5">
            <div className="text-center">
              <h3>F-ORDER - <Link to={`/campaign/${symbol}`}><strong>{symbol}</strong></Link></h3>
              <br/>
              <span className="monospace-font dimmed small">Available: {thousandFormat(holding)}</span>
              <br/>
              <span className="monospace-font dimmed small">Total Purchase: {dollarFormat(total_purchase)}</span>
              <br/>
              <span className="monospace-font dimmed small">Total Sell: {dollarFormat(totalSells)}</span>
              <br/>
              <span className="monospace-font dimmed small">
                {`Profit: ${dollarFormat(marketValue - netCost)}        
                (${percentFormat((marketValue - netCost) / total_purchase)})`}
              </span>
            </div>

            <form
              id="fake-order-create-form"
              className="hc-form mt-3"
              onSubmit={handleSubmit}>
              <NumberFormat
                className="form-control cc hc-input-not-disable"
                name="price"
                placeholder="Price"
                onChange={handleChange}
                autoComplete="off"
                value={price}
                decimalSeparator="."
                displayType="input"
                type="text"
                allowNegative={false}
                decimalScale={4}/>
              <NumberFormat
                className="form-control mt-3 hc-input-not-disable"
                name="qty"
                placeholder="Amount"
                onChange={handleChange}
                autoComplete="off"
                value={qty}
                decimalSeparator="."
                displayType="input"
                type="text"
                allowNegative={false}
                decimalScale={4}/>

              <div className="mt-1 hc-percent-btns">
                <a href="javascript:;"
                   className={`mx-2 ${percent === 25 ? 'active' : ''}`}
                   onClick={() => handlePercentBtns(25)}>
                  25%</a>
                <a href="javascript:;"
                   className={`mx-2 ${percent === 50 ? 'active' : ''}`}
                   onClick={() => handlePercentBtns(50)}>
                  50%</a>
                <a href="javascript:;"
                   className={`mx-2 ${percent === 100 ? 'active' : ''}`}
                   onClick={() => handlePercentBtns(100)}>
                  Max</a>
                <a href="javascript:;"
                   className="mx-2"
                   onClick={handleCustomBtn}
                >Custom</a>
                <div className={`hc-input-custom ${isPercentInputHide ? 'hide' : 'show'}`}>
                  <input type="number"
                         className={`form-control mt-3 hc-input-not-disable`}
                         name="percent"
                    // value={lessPercentDigit()}
                         value={percent}
                         onChange={handleChange}
                  />
                  <span className="hc-input-custom-content">%</span>
                </div>
              </div>
              <NumberFormat
                className="form-control mt-3"
                name="origQty"
                disabled={true}
                placeholder="Sell"
                onChange={handleChange}
                thousandsGroupStyle="thousand"
                value={origQty}
                prefix="$"
                decimalSeparator="."
                displayType="input"
                type="text"
                thousandSeparator={true}
                allowNegative={false}
                decimalScale={2}/>
              <button className="btn btn-block btn-primary mt-3"
                      type="button"
                      onClick={handleSubmit}
                      style={{
                        fontSize: '1rem',
                        paddingTop: '6px',
                        paddingBottom: '6px'
                      }}>
                Create
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
}

export default FakeOrderCreatePage;
