import React, {useState, useEffect} from 'react';
import {Link, Redirect} from 'react-router-dom';
import NumberFormat from "react-number-format";


import {useParams} from "react-router";
import {generalService} from "../services";
import {dollarFormat, percentFormat, thousandFormat} from "../helpers";
import {useSelector} from "react-redux";
import {generalActions} from "../redux";

function FillHoldingPage(props) {
  const {symbol} = useParams();
  const [isSummited, setIsSummited] = useState(false);
  const [list, setList] = useState([]);
  const [inputs, setInputs] = useState({
    newHolding: '',
  });
  const {newHolding} = inputs

  useEffect(() => {
    generalService.getFillHoldingList(symbol)
      .then(res => {
        setList(res.data)
      });
  }, [newHolding])

  const deleteFillHolding = (id) => {
    generalService.deleteFillHolding(id).then(r => {
      generalService.getFillHoldingList(symbol)
        .then(res => {
          setList(res.data)
        })
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    generalService.saveFillHolding(symbol, newHolding).then(r => setInputs(inputs => ({...inputs, newHolding: ''})))
  }

  const handleChange = async (e) => {
    const {name, value} = e.target;
    setInputs(inputs => ({...inputs, [name]: value}));
  }

  return isSummited ? <Redirect to={`/campaign/${symbol}`}/>
    : <section>
      <div className="col-md-6 mx-auto mt-5">
        <div className="text-center">
          <h3>FILL HOLDING - <Link to={`/campaign/${symbol}`}><strong>{symbol}</strong></Link></h3>
        </div>
      </div>

      <div className="d-flex-inline justify-content-center">
        <div className="container p-3">
          {/*<div className="col-12 fake-order-create-header">*/}
          <div className="col-md-6 mx-auto mt-5">
            <form
              id="fake-order-create-form"
              className="hc-form mt-3"
              onSubmit={handleSubmit}>
              <NumberFormat
                className="form-control cc hc-input-not-disable"
                name="newHolding"
                placeholder="New holding amount"
                onChange={handleChange}
                autoComplete="off"
                value={inputs.newHolding}
                decimalSeparator="."
                displayType="input"
                type="text"
                allowNegative={false}
                decimalScale={4}/>

              <button className="btn btn-block btn-primary mt-3"
                      type="button"
                      onClick={handleSubmit}
                      style={{
                        fontSize: '1rem',
                        paddingTop: '6px',
                        paddingBottom: '6px'
                      }}>
                FILL
              </button>
            </form>
          </div>
        </div>
      </div>

      <div className="col-md-6 mx-auto mt-5">
        <table className={`table`}>
          <tbody id="exchange-data">
          {
            list.map(item => {
              return <tr className="traded-pair-row" style={{lineHeight: '1.5', fontSize: '14px'}}>
                <td width={`33.33%`}><span className="price-859-1505 price small dimmed">{item.time}</span></td>
                <td className="d-sm-table-cell text-center"><span className="price-859-1505 price dimmed">{thousandFormat(item.qty)}</span></td>
                <td className="d-sm-table-cell text-center"><span className="price-859-1505 price down">
                  <a href="javascript:void(0)" onClick={() => deleteFillHolding(item.id)}>x</a>
                </span></td>
              </tr>
            })
          }

          </tbody>
        </table>
      </div>
    </section>
}

export default FillHoldingPage;