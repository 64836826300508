import React, {useEffect, useState} from 'react';
import {dollarFormat, donghoFormat} from "../../helpers";
import {generalService} from "../../services";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Link} from "react-router-dom";

function TableItem(props) {
  const {item, deleteFiat} = props

  return <tr className={`traded-pair-row ${item.is_cash_in ? '' : 'price down'}`} style={{lineHeight: 1.5, fontSize: '14px'}}>
    <td>
      <span className={`price-859-1505 price`}>{item.date}</span>
      {
        item.is_cash_in ?
          ''
          : <span className={`price-859-1505 price down`} style={{fontSize: '11px'}}>&nbsp;&nbsp;&nbsp;Cash Out</span>
      }
      <a href="javascript:void(0)" data-zone={item.id} onClick={() => deleteFiat(item.id)} className={`ml-2 ${item.id}`}>x</a>
      <br/>
      <span className="price-859-1505 price small dimmed">{item.note}</span>
    </td>
    <td className="d-sm-table-cell text-center">
      <span className={`price-859-1505 price`}>
        {donghoFormat(item.vnd_amount)}
        <br/>
        <span className="price-859-1505 price smaller">({donghoFormat(item.rate)})</span>
      </span>
    </td>
    <td className="d-sm-table-cell text-center">
      <span className="price-859-1505 price">{item.is_cash_in ? '+' : ''}{dollarFormat(item.usdt_amount)}</span>
    </td>
    <td className="d-sm-table-cell text-center">
      <span className={`price-859-1505 price`}>{dollarFormat(item.current_total_amount)}</span>
    </td>
  </tr>
}

function Table() {
  const [data, setData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)


  useEffect(() => {
    fetchList(currentPage).then(r => console.log('🚃'))
  }, [])

  const fetchList = async (page = currentPage) => {
    let query = `?page=${page}`
    generalService.getFiat(query)
      .then(res => {
        const newData = page !== currentPage ? [...data, ...res.data] : res.data;
        setData(newData)
        setCurrentPage(res.current_page)
        setLastPage(res.last_page)
      });
  }

  const loadMoreHandler = () => {
    const nextPage = currentPage + 1
    fetchList(nextPage).then(r =>
      console.log(`🚃 TradeList fetch - ${nextPage}`))
  }

  const renderListItem = (deleteFiat) => {
    return data.map(item => {
      return <TableItem key={item.id} item={item} data={item.value} deleteFiat={deleteFiat}/>
    })
  }

  const deleteFiat = (id) => {
    generalService.fiatDelete(id).then(r => {
      fetchList(1).then(r => console.log('🚃'))
    })
  }

  return <>
    <table className="table">
      <thead>
      <tr>
        <th>Date / Note</th>
        <th className="d-sm-table-cell text-center">VND</th>
        <th className="d-sm-table-cell text-center">USDT</th>
        <th className="d-sm-table-cell text-center">TOTAL</th>
      </tr>
      </thead>
      <tbody id="exchange-data">
      {renderListItem(deleteFiat)}
      </tbody>
    </table>
    {currentPage !== lastPage ?
      <div style={{textAlign: 'center', paddingBottom: '15px'}}>
        <a onClick={loadMoreHandler}
           style={{
             'font-size': '24px',
             'cursor': 'pointer'
           }}>
          ...</a>
      </div>
      : <></>
    }
  </>
}

export {Table};
