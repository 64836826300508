import thunk from 'redux-thunk'
import {routerMiddleware} from "connected-react-router";
import {createBrowserHistory} from 'history'
import {applyMiddleware, compose, createStore} from 'redux'
import createRootReducer from './rootReducer'

export const history = createBrowserHistory()

export default function configureStore(preloadedState) {
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  return createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancer(
      applyMiddleware(
        routerMiddleware(history),
        thunk
      ),
    ),
  )
}
