export {
  donghoFormat,
  dollarFormat,
  moreDigitsDollarFormat,
  thousandFormat,
  numberColor,
  percentFormat,
  currentDatetime,
  stringToFloat
}

function donghoFormat($value) {
  return Intl.NumberFormat('en-us', {
    currency: 'VND',
    style: 'currency',
  }).format($value);
}

function dollarFormat($value) {
  return Intl.NumberFormat('en-us', {
    currency: 'USD',
    style: 'currency',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }).format($value);
}

function oldMoreDigitsDollarFormat($value) {
  const result = Intl.NumberFormat('en-us', {
    currency: 'USD',
    style: 'currency',
    minimumFractionDigits: 4,
    maximumFractionDigits: 4
  }).format($value);

  if (result === '$0.0000') {
    return new Intl.NumberFormat('en-us', {
      currency: 'USD',
      style: 'currency',
      minimumFractionDigits: 6,
      maximumFractionDigits: 6
    }).format($value);
  }

  return result
}

function roundTo(n, place) {
  return +(Math.round(n + "e+" + place) + "e-" + place);
}

function moreDigitsDollarFormat($value, currency = true) {
  if (!$value) {
    return $value
  }

  if (Math.floor($value) === $value) {
    return $value;
  }

  let str = $value.toString()
  let decimal = '';

  if (str.indexOf(".") !== -1) {
    decimal = str.split(".")[1];
  }

  if (decimal) {
    let rmLeadingZeroes = parseInt(decimal)
    let decimalToRound = (decimal.toString().length - rmLeadingZeroes.toString().length) + 4;
    $value = roundTo($value, decimalToRound);
  }

  if (currency === true) {
    return new Intl.NumberFormat('en-US', {
      currency: 'USD',
      style: 'currency',
      minimumFractionDigits: 0,
      maximumFractionDigits: 20
    }).format(parseFloat($value))
  } else {
    return parseFloat($value)
  }

}

function thousandFormat($value) {
  return new Intl.NumberFormat('en-us', {
    style: 'decimal',
  }).format($value);
}

function percentFormat($value) {
  return new Intl.NumberFormat('en-us', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format($value);
}

function numberColor($value) {
  return $value > 0 ? 'up' : 'down'
}

function stringToFloat($value) {
  return parseFloat($value.replace(/,/g, ''));
}

function currentDatetime() {const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}
