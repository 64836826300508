import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {generalService} from "../../../services";
import {RsiDivergenceTable} from "./RsiDivergenceTable";
import {Dca, defaultFilter} from "./Dca";
import {Loading} from "react-loading-dot";
import {DateTimePicker} from "react-datetime-picker";

const initialInputs = {symbols: '', trend: 'bullish'}

const RsiModal = ({show, setShow}) => {
  const [inputs, setInputs] = useState(initialInputs);
  const [obOsInterval, setObOsInterval] = useState('h1');
  const [dataObOs, setDataObOs] = useState('');
  const [dataEmaHitStr, setDataEmaHitStr] = useState('');
  const [dataEmaHit, setDataEmaHit] = useState([]);
  const [dataRsiAnalysis, setDataRsiAnalysis] = useState({});
  const [tab, setTab] = useState('divergence');
  const [trend, setTrend] = useState('bullish');
  const [loading, setLoading] = useState(false)

  const hideModalHandle = async () => {
    document.title = 'Analysis Dashboard 🚀🚀'
    setShow(false)
  }

  useEffect(() => {
  }, [dataRsiAnalysis])

  const handleSubmit = (e) => {
    e.preventDefault();
    let postInputs = {}
    if (start !== null) {
      postInputs.start = generalService.formatDateTimePicker(start)
    }
    if (end !== null) {
      postInputs.end = generalService.formatDateTimePicker(end)
    }
    if (inputs.symbols !== '') {
      postInputs.symbols = inputs.symbols
    }
    postInputs.trend = trend
    setLoading(true)
    setDataRsiAnalysis({})
    if (tab === 'ob_os') {
      postInputs.interval = obOsInterval
      generalService.rsiAnalysisObOs(postInputs).then(r => {
        setDataObOs(r);
        setLoading(false)
      })
    } else if (tab === 'ema_hit') {
      generalService.rsiAnalysisEmaHit(postInputs).then(r => {
        setDataEmaHit(r.list);
        setDataEmaHitStr(r.symbols);
        setLoading(false)
      })
    } else {
      generalService.rsiAnalysis(postInputs).then(r => {
        setDataRsiAnalysis(r);
        setLoading(false)
      })
    }
  }

  const handleChange = async (e) => {
    const {name, value} = e.target;
    setInputs(inputs => ({...inputs, [name]: value.toUpperCase()}));
  }

  const renderTrendOptions = () => {
    const items = tab === 'divergence' ? ['bullish', 'bearish'] : ['rsi-30', 'rsi-70']
    return items.map(item => {
      return <strong
        className={`dimmed mx-2 pointer`}
        style={{
          textDecoration: trend === item ? 'underline' : 'none',
          color: "#2E7AD0"
        }}
        onClick={() => {
          setTrend(item)
        }}
      >
        {item.toUpperCase()}
      </strong>
    })
  }

  const currentDate = new Date();
  const [start, onStartChange] = useState(new Date(currentDate.getTime() - (3 * 60 * 60 * 1000)));
  const [end, onEndChange] = useState(currentDate);

  const [dcaData, setDcaData] = useState({});

  const filterDcaData = async (filter) => {
    console.log(filter)
    await generalService.dcaConfigGet(getStringAfterDCA(tab), filter.sort, filter.paginate).then(r => {
      console.log(r)

      setDcaData(r)
    })
  }

  const switchDcaConfig = async (type) => {
    await generalService.dcaConfigGet(type, defaultFilter.sort, defaultFilter.paginate).then(r => {
      setDcaData(r)
    })
    setTab('dca-' + type)
  }

  function getStringAfterDCA(input) {
    const prefix = "dca-";
    if (input.startsWith(prefix)) {
      return input.substring(prefix.length);
    } else {
      return input;
    }
  }

  return (
    <>
      {loading ? <Loading background={`rgb(225, 239, 250)`}/> : ''}
      <Modal
        className="alert-pricing-modal"
        size="lg"
        show={show}
        onHide={() => hideModalHandle()}
        aria-labelledby="alert-pricing-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="alert-pricing-modal"/>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-1">
            <div className="col-md-6 mx-auto text-center">
              <span
                className={`monospace-font dimmed`}
                style={{color: "#2E7AD0", cursor: "pointer"}}
                onClick={() => {
                  setTrend('bullish')
                  setTab('divergence')
                }}
              >
                Divergence
              </span>&nbsp;|&nbsp;
              <span
                className={`monospace-font dimmed`}
                style={{color: "#2E7AD0", cursor: "pointer"}}
                onClick={() => {
                  setTrend('rsi-30')
                  setTab('ema_hit')
                }}
              >
                Ema Hit
              </span>&nbsp;|&nbsp;
              <span
                className={`monospace-font dimmed`}
                style={{color: "#2E7AD0", cursor: "pointer"}}
                onClick={() => {
                  setTrend('rsi-30')
                  setTab('ob_os')
                }}
              >
                OB-OS
              </span>&nbsp;|&nbsp;
              <span
                className={`monospace-font dimmed`}
                style={{color: "#2E7AD0", cursor: "pointer", textDecoration: tab === 'dca-weekly' ? 'underline' : 'none',}}
                onClick={() => switchDcaConfig('weekly')}
              >
                DCA Weekly
              </span>&nbsp;|&nbsp;
              <span
                className={`monospace-font dimmed`}
                style={{color: "#2E7AD0", cursor: "pointer", textDecoration: tab === 'dca-monthly' ? 'underline' : 'none',}}
                onClick={() => switchDcaConfig('monthly')}
              >
                DCA Monthly
              </span>
              <br/>
              <br/>
            </div>
          </div>

          {tab === 'divergence' ?
            <div className="row">
              <div className="col-md-5 mx-auto px-3">
                <form
                  id="fake-order-create-form"
                  className="hc-form"
                  onSubmit={handleSubmit}
                >
                  <DateTimePicker
                    name="start"
                    onChange={onStartChange}
                    value={start}
                    minDetail="minute"
                    format={"dd-MM-y HH:mm"}
                  />

                  <DateTimePicker
                    name="start"
                    onChange={onEndChange}
                    value={end}
                    format={"dd-MM-y HH:mm"}
                    className={`mt-3`}
                  />
                  <div className="col-md-1 text-right pr-5" style={{position: "absolute", right: 0, 'cursor': 'pointer'}}>
                    <span
                      className={`text-right dimmed`}
                      onClick={() => {
                        onEndChange(new Date())
                      }}
                    >
                      Now
                    </span>
                  </div>

                  <div className="mt-2" style={{textAlign: "center"}}>
                    {renderTrendOptions()}
                  </div>

                  <textarea
                    className="form-control mt-4"
                    name="symbols"
                    onChange={handleChange}
                    autoComplete="off"
                    value={inputs.symbols}
                    style={{border: "1px solid #444"}}
                  />

                  <button
                    className="btn btn-block btn-primary mt-3 mx-auto"
                    type="submit"
                    onClick={handleSubmit}
                    style={{
                      fontSize: '1rem',
                      paddingTop: '6px',
                      paddingBottom: '6px'
                    }}
                  >
                    Run
                    {/*{loading ? <Loading background={`rgb(225, 239, 250)`}/> : ''}*/}
                  </button>

                </form>
              </div>
              <div className="col-md-7 mx-auto">
                <div className="row">
                  <RsiDivergenceTable dataRsiAnalysis={dataRsiAnalysis}/>
                </div>
              </div>
            </div> : <></>
          }

          {tab === 'ob_os' ?
            <div className="row">
              <div className="col-md-5 mx-auto px-3">
                <form
                  id="fake-order-create-form"
                  className="hc-form"
                  onSubmit={handleSubmit}
                >
                  <DateTimePicker
                    name="start"
                    onChange={onStartChange}
                    value={start}
                    minDetail="minute"
                    format={"dd-MM-y HH:mm"}
                  />

                  <DateTimePicker
                    name="start"
                    onChange={onEndChange}
                    value={end}
                    format={"dd-MM-y HH:mm"}
                    className={`mt-3`}
                  />
                  <div className="col-md-1 text-right pr-5" style={{position: "absolute", right: 0, 'cursor': 'pointer'}}>
                    <span
                      className={`text-right dimmed`}
                      onClick={() => {
                        onEndChange(new Date())
                      }}
                    >
                      Now
                    </span>
                  </div>

                  <div className="mt-2" style={{textAlign: "center"}}>
                    {renderTrendOptions()}
                  </div>

                  <div className="mt-3">
                    <select className="form-control text-center pr-0 mb-3"
                            name="interval"
                            onChange={e => setObOsInterval(e.target.value)}
                    >
                      <option value="d1">d1</option>
                      <option value="h4">h4</option>
                      <option value="h1" selected={true}>h1</option>
                    </select>
                  </div>

                  <textarea
                    className="form-control mt-3"
                    name="symbols"
                    onChange={handleChange}
                    autoComplete="off"
                    value={inputs.symbols}
                    style={{border: "1px solid #444"}}
                  />

                  <button
                    className="btn btn-block btn-primary mt-3 mx-auto"
                    type="submit"
                    onClick={handleSubmit}
                    style={{
                      fontSize: '1rem',
                      paddingTop: '6px',
                      paddingBottom: '6px'
                    }}
                  >
                    Run
                    {/*{loading ? <Loading background={`rgb(225, 239, 250)`}/> : ''}*/}
                  </button>

                </form>
              </div>
              <div className="col-md-7 mx-auto">
                <div className="row">
                  <p>RSI30: {dataObOs['rsi_30'] ? dataObOs['rsi_30'] : 'N/A'}</p>
                </div>
                <div className="row">
                  <p>RSI70: {dataObOs['rsi_70'] ? dataObOs['rsi_70'] : 'N/A'}</p>
                </div>
              </div>
            </div> : <></>
          }

          {tab === 'ema_hit' ?
            <div className="row">
              <div className="col-md-5 mx-auto px-3">
                <form
                  id="fake-order-create-form"
                  className="hc-form"
                  onSubmit={handleSubmit}
                >
                  <button
                    className="btn btn-block btn-primary mt-3 mx-auto"
                    type="submit"
                    onClick={handleSubmit}
                    style={{
                      fontSize: '1rem',
                      paddingTop: '6px',
                      paddingBottom: '6px'
                    }}
                  >
                    Run
                    {/*{loading ? <Loading background={`rgb(225, 239, 250)`}/> : ''}*/}
                  </button>

                </form>
              </div>
              <div className="col-md-7 mx-auto">
                <div className="row">
                  <p>{dataEmaHitStr}</p>
                  <table className="table col-12 text-sm-center table-ema-hit" style={{fontSize: "0.8rem"}}>
                    <thead>
                    <th width={`100`}>Symbol</th>
                    <th width={`100`}>Count</th>
                    <th width={`100`}><strong>D1.34</strong></th>
                    <th width={`100`}>89</th>
                    <th width={`100`}>200</th>
                    <th width={`100`}><strong>H4.34</strong></th>
                    <th width={`100`}>89</th>
                    <th width={`100`}>200</th>
                    <th width={`100`}><strong>H1.200</strong></th>
                    </thead>
                    <tbody>
                      {
                        dataEmaHit.length > 0 ? dataEmaHit.map((item, key) => (
                          <tr style={{background: key % 2 ? '#b7b7b70d' : 'inherit'}}>
                            <td className={`noborder`}>{item[0]}</td>
                            <td className={`noborder`}>{ item[1] }</td>
                            <td  className={`noborder`}><span style={{color: item[2] == 1 ? '#00ff00' : 'inherit'}}>◉</span></td>
                            <td  className={`noborder`}><span style={{color: item[3] == 1 ? '#00ff00' : 'inherit'}}>◉</span></td>
                            <td  className={`noborder`}><span style={{color: item[4] == 1 ? '#00ff00' : 'inherit'}}>◉</span></td>
                            <td  className={`noborder`}><span style={{color: item[5] == 1 ? '#00ff00' : 'inherit'}}>◉</span></td>
                            <td  className={`noborder`}><span style={{color: item[6] == 1 ? '#00ff00' : 'inherit'}}>◉</span></td>
                            <td  className={`noborder`}><span style={{color: item[7] == 1 ? '#00ff00' : 'inherit'}}>◉</span></td>
                            <td  className={`noborder`}><span style={{color: item[8] == 1 ? '#00ff00' : 'inherit'}}>◉</span></td>
                          </tr>
                        )) : <></>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div> : <></>
          }

          {
            tab === 'dca-weekly' ?
              <div className="row">
                <Dca type={'weekly'} data={dcaData} filterAction={filterDcaData} noteDone={() => setTab('divergence')}/>
              </div> : <></>
          }

          {
            tab === 'dca-monthly' ?
              <div className="row">
                <Dca type={'monthly'} data={dcaData} filterAction={filterDcaData} noteDone={() => setTab('divergence')}/>
              </div> : <></>
          }
        </Modal.Body>
      </Modal>
    </>
  );
}

export {RsiModal};
