import React, {useState, useEffect} from 'react';
import {Link, Redirect} from 'react-router-dom';
import NumberFormat from "react-number-format";
import {generalService} from "../services";
import {stringToFloat} from "../helpers";

function FiatCreatePage() {
  /*
     'vnd_amount' => 'bail|required|numeric',
     'usdt_amount' => 'bail|required|numeric',
     'rate' => 'bail|required|numeric',
     'is_cash_in' => 'bail|required|boolean',
     'note' => 'bail|r1equired',
  */
  const [isSummited, setIsSummited] = useState(false);
  const initInput = {vnd_amount: '', usdt_amount: '', rate: '', is_cash_in: true, note: '', date: generalService.getDateTime()}
  const [inputs, setInputs] = useState(initInput);
  const {vnd_amount, usdt_amount, rate, is_cash_in, note, date} = inputs;

  const handleSubmit = (e) => {
    e.preventDefault();
    generalService.fiatCreate({
      ...inputs,
      vnd_amount: stringToFloat(vnd_amount),
      usdt_amount: stringToFloat(usdt_amount)
    }).then(r => {
      setIsSummited(true)
    })
  }

  useEffect(() => {
    if (usdt_amount > 0) {
      let newInputs = {...inputs, rate: stringToFloat(vnd_amount) / stringToFloat(usdt_amount)}
      setInputs(newInputs);
    }
  }, [vnd_amount, usdt_amount])

  useEffect(() => {
    document.title = 'Input - Cash Flow 💰💰'
  }, [])

  useEffect(() => {
  }, [inputs])

  const handleChange = async (e) => {
    let {name, value} = e.target;

    if (name === 'is_cash_in') {
      value = JSON.parse(value)
    }

    setInputs(inputs => ({...inputs, [name]: value}));
  }

  return isSummited ? <Redirect to={`/fiat`}/>
    : <section>
      <div className="col-md-6 mx-auto mt-5">
        <div className="text-center">
          <h3>FIAT - CREATE</h3>
          <Link to={`/fiat`} className="monospace-font dimmed">Back</Link>
        </div>
      </div>

      <div className="d-flex-inline justify-content-center">
        <div className="container p-3">
          {/*<div className="col-12 fake-order-create-header">*/}
          <div className="col-md-6 mx-auto mt-5">
            <form
              id="fake-order-create-form"
              className="hc-form mt-3"
              onSubmit={handleSubmit}>

              <select className="coin-select bs-select form-control text-center mt-3 pr-0"
                      name="is_cash_in"
                      onChange={handleChange}
              >
                <option value={true}>Cash In</option>
                <option value={false}>Cash Out</option>
              </select>

              <NumberFormat
                className="form-control cc hc-input-not-disable mt-3"
                name="vnd_amount"
                placeholder="VND"
                onChange={handleChange}
                autoComplete="off"
                value={vnd_amount}
                thousandSeparator=","
                decimalSeparator="."
                displayType="input"
                type="text"
                allowNegative={false}
                decimalScale={4}/>

              <NumberFormat
                className="form-control cc hc-input-not-disable mt-3"
                name="usdt_amount"
                placeholder="USDT"
                onChange={handleChange}
                autoComplete="off"
                value={inputs.total}
                thousandSeparator=","
                decimalSeparator="."
                displayType="input"
                type="text"
                allowNegative={false}
                decimalScale={4}/>

              <NumberFormat
                className="form-control mt-3"
                name="rate"
                disabled={true}
                placeholder="Rate"
                onChange={handleChange}
                thousandsGroupStyle="thousand"
                value={rate}
                decimalSeparator="."
                displayType="input"
                type="text"
                thousandSeparator={true}
                allowNegative={false}
                decimalScale={2}/>

              <input
                type="text"
                name="note"
                className="form-control mt-3 hc-input-not-disable"
                onChange={handleChange}
                placeholder="Note..." autoComplete="off"/>

              <input
                type="text"
                name="date"
                className="form-control mt-3 hc-input-not-disable"
                onChange={handleChange}
                value={date}
                placeholder="Note..." autoComplete="off"/>

              <button className="btn btn-block btn-primary mt-3"
                      type="button"
                      onClick={handleSubmit}
                      style={{
                        fontSize: '1rem',
                        paddingTop: '6px',
                        paddingBottom: '6px'
                      }}
              >
                SAVE
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
}

export default FiatCreatePage;
