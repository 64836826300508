import React from 'react';
import {Link} from "react-router-dom";
function Header(props) {
  return <div>
    <div className="row">
      <div className="col-8 mx-auto">
        <div className="row mb-3">
          <div className="col-6">
            <div className="row">
              <h1 className="d-none d-sm-block p-2">{props.title}</h1>
            </div>
          </div>
          <div className="col-6 text-right">
            <div className="d-none d-sm-block">
              <Link to={`/`} className="monospace-font dimmed small">HOME</Link>
              <span> |  </span>
              <Link to={`/orders/${props.symbol ?? 'all'}`} className="monospace-font dimmed small">ORDER LIST</Link>
              <span> |  </span>
              <Link to={`/trades/${props.symbol ?? 'all'}`} className="monospace-font dimmed small">TRADE LIST</Link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="row d-block d-sm-none p-3 hc-shadow">
      <h3 className="mt-2">
        {props.title}
      </h3>

      <Link to={`/`} className="monospace-font dimmed">HOME</Link>
      <span> | </span>
      <Link to={`/orders/${props.symbol ?? 'all'}`} className="monospace-font dimmed">ORDER LIST</Link>
      <span> | </span>
      <Link to={`/trades/${props.symbol ?? 'all'}`} className="monospace-font dimmed">TRADE LIST</Link>
    </div>
  </div>
}

export {Header};