import React, {useEffect, useState} from 'react';
import {dollarFormat, moreDigitsDollarFormat, percentFormat, thousandFormat} from "../../helpers";
import {useParams} from "react-router";
import {generalService} from "../../services";

function CampaignTradeItem(props) {
  const {item} = props
  const {isBuyer, time, qty, quoteQty, price} = item

  return <tr className="traded-pair-row"
             style={{lineHeight: 1.5, fontSize: '14px'}}>
    <td>
      <span className="price-859-1505 price small dimmed">{time}</span>
    </td>

    <td className="d-sm-table-cell text-center">
      <span className={`price-859-1505 price ${isBuyer === 1 ? 'up' : 'down'}`}>
        {isBuyer ? 'BUY' : 'SELL'}
      </span>
    </td>

    <td className="d-sm-table-cell text-center">
      <span className="price-859-1505 price dimmed">{moreDigitsDollarFormat(price)}</span>
    </td>

    <td className="d-sm-table-cell text-center">
      <span className="price-859-1505 price dimmed">{thousandFormat(qty)}</span>
    </td>

    <td className="text-center">
      <span className="price-859-1505 price dimmed">{dollarFormat(quoteQty)}</span>
    </td>
  </tr>
}

function CampaignTable() {
  const {symbol} = useParams();
  const [list, setList] = useState([])
  const [summary, setSummary] = useState([])
  const {total_purchases, total_sells, total_profit, total_profit_percent} = summary

  useEffect(() => {
    generalService.getCampaignDetail(symbol)
      .then(data => {
        setSummary(data.summary ?? {})
        setList(data.list)
      })
  }, [])

  const renderCampaignTradeItem = (trades) => {
    console.log(trades)
    if (!Array.isArray(trades)) {
      trades = Object.entries(trades).map(([key, value]) => ( value));
    }

    return trades.map(item => {
      return <CampaignTradeItem key={item.id} item={item}/>
    })
  }

  const renderCampaignList = () => {
    return list.map((item, index) => {
      let {total_purchases, total_sells, profit_percent, trades, profit} = item

      return <table className="table" key={index}>
        <thead>
        <tr>
          <th className="d-sm-table-cell text-left"># {index + 1}</th>
          <th className="d-sm-table-cell text-center"> </th>
          <th className="d-sm-table-cell text-center">Total purchase: {dollarFormat(total_purchases)}</th>
          <th className="d-sm-table-cell text-center">Total sell: {dollarFormat(total_sells)}</th>
          <th className="d-sm-table-cell text-center">Profit: {dollarFormat(profit)} ({percentFormat(profit_percent)})</th>
        </tr>
        <tr>
          <th className="d-sm-table-cell text-left">Time</th>
          <th className="d-sm-table-cell text-center">Side</th>
          <th className="d-sm-table-cell text-center">Price</th>
          <th className="d-sm-table-cell text-center">Qty</th>
          <th className="d-sm-table-cell text-center">Total</th>
        </tr>
        </thead>
        <tbody id="exchange-data">{renderCampaignTradeItem(trades)}</tbody>
      </table>
    })
  }

  return (summary && Object.keys(summary).length !== 0) ? <>
    <table className="table mt-5">
      <thead>
      <tr>
        <th className="d-sm-table-cell text-left"
            style={{fontSize: '14px', lineHeight: '22px'}}>
          Total purchase: {dollarFormat(total_purchases)} <br/>
          Total sell: {dollarFormat(total_sells)} <br/>
          {`Profit: ${dollarFormat(total_profit)} (${percentFormat(total_profit_percent)})`} <br/>
        </th>
      </tr>
      </thead>
    </table>
    {renderCampaignList()}
  </> : <></>
}

export {CampaignTable};
