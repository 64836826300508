import React, {useState, useEffect} from 'react';
import {generalService} from "../../../services";
import {moreDigitsDollarFormat} from "../../../helpers";

function AlertPricingItem({key, itemValue, afterDelete, setItemToEdit, setPauseChanged}) {
  const [isPause, setIsPause] = useState(false);
  const [fireAt, setFireAt] = useState('');

  useEffect(() => {
    setIsPause(itemValue.pause)
    setFireAt(itemValue.alerted_at_formatted)
  }, [itemValue])

  const handleDelete = (e) => {
    e.preventDefault();
    generalService.alertPricingDelete({id: itemValue.id, type: 'delete'}).then(afterDelete)
  }

  const handleChangePauseStatus = (e) => {
    e.preventDefault();
    generalService.alertPricingDelete({id: itemValue.id, type: 'pause_change'})
      .then(r => {
        // setIsPause(!isPause)
        setPauseChanged()
      })
  }

  const toEdit = async (id) => {
    setItemToEdit(id)
  }

  return <tr key={key}>
    <td style={{position: "relative", cursor: "default"}}>
      <p className={`small mb-1`}>
            <span
              style={{color: isPause ? '' : '#00ff00', cursor: "pointer"}}
              onClick={() => toEdit(itemValue.id)}
            >
              ◉
            </span>
        &nbsp;&nbsp;
        <strong
          onClick={() => toEdit(itemValue.id)}
          style={{cursor: "pointer"}}
        >
          {itemValue.symbol}
          {
            itemValue.close_at_frame !== null && itemValue.close_at_frame !== 'hit'
              ? <span style={{fontSize: "10px"}}>[{itemValue.close_at_frame.toUpperCase()}]</span>
              : ''
          }
        </strong> at <strong>{moreDigitsDollarFormat(itemValue.price, false)}</strong>&nbsp;
        <span className={`dimmed price small`}>
              ({itemValue.created_at_formatted})
          {fireAt && isPause ? ' Fired at ' + fireAt : ''}
            </span>
      </p>
      {
        itemValue.note ? <>
              <span className={`price small`}>
                <span style={{opacity: "0"}}>◉</span>
                &nbsp;&nbsp;
                {itemValue.note}
              </span>
          <br/>
        </> : <></>
      }

      <div
        style={{
          position: "absolute",
          top: 0, right: 5,
          paddingTop: "0.5rem",
          width: "3rem"
        }}
      >
            <span
              className="btn-default m-1"
              onClick={handleDelete}
            >
              <i className={`fa fa-trash`}
                 aria-hidden="true"
                 style={{
                   cursor: "pointer",
                   fontSize: "80%",
                   paddingLeft: "5%"
                 }}
              />
            </span>
        <span
          className="btn-default m-1"
          onClick={handleChangePauseStatus}
        >
              <i className={`fa ${isPause ? `fa-play` : 'fa-pause'}`}
                 aria-hidden="true"
                 style={{
                   color: isPause ? '#2E7AD0' : '#fff',
                   cursor: "pointer",
                   paddingLeft: "5%",
                   fontSize: "90%"
                 }}
              />
            </span>
      </div>
    </td>
  </tr>
}

export {AlertPricingItem};
