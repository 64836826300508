import React, {useEffect, useState} from 'react';
import {dollarFormat, moreDigitsDollarFormat, numberColor, percentFormat, stringToFloat, thousandFormat} from "../../helpers";
import {generalService} from "../../services";
import {useSelector} from "react-redux";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Link} from "react-router-dom";

function TableItem(props) {
  const {item, realtime} = props
  const [profitPercent, setProfitPercent] = useState(0)

  useEffect(() => {
    let percent = (realtime - item.price) / item.price
    setProfitPercent(percent)
  }, [])

  return <tr className="traded-pair-row"
             style={{lineHeight: 1.5, fontSize: '14px'}}>
    <td>
      <Link to={`/campaign/${item.symbol}`}><strong>{item.symbol}</strong> </Link>
      <br/>
      <span className="price-859-1505 price small dimmed">{item.time}</span>
    </td>
    <td className="d-sm-table-cell text-center">
      <span className={`price-859-1505 price ${item.side === 'BUY' ? 'up' : 'down'}`}>{item.side}</span>
    </td>
    <td className="d-sm-table-cell text-center">
      <span className="price-859-1505 price dimmed">{thousandFormat(item.qty)}</span>
      <br/>
      <span className="price-859-1505 price dimmed">{moreDigitsDollarFormat(item.price)}</span>
      {
        profitPercent ?
          <><span className={`price-859-1505 price ${numberColor(profitPercent)}`}>&nbsp;({percentFormat(profitPercent)})</span></>
          : <></>
      }
    </td>
    <td className="text-center">
      <span className="price-859-1505 price dimmed">{dollarFormat(item.quoteQty)}</span>
    </td>
  </tr>
}

function Table(props) {
  const kline = useSelector(state => state.kline);
  const [data, setData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)
  const [filterShow, setFilterShow] = useState(false)
  const [inputs, setInputs] = useState({
    from: generalService.getDate(-30) + ' 00:00',
    to: generalService.getDate() + ' 23:59',
  });

  useEffect(() => {
    fetchList().then(r => console.log('🚃'))
  }, [props.sortBy]);

  useEffect(() => {
    renderListItem()
  }, [data, kline])

  const fetchList = async (page = currentPage) => {
    let query = props.symbol === 'all' ? '' : '/' + props.symbol
    query += `?page=${page}`
    query += `&side=${props.sortBy}`
    if (filterShow) {
      query += `&search_from=${inputs.from}&search_to=${inputs.to}`
    }
    query += '&XDEBUG_SESSION_START=PHPSTORM'
    generalService.getTradeList(query)
      .then(res => {
        const newData = page !== currentPage ? [...data, ...res.data] : res.data;
        setData(newData)
        if (filterShow) {
          setCurrentPage(1)
          setLastPage(1)
        } else {
          setCurrentPage(res.current_page)
          setLastPage(res.last_page)
        }
      });
  }

  const loadMoreHandler = () => {
    const nextPage = currentPage + 1
    fetchList(nextPage).then(r =>
      console.log(`🚃 TradeList fetch - ${nextPage}`))
  }

  const renderListItem = () => {
    return data.map(item => {
      const index = item.symbol
      let realtimePrice = 0

        realtimePrice = kline[index]

      return <TableItem key={item.tradeId} item={item} data={item.value} realtime={realtimePrice}/>
    })
  }

  const handleChange = async (e) => {
    let {name, value} = e.target;
    setInputs(inputs => ({...inputs, [name]: value}));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchList().then(r => console.log('🚃'))
  }


  return <>
    {filterShow ?
      <div className="row">
        <div className="col-sm-6 col-xl-3 mx-auto mb-3">
          <input
            type="text"
            name="from"
            className="form-control mt-3 hc-input-not-disable"
            onChange={handleChange}
            value={inputs.from}
            placeholder="Note..." autoComplete="off"/>
          <input
            type="text"
            name="to"
            className="form-control mt-3 hc-input-not-disable"
            onChange={handleChange}
            value={inputs.to}
            placeholder="Note..." autoComplete="off"/>
          <button className="btn btn-block btn-primary mt-3"
                  type="button"
                  onClick={handleSubmit}
                  style={{
                    fontSize: '1rem',
                    paddingTop: '6px',
                    paddingBottom: '6px'
                  }}
          >Search
          </button>
        </div>
      </div>
      : <></>
    }

    <table className="table">
      <thead>
      <tr>
        <th>
          <span>Symbol &lt;&gt; / Date</span>
          <a href="javascript:void(0)"
            className="ml-2"
            type=" button"
            onClick={() => setFilterShow(!filterShow)}
          >Filter</a>
        </th>
        <th className="d-sm-table-cell text-center">Side</th>
        <th className="d-sm-table-cell text-center">Info</th>
        <th className="d-sm-table-cell text-center">Total</th>
      </tr>
      </thead>
      <tbody id="exchange-data">
        {renderListItem()}
      </tbody>
    </table>
    {currentPage !== lastPage ?
      <div style={{textAlign: 'center', paddingBottom: '15px'}}>
        <a onClick={loadMoreHandler}
           style={{
             'font-size': '24px',
             'cursor': 'pointer'
           }}>
          ...</a>
      </div>
      : <></>
    }
  </>
}

export {Table};
