import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {generalService} from "../../services";
import {useParams} from "react-router";
// eslint-disable-next-line no-unused-vars
import {JsonToTable} from "react-json-to-table";
import {alertActions} from "../../redux";
import {useDispatch, useSelector} from "react-redux";
import {MarketNoteModal} from "./components/MarketNoteModal";

import ImageViewer from "react-simple-image-viewer";
import {allConstants} from "../../constants";
import {AlertSwitching} from "./AlertSwitching";


function Market() {
  const dispatch = useDispatch();
  const imageViewer = useSelector(state => state.imageViewer);

  const {type} = useParams();
  const [data, setData] = useState([]);
  const [types, setTypes] = useState([]);
  const [typeBreaks, setTypeBreaks] = useState([]);
  const [lastUpdated, setLastUpdated] = useState([]);
  const [totalBingX, setTotalBingX] = useState(0);
  const [priorities, setPriorities] = useState({});
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState([]);

  useEffect(() => {
    document.title = 'Market Data 🚀🚀'
  }, [type])

  useEffect(() => {
    fetchList().then(r => console.log('🚃'))
  }, [type])

  useEffect(() => {
    fetchList().then(r => console.log('🚃'))
  }, [show])

  const fetchList = async () => {
    await generalService.getMarketData(type)
      .then(res => {
        setData(res.data)
        setTypes(res.types)
        setTypeBreaks(res.type_breaks)
        setLastUpdated(res.last_updated)
        setTotalBingX(res.total_bingX)
        setPriorities(res.priorities)
      });
  }

  const binanceHandleSubmit = (e) => {
    e.preventDefault();
    generalService.fetchBinanceData().then(r => {
      dispatch(alertActions.success('Fetched!'));
      fetchList().then(r => console.log('🚃'))
    })
  }

  const onSymbolNameClick = (id) => {
    generalService.getMarketNotes(id).then(r => {
      setModalData(r.data)
    })
    setShow(true)
  }

  const tableList = () => {
    let res = <></>
    if (data.length > 0) {

      res = data.map((row) => {
        let rowArr = Object.entries(row).map(([key, value]) => value);
        let find = Array.isArray(priorities) ? priorities.find(x => x.symbol === rowArr[1]) : 0

        let stars = ''
        if (find) {
          stars = find.note_priority > 0 ?
            Array.from(Array(find.note_priority).keys()).map(item => {
              return <span style={{fontSize: '10px'}}>⭐️</span>
            })
            : <></>
        }

        return <tr>
          <td onClick={() => onSymbolNameClick(rowArr[0])}>
            {rowArr[1]}
            &nbsp;
            {stars}
          </td>
          <td>{rowArr[2]}</td>
          <td>{rowArr[3]}</td>
          <td>{rowArr[4]}</td>
          <td>{rowArr[5]}</td>
          <td>{rowArr[6]}</td>
        </tr>
      })
    }

    return res
  }

  const closeImageViewer = () => {
    dispatch({
      type: allConstants.SET_IMAGE_VIEWS,
      data: {
        'isViewerOpen': false,
        'filename': []
      }
    })
  }

  return <div className="container">
    <div className="row mb-3">
      <div className="col-md-12 mx-auto mt-5 mb-3">
        <div className="text-center">
          <h3>
            <AlertSwitching/>&nbsp;
            MARKET
            <i style={{fontSize: '12px'}}> ({totalBingX} symbols - {lastUpdated})</i>
          </h3>
          <Link to={`/`} className="monospace-font dimmed px-2">Back |</Link>
          <Link to={`/bingX-update`} className="monospace-font dimmed px-2">BingX Update |</Link>
          <a href={`#`} className="monospace-font dimmed text-right ml-2"
             onClick={binanceHandleSubmit}
             style={{
               paddingTop: '6px',
               paddingBottom: '6px'
             }}>
            Binance Update |
          </a>
          <Link to={`/volume-range-analysis`} className="monospace-font dimmed px-2">Volume Analysis |</Link>
          <Link to={`/ema-analysis`} className="monospace-font dimmed px-2">EMA</Link>
        </div>
      </div>
    </div>

    <div className="row">
      <ul className={`market-types`}>
        {
          types.map((item, key) => {
              let actualKey = key + 1
              return <>
                <Link to={`/market/${key + 1}`}
                      style={{fontSize: '12px', display: 'inline-block'}}
                      className={`monospace-font dimmed market-type px-3 ${parseInt(type) === actualKey ? 'active' : ''}`}>{item}
                </Link>
                {typeBreaks.includes(actualKey) ? <br/> : ''}
              </>
            }
          )
        }
      </ul>

      <div className="col-12 mt-3 market-data-table">
        <div className="json-to-table">
          <table>
            <tbody>
            <tr>
              <td colSpan="0">Symbol</td>
              <td colSpan="0">Change</td>
              <td colSpan="0">Vol/Cap</td>
              <td colSpan="0">Volume</td>
              <td colSpan="0">Market Cap</td>
              <td colSpan="0">Rank</td>
            </tr>
            {tableList()}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <MarketNoteModal show={show} setShow={setShow} modalData={modalData}/>

    {
      imageViewer.isViewerOpen && (
        <div className={`image-view-in-market`} style={{position: 'relative', zIndex: 9999}}>
          <ImageViewer
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              width: '100vh',
              height: '100vh'
            }}
            src={imageViewer.filename}
            currentIndex={0}
            onClose={closeImageViewer}
            disableScroll={true}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)"
            }}
            closeOnClickOutside={true}
          />
        </div>
      )
    }
  </div>
}

export default Market;
