import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {generalService} from "../../services";
import {Loading} from "react-loading-dot";

function DiffChecker() {
  const [inputs, setInputs] = useState({left: '', right: ''});
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true)
    generalService.diffChecker(inputs).then(r => {
      setData(r)
      setLoading(false)
    })
  }

  useEffect(() => {
  }, [data])

  useEffect(() => {
    document.title = 'Diff - Analysis Dashboard 🚀🚀'
  }, [data])


  const handleChange = async (e) => {
    const {name, value} = e.target;
    setInputs(inputs => ({...inputs, [name]: value}));
  }

  const dataTable = () => {
    return (
      <div dangerouslySetInnerHTML={{__html: data}}/>
    );
  }


  return <section>
    {loading ? <Loading background={`rgb(225, 239, 250)`}/> : ''}
    <div className="col-md-6 mx-auto mt-5">
      <div className="text-center">
        <h3>DIFF CHECKER</h3>
        <Link to={`/`} className="monospace-font dimmed">HOME&nbsp;|&nbsp;</Link>
        <Link to={`/ema-analysis`} className="monospace-font dimmed">BACK</Link>
      </div>
    </div>

    <div className="d-flex-inline justify-content-center">
      <div className="fluid-container p-3">
        <div className="row">
          <div className="col-md-12 mx-auto">
            <form
              id="fake-order-create-form"
              className="hc-form mt-3"
              onSubmit={handleSubmit}
            >
              <div className="d-flex justify-content-between p-3">
               <textarea
                 name="left"
                 className="form-control mt-3 small mx-2"
                 rows={5}
                 style={{
                   backgroundColor: "#2220",
                   border: "1px solid #626b7f38",
                   color: "#626B7F",
                   width: "50%"
                 }}
                 onChange={handleChange}
                 value={inputs.left}
               />
                <textarea
                  name="right"
                  className="form-control mt-3 small mx-2"
                  rows={5}
                  style={{
                    backgroundColor: "#2220",
                    border: "1px solid #626b7f38",
                    color: "#626B7F",
                    width: "50%"
                  }}
                  onChange={handleChange}
                  value={inputs.right}
                />
              </div>

              <button
                className="btn btn-block btn-primary mt-3 text-center"
                type="submit"
                onClick={handleSubmit}
                style={{
                  fontSize: '1rem',
                  paddingTop: '6px',
                  paddingBottom: '6px',
                  width: '20%',
                  margin: '0 auto',
                }}
              >
                Check
              </button>
            </form>
          </div>
        </div>

        <div className="row px-3" style={{backgroundColor: "#1D2330"}}>
          <div className="col-md-12 mx-auto mt-5 px-4">
            {dataTable()}
          </div>
        </div>
      </div>
    </div>

  </section>
}

export default DiffChecker;
