import React, {useEffect, useState} from 'react';
import {dollarFormat, moreDigitsDollarFormat, percentFormat, thousandFormat} from "../../helpers";
import {useDispatch, useSelector} from "react-redux";
import {klineActions} from "../../redux";
import {Link} from "react-router-dom";
import {LAYOUT_DESKTOP, LAYOUT_MOBILE} from "../../constants";

function TableItem(props) {
  const {item, realtime, toGo} = props
  const {symbol, side, time, quoteQty, qty, price} = item

  return <tr className="traded-pair-row" style={{lineHeight: 1.5, fontSize: '14px'}}>
    <td>
      <Link to={`/campaign/${symbol}`}><strong>{symbol}</strong></Link>
      <br/>
      <span className="price-859-1505 price small dimmed">{time}</span>
    </td>
    <td className="d-sm-table-cell text-center">
      <span className={`price-859-1505 price ${side === 'BUY' ? 'up' : 'down'}`}>{side}</span>
    </td>
    <td className="d-sm-table-cell text-center">
      <span className="price-859-1505 price dimmed">{dollarFormat(quoteQty)}</span>
      <br/>
      <span className="price-859-1505 price dimmed">{thousandFormat(qty)}</span>
    </td>
    <td className={`text-center ${LAYOUT_DESKTOP}`}>
      <span className="price-859-1505 price dimmed">{moreDigitsDollarFormat(realtime)} -> {moreDigitsDollarFormat(price)}</span>
      <br/>
      <span className="price-859-1505 price dimmed">{percentFormat(toGo)}</span>
    </td>
    <td className={`text-center ${LAYOUT_MOBILE}`}>
      <span className="price-859-1505 price dimmed">{moreDigitsDollarFormat(realtime)}</span>
      <br/>
      <span className="price-859-1505 price dimmed">{moreDigitsDollarFormat(price)}</span>
      <br/>
      <span className="price-859-1505 price dimmed">({percentFormat(toGo)})</span>
    </td>
  </tr>
}

function Table(props) {
  const kline = useSelector(state => state.kline);
  // const [thisKline, setThisKline] = useState([]);

  const renderListItem = () => {
    if (!props.list || !props.orderBy) {
      return
    }

    if ((kline && Object.keys(kline).length === 0)) {
      return
    }

    let thisKline = kline
    let list = props.list.map(item =>{
      let realtimePrice = 0
      const index = item.symbol

      if (thisKline[index]) {
        realtimePrice = thisKline[index]
      } else {
        klineActions.setPriceInComponent(index).then(r => {
          const {symbol, price} = r
          thisKline = {...thisKline, [symbol]: price}
        })
      }

      item.realtime = realtimePrice;
      item.to_go = Math.abs(item.price - realtimePrice) / realtimePrice;

      return item;
    })

    if (props.orderBy === 'to_go') {
      list = list.sort((a, b) => b.to_go < a.to_go ? 1 : -1);
    } else if(props.orderBy === 'side') {
      list = list.sort((a, b) => a.side.localeCompare(b.side) || b.time - a.time );
    } else if(props.orderBy === 'symbol') {
      list = list.sort((a, b) => a.symbol.localeCompare(b.symbol) || b.time - a.time );
    } else if(props.orderBy === 'time'){
      list = list.sort((a, b) => b.time > a.time ? 1 : -1);
    } else {
      list = list.sort((a, b) => b.time > a.time ? 1 : -1);
    }

    if (props.sortBy !== true) {
      list = list.filter(item => item.isFake === 0);
    }

    return list.map(item => {
      return <TableItem key={item.orderId}
                        item={item}
                        realtime={item.realtime}
                        toGo={item.to_go}/>
    })
  }

  return <table className="table">
    <thead>
    <tr>
      <th>Symbol &lt;&gt; / Date</th>
      <th className="d-sm-table-cell text-center">Side</th>
      <th className="d-sm-table-cell text-center">Order Info</th>
      <th className="d-sm-table-cell text-center">To Go &lt;&gt;</th>
    </tr>
    </thead>
    <tbody id="exchange-data">
    {renderListItem()}
    </tbody>
  </table>
}

export {Table};