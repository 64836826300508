import React, {useEffect, useState} from 'react';
import {OrphanTable, Header} from "../CampaignDetailPage";
import {useParams} from "react-router";
import {CampaignTable} from "./CampaignTable";
import {useDispatch, useSelector} from "react-redux";
import {dollarFormat, moreDigitsDollarFormat} from "../../helpers";
import {generalService} from "../../services";

function CampaignDetailPage() {
  const {symbol} = useParams();
  const dispatch = useDispatch();
  const kline = useSelector(state => state.kline);
  let [realtimePrice, setRealtimePrice] = useState(0)
  let [isNonBinancePair, setIsNonBinancePair] = useState(false)

  useEffect(() => {
    if (kline && kline[symbol]) {
      realtimePrice = kline[symbol]
      setRealtimePrice(realtimePrice)
    }
  }, [kline]);

  useEffect(() => {
    document.title = symbol.replace('USDT', '') + ' | Campaign Detail 🚀🚀';
    generalService.nonBinancePairCheck(symbol).then(r => {
      setIsNonBinancePair((typeof r === 'object'))
    })
  }, [])

  return (
    <section>
      <div className="container p-3">
        <Header title={`${symbol} - ${moreDigitsDollarFormat(realtimePrice)}`}
                symbol={symbol}
        />
        <OrphanTable symbol={symbol} realtimePrice={realtimePrice} isNonBinancePair={isNonBinancePair}/>
        <CampaignTable symbol={symbol}/>
      </div>
    </section>
  );
}

export {CampaignDetailPage};
