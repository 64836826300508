import {allConstants} from '../../constants';

const initialState = false

export default function reset(state = initialState, action) {
  switch (action.type) {
    case allConstants.TOGGLE_RESET_STAND_BY:
      return !state
    case allConstants.SET_RESET_STAND_BY_ON:
      return true
    case allConstants.SET_RESET_STAND_BY_OFF:
      return false
    default:
      return state
  }
}